<div style="width:100%; height:100%;">
	<uno-tab>
		<!-- team tab -->
		@if (this.team != null) {
			<uno-tab-section [active]="true" title="{{'team' | translate}}">
				<uno-form [editable]="this.canEdit()" [layout]="this.layout" [object]="this.team" #teamForm></uno-form>
				<div style="display: flex; flex-direction: row-reverse; gap: 5px; padding: 15px">
					<!-- Save / Create -->
					<uno-button (click)="this.update();">{{(this.createMode ? 'create' : 'save') | translate}}</uno-button>
					<!-- Update / Delete -->
					@if (!this.createMode) {
						@if ([userPermissions.TEAM_EDIT] | hasPermissions) {
							<uno-button (click)="this.update(true);">{{'update' | translate}}</uno-button>
						}
						@if ([userPermissions.TEAM_DELETE] | hasPermissions) {
							<uno-button (click)="this.delete();" color="error">{{'delete' | translate}}</uno-button>
						}
					}
				</div>
			</uno-tab-section>

			<!-- Users tab -->
			@if (!this.createMode) {
				<uno-tab-section title="{{'users' | translate}}">
					<uno-content [ngStyle]="{height: 'calc(100% - 130px)'}">
						<uno-responsive-table-list [selectable]="false" [layout]="this.tableLayout" [loadMore]="this.loadMore" [totalItems]="this.totalItems" [pageSize]="this.tablePageSize" [labelShown]="true" [rowClickable]="true" (rowClick)="app.navigator.navigate('/menu/users/edit', {uuid: $event.element.uuid});">
						</uno-responsive-table-list>
					</uno-content>
				</uno-tab-section>
			}
		}
	</uno-tab>
</div>
