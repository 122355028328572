<div style="width:100%; height:100%;">
	<uno-tab>
		<uno-tab-section [active]="true" title="{{'actionPlan' | translate}}">
			<!-- Base Form -->
			<uno-tab-section title="{{'actionPlan' | translate}}"></uno-tab-section>
			<uno-form [layout]="this.actionPlanLayout" [object]="this.actionPlan" #actionPlanForm></uno-form>

			@if (this.actionPlan?.state === state.WAITING_REINSPECTION && ([userPermissions.ATEX_INSPECTION] | hasPermissions) && (this.inspections | objectKeys).length > 0) {
				<!-- Re-inspection form -->
				<uno-title>{{'reinspection' | translate}}</uno-title>

				<!-- Cycle all Inspections -->
				@for (i of this.inspections | objectKeys; track i) {
					@if (this.inspections[i] !== undefined && this.inspections[i].forms !== undefined) {
						<!-- Inspections field forms -->
						@for (f of this.inspections[i].forms | objectKeys; track f) {
							<uno-form [editable]="true" [layout]="this.inspections[i].forms[f]" [object]="this.inspections[i].data.responses[f]"></uno-form>
						}

						<!-- Open page -->
						<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
							@if ([userPermissions.ATEX_INSPECTION] | hasPermissions) {
								<uno-button (click)="app.navigator.navigate('/menu/atex/inspections/edit', {uuid: this.inspections[i].uuid});">{{'viewInspection' | translate}}</uno-button>
							}
							@if (this.actionPlan.data.documents.length > 0 && ([userPermissions.ASSET_PORTFOLIO_ASSET_EDIT] | hasPermissions)) {
								<uno-button (click)="copyDocuments(this.assets[this.inspections[i].assetUuid].uuid);">{{'copyDocuments' | translate}}</uno-button>
							}
						</div>
					}
				}
			}

			<!-- Submit buttons -->
			<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
				@if (this.createMode && ([userPermissions.ACTION_PLAN_CREATE] | hasPermissions)) {
					<uno-button (click)="this.create();">{{'create' | translate}}</uno-button>
				}

				@if (!this.createMode) {
					<uno-button (click)="this.update();">{{'save' | translate}}</uno-button>
					<uno-button (click)="this.update(undefined, true);">{{'update' | translate}}</uno-button>
					@if (this.actionPlan?.state === state.TODO && ([userPermissions.ACTION_PLAN_SUBMIT] | hasPermissions)) {
						<uno-button (click)="this.update(state.WAITING_CLIENT_VALIDATION);" color="success">{{'submit' | translate}}</uno-button>
					}
					@if (this.actionPlan?.state === state.WAITING_CLIENT_VALIDATION && ([userPermissions.ACTION_PLAN_CLIENT] | hasPermissions)) {
						<uno-button (click)="this.update(state.CLIENT_ACCEPTED);" color="success">{{'approve' | translate}}</uno-button>
						<uno-button (click)="this.update(state.CLIENT_REJECTED);" color="warning">{{'reject' | translate}}</uno-button>
					}
					@if (this.actionPlan?.state === state.CLIENT_ACCEPTED && ([userPermissions.ACTION_PLAN_COMPANY] | hasPermissions)) {
						<uno-button (click)="this.update(state.WAITING_REINSPECTION);" color="success">{{'finish' | translate}}</uno-button>
						<uno-button (click)="this.update(state.BLOCKED);" color="warning">{{'blocked' | translate}}</uno-button>
					}
					@if (this.actionPlan?.state === state.BLOCKED && ([userPermissions.ACTION_PLAN_UNBLOCK] | hasPermissions)) {
						<uno-button (click)="this.update(state.CLIENT_ACCEPTED);" color="success">{{'unlock' | translate}}</uno-button>
					}
					@if (this.actionPlan?.state === state.WAITING_REINSPECTION && ([userPermissions.ACTION_PLAN_REINSPECT] | hasPermissions)) {
						<uno-button (click)="this.update(state.DONE);">{{'finish' | translate}}</uno-button>
					}
					@if (([userPermissions.ACTION_PLAN_ARCHIVE] | hasPermissions) && this.actionPlan?.state !== state.ARCHIVED) {
						<uno-button (click)="this.update(state.ARCHIVED);" color="warning">{{'archive' | translate}}</uno-button>
					}
					@if ([userPermissions.ACTION_PLAN_DELETE] | hasPermissions) {
						<uno-button (click)="this.delete();" color="error">{{'delete' | translate}}</uno-button>
					}
				}
			</div>
		</uno-tab-section>

		<!-- Assets -->
		@if ((this.assets | objectKeys).length > 0) {
			<uno-tab-section title="{{'assets' | translate}}">
				@for (i of this.assets | objectKeys; track i) {
					<!-- Asset information -->
					<uno-form [editable]="false" [layout]="assetsLayout" [object]="this.assets[i]"></uno-form>

					<!-- Open page -->
					<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
						@if ([userPermissions.ASSET_PORTFOLIO] | hasPermissions) {
							<uno-button (click)="app.navigator.navigate('/menu/asset-portfolio/asset/edit', {uuid: this.assets[i].uuid});">{{'viewAsset' | translate}}</uno-button>
						}
						@if (this.actionPlan.data.documents.length > 0 && ([userPermissions.ASSET_PORTFOLIO_ASSET_EDIT] | hasPermissions)) {
							<uno-button (click)="copyDocuments(this.assets[i].uuid);">{{'copyDocuments' | translate}}</uno-button>
						}
					</div>
				}
			</uno-tab-section>
		}
		<!-- FFPs -->
		@if ((this.ffps | objectKeys).length > 0 && this.ffpLayout) {
			<uno-tab-section title="{{'ffp' | translate}}">
				@for (i of this.ffps | objectKeys; track i) {
					<!-- FFP information -->
					<uno-form [editable]="false" [layout]="ffpLayout" [object]="this.ffps[i]"></uno-form>
				}
			</uno-tab-section>
		}

		@if (this.history.length > 0) {
			<uno-tab-section title="{{'history' | translate}}">
				<!-- History -->
				<ion-list class="ion-no-padding ion-no-margin">
					@for (option of this.history; track option) {
						<ion-item class="ion-margin-horizontal ion-no-padding" lines="full">
							<ion-label>{{option.date | formatDate}}</ion-label>
							<div class="ion-text-end">
								<ion-label>{{option.user.name}}</ion-label>
								<ion-label color="medium" style="font-size: 13px;">{{option.user.email + (option.user.phone.length > 0 ? (' | ' + option.user.phone) : '')}}</ion-label>
							</div>
						</ion-item>
					}
				</ion-list>
			</uno-tab-section>
		}
	</uno-tab>
</div>
