export const Environment = {
	PRODUCTION: true,
	CLIENT_ID: 'cires',
	DEFAULT_ROUTE: null,
	TEST: false,
	VERSION: '0.346.6',
	MODULES: [
		'atex-inspections',
		'digital-twin',
		'asset-planning',
		'gap-analysis',
		'inspections',
		'observations',
		'repairs',
		'dl-50'
	],
	TIMESTAMP: '2024-11-05T12:42:34.816Z',
	COMMIT: 'd64eee4d0e5a1584ad364997bcac01421c3b7ca9',
	BRANCH: 'cires-deploy',
	API_SERVER: 'https://cires-api.unoplatform.io:8080',
	MAPBOX_TOKEN: 'pk.eyJ1IjoidW5vM2QiLCJhIjoiY2swbWk3ZDU1MTQ2aTNubXprbnZ6Y2c1bCJ9.fGhQreB4AkAUcW_5_tr81Q',
	RESOURCE_SERVER: 'https://cires-api.unoplatform.io:8082',
	FILE_CONVERTER_SERVER: 'https://cires-api.unoplatform.io:8081',
	PIPELINE_INTEGRITY_SERVER: null,
	DIGITAL_TWIN_SERVER: null
};
