/**
 * Object utils contain tools to simplify the manipulation of objects.
 *
 * For example tools to copy data between objects or change the inner structure of objects.
 */
export class ObjectUtils {

	/**
	 * Get a attribute from an object by its path.
	 *
	 * Attribute names can indicate nested objects (e.g. `a:{b:{c:2}}` the c value can be accessed as "a.b.c").
	 */
	public static getAttribute(object: any, attribute: string): any {
		const attrs: string[] = attribute.split('.');

		let sub = object;
		for (let i = 0; i < attrs.length; i++) {
			sub = sub[attrs[i]];
		}

		return sub;
	}

	/**
	 * Search for the value of an attribute recursively in an object.
	 *
	 * Returns the first value found in the object tree.
	 *
	 * @param object - The object to search the attribute in.
	 * @param attribute - The attribute to search for.
	 * @returns The value of the attribute if found, null otherwise.
	 */
	public static searchAttribute(object: any, attribute: string): any {
		if (object.hasOwnProperty(attribute)) {
			return object[attribute];
		}

		for (const key in object) {
			if (object[key] instanceof Object) {
				const value = ObjectUtils.searchAttribute(object[key], attribute);
				if (value !== null) {
					return value;
				}
			}
		}

		return null;
	}

	/**
	 * Copy all the properties from an object to another. Copy common attributes from the origin to destination object.
	 *
	 * The copy is shallow and does not create completely new instances of the objects copied.
	 *
	 * @param destination - Destination object.
	 * @param origin - Origin object.
	 */
	public static copyFrom(destination: any, origin: any): void {
		for (const i in destination) {
			if (origin[i] !== undefined && origin[i] !== null) {
				destination[i] = origin[i];
			}
		}
	}

	/**
	 * Copy the values of an object from another object.
	 *
	 * If an attribute is not present in the destination object an error is thrown.
	 *
	 * The copy is shallow and does not create completely new instances of the objects copied.
	 *
	 * @param object - Origin object to copy data into.
	 * @param attributes - Object with new attributes to be copied.
	 * @returns The origin object where the values where copied to.
	 */
	public static setValues(object: any, attributes: any): any {
		for (const a in attributes) {
			if (!object.hasOwnProperty(a)) {
				throw new Error('Attribute ' + a.toString() + ' must not be null or undefined.');
			}

		 	object[a] = attributes[a];
		 }

		return object;
	}

	/**
	 * Compare if object "a" and "b" are the same.
	 *
	 * If a and b are objects all attributes keys and values should match.
	 *
	 * @param a - First object to compare.
	 * @param b - Second object to compare.
	 * @param ignore - List of object attribute keys to ignore.
	 * @returns True if both object are the same, false otherwise.
	 */
	public static equal(a: any, b: any, ignore: string[] = []): boolean {
		if (a instanceof Date && b instanceof Date) {
			return a.getTime() === b.getTime();
		}

		if (a instanceof Object && b instanceof Object) {
			const keysA: string[] = Object.keys(a);
			const keysB: string[] = Object.keys(b);

			for (let i = 0; i < ignore.length; i++) {
				const indexA = keysA.indexOf(ignore[i]);
				if (indexA > -1) {
					keysA.splice(indexA, 1);
				}

				const indexB = keysB.indexOf(ignore[i]);
				if (indexB > -1) {
					keysB.splice(indexB, 1);
				}
			}

			if (keysA.length !== keysB.length) {
				return false;
			}

			for (const i of keysA) {
				if (!ObjectUtils.equal(a[i], b[i], ignore)) {
					return false;
				}
			}

			return true;
		}

		return a === b;
	}

	/**
	 * Given a list of objects and a list of properties, delete the properties from those objects.
	 *
	 * @param objects - array of objects.
	 * @param attributes - the list of attributes to delete from the objects.
	 * @returns the objects without the properties.
	 */
	public static removeAttributes(objects: any[], attributes: string[]): any {
		for (const obj of objects) {
			for (const key of Object.keys(obj) ) {
				if (obj[key] instanceof Array) {
					this.removeAttributes(obj[key], attributes);
				} else if (obj[key] instanceof Object) {
					this.removeAttributes([obj[key]], attributes);
				} else {
					for (const att of attributes) {
						delete obj[att];
					}
				}
			}
		}

		return objects;
	}

	/**
	 * Take all the attributes of an object and convert them to an array of key and values.
	 *
	 * @param obj - The object to extract attributes from
	 * @returns The array containing all the object key values pairs.
	 */
	public static toArray(obj: any): {key: string, value: any}[] {
		const array: any[] = [];

		for (const k in obj) {
			array.push({
				key: k,
				value: typeof obj[k] === 'object' ? ObjectUtils.toArray(obj[k]) : obj[k]
			});
		}

		return array;
	}
}
