import {Component, forwardRef, Input, OnChanges, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule} from '@angular/forms';
import {App} from 'src/app/app';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {UnoFormField, OptionsDisplayMode} from '../../uno-forms/uno-form/uno-form-field';
import {OptionSelectorOption} from '../uno-options/uno-options.component';

/**
 * Form input muliple options box.
 *
 * Used to select multiple options.
 */
@Component({
	selector: 'uno-options-multiple',
	templateUrl: './uno-options-multiple.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => { return UnoOptionsMultipleComponent; }),
		multi: true
	}],
	standalone: true,
	imports: [IonicModule, FormsModule, TranslateModule]
})
export class UnoOptionsMultipleComponent implements ControlValueAccessor, OnChanges {
	public optionsDisplayMode: any = OptionsDisplayMode;
	
	public app: any = App;

	/**
	 * Allow the input to be disabled.
	 */
	@Input()
	public disabled: boolean = false;

	/**
	 * If set, sorts all the items alphabetically.
	 */
	@Input()
	public sort: boolean = false;

	/**
	 * If set, clear button will be shown on component to clear its value.
	 */
	@Input()
	public showClear: boolean = false;

	/**
	 * If set, the options will be automatically translated
	 */
	@Input()
	public translate: boolean = true;

	/**
	 * Options to display for selection.
	 */
	@Input()
	public options: OptionSelectorOption[] = [];

	/**
	 * The display mode for the options-multiple component.
	 */
	@Input()
	public displayMode: number = OptionsDisplayMode.POPOVER;

	/**
	 * The row form field to fetch the options for.
	 */
	@Input()
	public row: UnoFormField = null;
	
	/**
	 * The object to get the options for.
	 */
	@Input()
	public object: any = null;
		
	/**
	 * The selected option(s) value(s)
	 */
	public value: any[] = [];
	
	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.options && this.options instanceof Array && this.sort) {
			this.options.sort((a: any, b: any) => { return a.label > b.label ? 1 : -1;});
		}
	}

	public onChange: (value: any)=> void = null;

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	/**
	 * Update the value of the input.
	 * 
	 * @param value - The value to write
	 */
	public updateValue(value: any[]): void {
		this.value = value;
		this.onChange(this.value);
	}

	public writeValue(value: any): void {
		this.value = value;
	}

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}

	public registerOnTouched(fn: any): void {}
}
