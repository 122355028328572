@if (!validationUtils.validPhoneNumber(this.phoneNumber())) {
	<ion-note color="danger" style="font-size: 11px; font-weight: bold;">{{'invalidPhoneNumber' | translate}}</ion-note>
	<br>
}
<div class="uno-phone-number-container">
	<!-- Dropdown -->
	<uno-options-icon [disabled]="false" [width]="86" [options]="options()" [selectedOption]="selectedOption()" (valueChange)="this.updatePhoneNumber($event)"></uno-options-icon>

	<!-- Number Input -->
	<input class="uno-phone-number-input" type="tel" name="tel" placeholder="000000000" [ngModelOptions]="{standalone: true}" [(ngModel)]="this.phoneNumber" [value]="this.phoneNumber" (change)="this.updatePhoneNumber()">
</div>
