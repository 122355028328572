import {FileUtils} from 'src/app/utils/file-utils';
import {TeamService} from 'src/app/modules/teams/services/teams.service';
import {APAsset} from '../../../../models/asset-portfolio/asset';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {UUID} from '../../../../models/uuid';
import {Locale} from '../../../../locale/locale';
import {Modal} from '../../../../modal';
import {Team} from '../../../../models/teams/team';
import {ProgressBar} from '../../../../progress-bar';
import {Session} from '../../../../session';
import {XlsxSheetData, XlsxUtils} from '../../../../utils/xlsx-utils';
import {InspectionForm} from '../../../../models/inspections/form/inspection-form';
import {InspectionFormFieldType} from '../form/inspection-form-field-type';
import {InspectionWorkflowStep} from '../../../../models/inspections/workflow/inspection-workflow-step';
import {InspectionFormField, OptionsData} from '../../../../models/inspections/form/inspection-form-field';
import {InspectionFormService} from '../../services/inspection-form.service';
import {InspectionProjectService} from '../../services/inspection-project.service';
import {InspectionWorkflowService} from '../../services/inspection-workflow.service';
import {InspectionProject} from '../../../../models/inspections/project/inspection-project';
import {InspectionService} from '../../services/inspection.service';
import {InspectionData, InspectionDataFields} from '../../../../models/inspections/inspection/inspection-data';
import {Inspection} from '../../../../models/inspections/inspection/inspection';
import {ResourceUtils} from '../../../../utils/resource-utils';

export class InspectionExport {
	/**
	 * Export all dynamic inspection content into a JSON file.
	 *
	 * Includes data from forms, project, workflows, inspection data.
	 */
	public static async exportJSON(): Promise<void> {
		const values = await Promise.all([
			await InspectionFormService.list(),
			await InspectionWorkflowService.list(),
			await Service.fetch(ServiceList.inspection.workflowStepAdjacency.list, null, null, {}, Session.session, true),
			await InspectionProjectService.list(),
			await InspectionService.listDetailed({fetchData: true})
		]);

		const out = {
			forms: values[0].forms,
			workflows: values[1].inspectionWorkflows,
			adjacencies: values[2].response.adjacencies,
			projects: values[3].projects,
			inspections: values[4].inspections
		};

		FileUtils.writeFile('inspections.json', JSON.stringify(out, null, '\t'));
	}

	/**
	 * Export all the inspections of all projects in a XLSX file.
	 * 
	 * An inspection per line and a column per each form field on the inspection project workflow forms.
	 * 
	 * An inspection project per document sheet.
	 */
	public static async exportAllXLSX(): Promise<void> {
		const request = await InspectionProjectService.list({});
		const projectUuids: UUID[] = request.projects.map((project) => {return project.uuid;});

		await InspectionExport.exportXLSX(projectUuids);
	}

	/**
	 * Export all the inspections of a project in a XLSX file.
	 * 
	 * An inspection per line and a column per each form field on the inspection project.
	 * 
	 * An inspection project per document sheet.
	 *
	 * @param projectUuids - The array containing all the uuids of the projects to export.
	 */
	public static async exportXLSX(projectUuids: UUID[]): Promise<void> {
		// The progress bar use to display export progress on a modal
		const progressBar: ProgressBar = new ProgressBar();
		progressBar.show();

		const workBookSheets: XlsxSheetData[] = [];

		const errors: Error[] = [];

		for (let i = 0; i < projectUuids.length; i++) {
			// The global progress value computed from the number of exported projects within the total number of projects
			const progress: number = (i - 1 < 0 ? 0 : i - 1) / projectUuids.length;
			progressBar.update(Locale.get('loadingData'), progress);

			// The sheets to store on xlsx file to be exported
			const sheet = await InspectionExport.appendProjectInspectionsXLSX(projectUuids[i], projectUuids.length, progressBar, progress);

			// Add to errors list
			errors.push(...sheet.errors);

			// Shorten the sheet name and add a sequential number to avoid repeated sheet names
			sheet.data.name = (i + 1 + '-' + sheet.data.name).slice(0, 30);
			workBookSheets.push(sheet.data);
		}

		if (errors.length > 0) {

			const errs = errors.map((e) => {return '\t-' + e;}).join('\n');

			Modal.alert(Locale.get('error'), Locale.get('errorsEncounteredDuringExport', {errors: errs}));
		}

		if (workBookSheets.length > 0) {
			XlsxUtils.writeMultiSheetFile(workBookSheets, 'inspections.xlsx');
		} else {
			Modal.alert(Locale.get('warning'), Locale.get('nothingToExport'));
		}

		progressBar.destroy();
	}

	/**
	 * Export all the inspections of a project in a XLSX file within a document sheet.
	 *
	 * An inspection per line and a column per each form field on the inspection project.
	 *
	 * @param projectUuid - The UUID of the project to export.
	 * @param totalProjects - Total count of inspection projects.
	 * @param progressBar - Progress bar object to be updated on data load.
	 * @param progress - Current progress of the import process.
	 * @param errors - Array to append errors found while reading data.
	 */
	private static async appendProjectInspectionsXLSX(projectUuid: UUID, totalProjects: number, progressBar: ProgressBar, progress: number): Promise<{data: XlsxSheetData, errors: Error[]}> {
		let from: number = 0;
		const count: number = 300;
		const nd = '';

		const errors = [];

		// Inspections document header
		const inspectionHeader: any[] = [
			Locale.get('uuid'), Locale.get('createdAt'), Locale.get('updatedAt'),
			Locale.get('name'), Locale.get('description'), 
			Locale.get('projectUuid'), Locale.get('projectName'),
			Locale.get('assetUuid'), Locale.get('assetName'), Locale.get('assetTag'), 
			Locale.get('teamUuid'), Locale.get('teamName'), 
			Locale.get('qr')
		];

		try {
			// Get the project info
			const reqProject = await Service.fetch(ServiceList.inspection.project.get, null, null, {uuid: projectUuid}, Session.session, true, false);
			const project: InspectionProject = InspectionProject.parse(reqProject.response.project);

			// Get project workflow steps
			const stepsReq = await Service.fetch(ServiceList.inspection.workflowStep.list, null, null, {project: projectUuid}, Session.session, true, false);
			let steps: InspectionWorkflowStep[] = InspectionWorkflowStep.parseArray(stepsReq.response.steps);

			// Sort steps by its index
			steps = steps.sort((a: InspectionWorkflowStep, b: InspectionWorkflowStep) => { return a.index - b.index;});
			
			// Store the maps to be used later on
			const formsMap: Map<UUID, InspectionForm> = new Map();

			// Get all step forms and headers once by step UUID
			let stepsHeaders: string[] = [];
			for (let i = 0; i < steps.length; i++) {
				// Check if step has a form associated
				if (!steps[i].formUuid) {
					continue;
				}

				try {
					const headers: string[] = await InspectionExport.getFormHeaders(steps[i].formUuid, steps[i].name, formsMap);
					stepsHeaders = stepsHeaders.concat(headers);
				} catch (e) {
					errors.push(new Error(Locale.get('errorGettingProjectFormHeaders', {stepUuid: steps[i].uuid, stepName: steps[i].name, projectUuid: project.uuid, projectName: project.name, details: e})));
				}
			}

			// Data rows to insert on file (including the header)
			const fileData: any[][] = [inspectionHeader.concat(stepsHeaders)];

			// Get the total inspections of this inspection project
			const totalProjectInspections = (await Service.fetch(ServiceList.inspection.count, null, null, {projectUuid: projectUuid}, Session.session, true, false)).response.count;
			if (totalProjectInspections > 0) {
				let inspectionCount: number = 0;

				// Control variable to tell when there's no more inspections to fetch
				let finished: boolean = false;
				while (!finished) {
					let inspections: Inspection[] = [];
					const assets: APAsset[] = [];
					let teams: Team[] = [];
						
					try {
						const list = await InspectionService.listDetailed({project: projectUuid, fetchData: true, from: from, count: count}, true);
						const assetsUuids: UUID[] = [];
						const teamsUuids: UUID[] = [];

						for (let i = 0; i < list.inspections.length; i++) {
							const inspection: Inspection = list.inspections[i];
							inspections.push(inspection);
								
							if (inspection.assetUuid) {
								assetsUuids.push(inspection.assetUuid);
							}

							if (inspection.teamUuid) {
								teamsUuids.push(inspection.teamUuid);
							}
						}

						// Get all the assets data at once and fill them on inspection export data
						if (assetsUuids.length > 0) {
							const assetReq = await Service.fetch(ServiceList.assetPortfolio.asset.getBatch, null, null, {assets: assetsUuids}, Session.session, true, false);
							for (let i = 0; i < assetReq.response.assets.length; i++) {
								assets.push(APAsset.parse(assetReq.response.assets[i]));
							}
						}
		
						// Get all the teams data at once and fill them on inspection export data
						if (teamsUuids.length > 0) {
							teams = await TeamService.getBatch(teamsUuids, true);
						}

						from += list.inspections.length;
						if (!list.hasMore) {
							finished = true;
						}
					} catch (e) {
						inspections = [];
						errors.push(new Error(Locale.get('errorGettingInspectionsForProject', {projectName: project.name, projectUuid: project.uuid, details: e})));
					}
	
					let row: any[] = [];
					for (let i = 0; i < inspections.length; i++) {
						const inspection: Inspection = inspections[i];
							
						try {
							progressBar.update(Locale.get('loadingData'), progress + inspectionCount / totalProjectInspections / totalProjects);

							const inspectionAsset: APAsset = assets.find((a) => {return a.uuid === inspection.assetUuid;});
							const inspectionTeam: Team = teams.find((t) => {return t.uuid === inspection.teamUuid;});
		
							row = [
								inspection.uuid, inspection.createdAt.toLocaleString(Locale.code), inspection.updatedAt.toLocaleString(Locale.code),
								inspection.name, inspection.description,
								project.uuid, project.name
							];
		
							row = row.concat(inspectionAsset ? [inspectionAsset.uuid, inspectionAsset.name, inspectionAsset.tag] : [nd, nd, nd]);
							row = row.concat(inspectionTeam ? [inspectionTeam.uuid, inspectionTeam.name] : [nd, nd]);
								
							row.push(inspection.qr ? inspection.qr : nd);
		
							for (let j = 0; j < steps.length; j++) {
								const stepData: InspectionData = inspection.getInspectionDataForStep(steps[j].uuid);

								const data = steps[j].formUuid ? InspectionExport.getFormFieldsData(steps[j].formUuid, stepData ? stepData.data : null, formsMap) : [];
								row = row.concat(data);
							}

							fileData.push(row);

							inspectionCount++;
						} catch (e) {
							errors.push(new Error(Locale.get('errorExportingInspectionForProject', {inspectionName: inspection.name, inspectionUuid: inspection.uuid, projectName: project.name, projectUuid: project.uuid, details: e})));
						}
					}
				}
			}
			const xlsx = {
				name: project.name,
				data: fileData
			};

			return {data: xlsx, errors: errors};			
		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('errorExport'));
			console.error('EQS: Error exporting file.', e);
		}
	}

	/**
	 * Gets the form fields names recursively to be used as document sheet headers for every form field and return them on an array of strings to be inserted on sheet header.
	 *
	 * Changes on this method must be mirrored on "getFormFieldsData" method in order to ensure the correct match between headers and data.
	 *
	 * @param formUuid - The UUID of the form to get the headers for.
	 * @param parentHeader - The upper header label to be kept as prefix on its subfields headers.
	 * @param forms - Map with inspection forms.
	 * @returns An array containing all the headers/form fields name of a form.
	 */
	public static async getFormHeaders(formUuid: UUID, parentHeader: string, forms: Map<UUID, InspectionForm>): Promise<string[]> {
		if (!formUuid) {
			return [];
		}

		const form: InspectionForm = await InspectionFormService.get(formUuid, true, false);
		forms.set(formUuid, form);

		const formHeader: string = parentHeader.length > 0 ? parentHeader + ' - ' + form.name : form.name;

		let headers: string[] = [];
		for (let i = 0; i < form.fields.length; i++) {
			const header: string = formHeader.length > 0 ? formHeader + ' - ' + form.fields[i].text : form.fields[i].text;

			// Recursively call sub-form data
			if (form.fields[i].type === InspectionFormFieldType.MULTIPLE_FORMS) {
				throw new Error('Export of forms with repeated form fields is not supported.');
			} else if (form.fields[i].type === InspectionFormFieldType.COMPOSED_FIELD || form.fields[i].type === InspectionFormFieldType.SUB_FORM) {
				if (!form.fields[i].subFormUuid) {
					throw new Error('Invalid Subform UUID.');
				}

				const subHeaders: string[] = await InspectionExport.getFormHeaders(form.fields[i].subFormUuid, header, forms);
				headers = headers.concat(subHeaders);
			} else {
				headers.push(header);
			}
		}

		return headers;
	}

	/**
	 * Gets the fields data recursively for every form field and return them on an array of strings to be inserted on file.
	 *
	 * Changes on this method must be mirrored on "getFormHeaders" method in order to ensure the correct match between headers and data.
	 *
	 * @param formUuid - The UUID of the form to get the data for.
	 * @param data - The inspection form data object to get the data from.
	 * @param forms - Map with inspection forms.
	 * @returns An array containing the form fields data.
	 */
	public static getFormFieldsData(formUuid: UUID, data: InspectionDataFields, forms: Map<UUID, InspectionForm>): string[] {		
		// Util to format the value of the field based only value and the type of form field to display.
		function formatValue(value: any, field: InspectionFormField): string {
			if (value === null || value === undefined) {
				return value;
			}

			if (field.type === InspectionFormFieldType.IMAGES || field.type === InspectionFormFieldType.DOCUMENTS) {
				return value.map(function(a) { return ResourceUtils.getURL(a);}).join(', ');
			} else if (value instanceof Object) {
				return JSON.stringify(value);
			}

			return value.toString();
		}

		if (!forms.has(formUuid)) {
			throw new Error('Form with UUID ' + formUuid + ' not found in the map provided.');
		}

		const form: InspectionForm = forms.get(formUuid);

		// List of the fields data.
		let fieldsData: string[] = [];
		
		for (let n = 0; n < form.fields.length; n++) {
			const field: InspectionFormField = form.fields[n];

			// If the field has sub-form attached iterate the children elements.
			if (field.type === InspectionFormFieldType.MULTIPLE_FORMS) {
				throw new Error('Export of forms with repeated form fields is not supported');
			} else if (field.type === InspectionFormFieldType.COMPOSED_FIELD || field.type === InspectionFormFieldType.SUB_FORM) {
				fieldsData = fieldsData.concat(field.subFormUuid ? InspectionExport.getFormFieldsData(field.subFormUuid, data ? data[field.uuid] : null, forms) : []);
			} else {
				if (!data) {
					fieldsData.push('');
					continue;
				}
				
				// Get the value of the field.
				let value: any = data[field.uuid];

				const d: OptionsData = field.data as OptionsData;
				if (field.data && d.options) {
					if (field.type === InspectionFormFieldType.OPTIONS) {
						const option: any = d.options.find((opt) => {return opt.key === value;});
						value = option ? option.value : '';
					} else if (field.type === InspectionFormFieldType.OPTIONS_MULTIPLE) {
						const optionsData: string[] = [];

						// Extract the answer label from fields' data by answer value (option UUID)
						if (value instanceof Array) {
							for (let i = 0; i < value.length; i++) {
								const option: any = d.options.find((opt) => {return opt.key === value[i];});
								optionsData.push(option ? option.value : '');
							}
						}

						value = optionsData.join(' | ');
					}
				}

				fieldsData.push(formatValue(value, field));
			}
		}

		return fieldsData;
	}
}
