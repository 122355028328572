import {NgClass} from '@angular/common';
import {Component, Input, Signal, ViewEncapsulation, WritableSignal, computed, forwardRef, signal} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import CountryList, {Country} from 'country-list-with-dial-code-and-flag';
import {CdkConnectedOverlay, CdkOverlayOrigin} from '@angular/cdk/overlay';
import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR} from '@angular/forms';
import {CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {ValidationUtils} from 'src/app/utils/validation-utils';
import {IonicModule} from '@ionic/angular'; 
import {UnoIconComponent} from '../../uno/uno-icon/uno-icon.component';
import {UnoOptionIconData, UnoOptionsIcon} from '../../uno/uno-options-icon/uno-options-icon.component';

/**
 * The Phone Number Component
 * 
 * @param disabled - Allows the input to be disabled.
 * @param value - Value of the string containing the dial code and the phone number.
 */
@Component({
	selector: 'uno-phone-number',
	templateUrl: './uno-phone-number.component.html',
	styleUrls: ['./uno-phone-number.component.css'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => { return UnoPhoneNumberComponent; }),
		multi: true
	}],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [CdkConnectedOverlay, CdkOverlayOrigin, NgClass, UnoIconComponent, IonicModule, FormsModule, TranslateModule, CdkVirtualScrollViewport, CdkVirtualForOf, CdkFixedSizeVirtualScroll, UnoOptionsIcon]

})
export class UnoPhoneNumberComponent implements ControlValueAccessor {
	public validationUtils: any = ValidationUtils;

	/**
	 * Indicates if the input is disabled.
	 */
	@Input()
	public disabled: boolean = false;

	/**
	 * Method called when the data is changed.
	 */
	public onChange: (value: any)=> void = function() {};

	/**
	 * Value of the phone number.
	 */
	public value: string = '';

	/**
	 * List of all country data
	 */
	public countries: Signal<Country[]> = computed(() => {return CountryList.getAll();});

	/**
	 * Options for the dropdown
	 */
	public options: Signal<UnoOptionIconData[]> = computed(() => {return CountryList.getAll().map((country) => {return {value: country.code, icon: `/assets/flags/${country.code.toLowerCase()}.svg`, label: country.code, sublabel: country.dialCode};});});

	/**
	 * String that holds selected Country code
	 */
	public selectedCountryCode: WritableSignal<string> = signal('');
	
	/**
	 * The seleced option from the dropdown
	 */
	public selectedOption: WritableSignal<UnoOptionIconData> = signal({value: '', icon: '', label: '', sublabel: ''});

	/**
	 * String that holds the phone number
	 */
	public phoneNumber: WritableSignal<string> = signal('');

	/**
	 * Method that ensures the phone number is composed of only digits and hyphens.
	 */
	public validateAndFormatNumber(): void {
		if (!this.validationUtils.validPhoneNumber(this.phoneNumber())) {
			this.phoneNumber.set(this.phoneNumber().replace(/[^0-9-]/g, ''));
		}
	}

	/**
	 * Method that emits the complete phone number to the form
	 * 
	 * @param option - Selected option from menu
	 */
	public updatePhoneNumber(option?: UnoOptionIconData): void {
		this.validateAndFormatNumber();

		const phoneNumber = option ? `${option.sublabel} ${this.phoneNumber()}` : `${CountryList.findByCountryCode(this.selectedCountryCode())[0].dialCode} ${this.phoneNumber()}`;
		this.onChange(phoneNumber);
	}

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	public writeValue(value: any): void {
		this.value = value;

		if (this.value) {
			const dialCode = this.value.match(this.validationUtils.dialCodeRegex); 

			if (dialCode) {
				const country = CountryList.findByDialCode(dialCode[0]);
				this.selectedCountryCode.set(country[0].code);
				this.phoneNumber.set(this.value.replace(/\+\d+ /, ''));
			} else {
				// If the user has not selected a country code before, the default is Portugal
				this.selectedCountryCode.set('PT');
				this.phoneNumber.set(this.value);
			}
	
			this.selectedOption.set({
				value: this.selectedCountryCode(),
				icon: `/assets/flags/${this.selectedCountryCode().toLowerCase()}.svg`,
				label: this.selectedCountryCode()
			});
		} else {
			this.selectedCountryCode.set('PT');
			this.phoneNumber.set('');
		}
	}

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}

	public registerOnTouched(fn: any): void {}
}

