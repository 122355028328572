<div [ngClass]="this.row.border ? 'uno-form-repetitive-field-container' : 'uno-form-repetitive-field-container-borderless'">
	<!-- Repetitive form -->
	@for (item of this.items; track i; let i = $index) {
		<div class="uno-form-repetitive-field-inner-container">
			<uno-form [showRequiredUneditable]="this.showRequiredUneditable" [editable]="!this.disabled" [layout]="this.layout" [object]="item" [onChange]="this.onChange"></uno-form>

			@if (!this.disabled && ((!(this.row.disableSort | checkbool:false:this.items:this.row) && ((i < (this.items.length - 1)) || (i > 0))) || !(this.row.disableDelete | checkbool:false:this.items:this.row))) {
				<div class="uno-form-repetitive-field-buttons">
					@if (!(this.row.disableSort | checkbool:false:this.items:this.row) && (i < (this.items.length - 1))) {
						<uno-button icon="/assets/icons/uno/down-specialarrow.svg" (click)="this.moveFieldUp(i)" size="medium" [fitContent]="true"></uno-button>
					}
					@if (!(this.row.disableSort | checkbool:false:this.items:this.row) && (i > 0)) {
						<uno-button icon="/assets/icons/uno/up-specialarrow.svg" (click)="this.moveFieldDown(i)" size="medium" [fitContent]="true"></uno-button>
					}
					@if (!(this.row.disableDelete | checkbool:false:this.items:this.row)) {
						<uno-button icon="/assets/icons/uno/bin.svg" (click)="this.items.splice(i, 1);" size="medium" [fitContent]="true"></uno-button>
					}
				</div>
			}
		</div>
	}

	<!-- Add button -->
	@if (!this.disabled) {
		<uno-button slot="end" (click)="this.addItem()" size="medium">
			<uno-text>{{'add' | translate }}</uno-text>
		</uno-button>
	}
</div>
