import {Component, Input, ViewEncapsulation, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule} from '@angular/forms';
import {IonicSelectableComponent} from 'ionic-selectable';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {NgClass, NgStyle} from '@angular/common';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {OverlayModule} from '@angular/cdk/overlay';
import {AssetService} from '../../../modules/asset-portfolio/services/asset.service';
import {Modal} from '../../../modal';
import {APAsset} from '../../../models/asset-portfolio/asset';
import {Session} from '../../../session';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {App} from '../../../app';
import {Locale} from '../../../locale/locale';
import {QRReaderComponent} from '../../../modules/qr/components/qr-reader/qr-reader.component';
import {NFCReaderComponent} from '../../../modules/nfc/components/nfc-reader/nfc-reader.component';
import {UserPermissions} from '../../../models/users/user-permissions';
import {UUID} from '../../../models/uuid';
import {UnoFormField} from '../../uno-forms/uno-form/uno-form-field';
import {AssetBaseLayout, AssetStructureLayout} from '../../../modules/asset-portfolio/screens/asset/asset-layout';
import {UnoFormComponent} from '../../uno-forms/uno-form/uno-form.component';
import {PermissionsPipe} from '../../../pipes/permissions.pipe';
import {UnoIconComponent} from '../../uno/uno-icon/uno-icon.component';
import {AssetTreeListItemComponent} from '../../../modules/asset-portfolio/screens/asset/tree/asset-tree-list-item/asset-tree-list-item.component';
import {UnoAssetSelectorTreeNavigationComponent} from './uno-asset-selector-tree-navigation/uno-asset-selector-tree-navigation.component';

/**
 * The asset list component is used for the user to select assets from a list retrieved from the API.
 *
 * The list can be filtered to include a specific type of assets.
 */
@Component({
	selector: 'uno-asset-selector',
	templateUrl: './uno-asset-selector.component.html',
	styleUrls: ['./uno-asset-selector.component.css'],
	encapsulation: ViewEncapsulation.None,
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => { return UnoAssetSelectorComponent; }),
		multi: true
	}],
	standalone: true,
	imports: [IonicModule, IonicSelectableComponent, FormsModule, TranslateModule, PermissionsPipe, UnoIconComponent, NgClass, NgStyle, AssetTreeListItemComponent, ScrollingModule, OverlayModule]
})
export class UnoAssetSelectorComponent implements ControlValueAccessor {
	public permissions: any = UserPermissions;

	public session: any = Session;

	public app: any = App;

	/**
	 * Indicate if the user can select multiple assets.
	 *
	 * When selecting multiple the value stored is an array.
	 */
	@Input()
	public multiple: boolean = true;

	/**
	 * Allow the input to be disabled.
	 */
	@Input()
	public disabled: boolean = false;

	/**
	 * If set, clear button will be shown on component to clear its value.
	 */
	@Input()
	public showClear: boolean = false;

	/**
	 * If set, sorts all the items alphabetically upon fetch.
	 */
	@Input()
	public sort: boolean = false;

	/**
	 * If set, listed assets will belong only to the provided asset type.
	 */
	@Input()
	public assetTypeUuid: UUID = null;

	/**
	 * If set, listed assets will belong only to the provided asset sub-type.
	 */
	@Input()
	public assetSubTypeUuid: UUID = null;

	/**
	 * List of the selected asset UUID stored in this component.
	 */
	public value: (UUID[] | UUID) = [];

	/**
	 * Assets that are selected on the object and are not yet available from the API.
	 *
	 * These should be retrieved from the API.
	 */
	public baseOptions: APAsset[] = [];

	/**
	 * Assets available based on the search value inserted.
	 */
	public options: APAsset[] = [];

	/**
	 * Method called when the data is changed.
	 */
	public onChange: (value: any)=> void = function(value) {};


	/**
	 * Create a new asset in a modal and select it.
	 *
	 * Only display basic asset information.
	 */
	public async createAssetModal(): Promise<void> {
		const asset = new APAsset();

		const layout: UnoFormField[] = AssetBaseLayout.concat(AssetStructureLayout);

		await Modal.form(Locale.get('asset'), asset, layout);

		if (!UnoFormComponent.requiredFilled(AssetStructureLayout, asset) || !UnoFormComponent.requiredFilled(AssetBaseLayout, asset)) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}

		const request = await Service.fetch(ServiceList.assetPortfolio.asset.create, null, null, asset, Session.session);

		this.updateValue(request.response.uuid);

	}

	/**
	 * Load base options from the API server, these options are the ones selected on the object.
	 *
	 * They need to be fetched to show on the list (their name is not known, if their info is not retrieved).
	 */
	public async loadBaseOptions(): Promise<void> {
		if (!this.value) {
			return;
		}

		const assets: any = this.value instanceof Array ? this.value : [this.value];

		if (assets.length > 0) {
			const request = await Service.fetch(ServiceList.assetPortfolio.asset.getBatch, null, null, {assets: assets}, Session.session);
	
			this.baseOptions = request.response.assets;
			for (let i = 0; i < this.baseOptions.length; i++) {
				UnoAssetSelectorComponent.setDisplayText(this.baseOptions[i]);
			}
		} else {
			this.baseOptions = [];
		}
		this.options = this.baseOptions.concat([]);
	}

	/**
	 * Get the text to be displayed on the asset list for a specific option.
	 *
	 * @param option - Option of the list retrieved from the API
	 */
	public static setDisplayText(option: any): void {
		option.display = option.name;
		
		if (option.tag) {
			option.display += ' (' + option.tag + ')';
		}
	}

	/**
	 * Opens Asset modal
	 */
	public async openAssetModal(): Promise<void> {
		let popover: HTMLIonPopoverElement = null;


		const onAdd = (assets: APAsset[]): void => {
			const uuids = assets.map((asset) => {return asset.uuid;});
			if (this.multiple) {
				this.updateValue(uuids);
			} else if (uuids.length > 0) {
				this.updateValue(uuids[0]);
			} else {
				this.updateValue(null);
			}
			popover.dismiss();
		};

		
		popover = await Modal.component(UnoAssetSelectorTreeNavigationComponent, {onAdd: onAdd, multiple: this.multiple, alreadySelected: this.value}, true, false, false);

	}

	/**
	 * Select a asset using a NFC tag, a NFC selector is shown to the user.
	 *
	 * If the NFC tag asset is already present in the list a message is presented to the user.
	 */
	public selectAssetNFC(): void {
		Modal.component(NFCReaderComponent, {
			onRead: async(id: any) => {
				const asset: APAsset = await AssetService.getByNFC(id);
				Modal.toast(this.selectAsset(asset.uuid) ? Locale.get('nfcSuccess') : Locale.get('assetAlreadySelected'));
			}
		});
	}

	/**
	 * Select an asset using a QR code, a QR code selector modal is shown to the user.
	 *
	 * If the QR asset is already present in the list a message is presented to the user.
	 */
	public selectAssetQR(): void {
		Modal.component(QRReaderComponent, {
			onRead: async(id: any) => {
				const asset: APAsset = await AssetService.getByQR(id);
				Modal.toast(this.selectAsset(asset.uuid) ? Locale.get('qrSuccess') : Locale.get('assetAlreadySelected'));
			}
		});
	}

	/**
	 * Select asset from its uuid, automatically adds its data to the list.
	 *
	 * @param uuid - Asset uuid received from the API.
	 * @returns "true" if the object was selected successfully, "false" if the object was already selected.
	 */
	public selectAsset(uuid: UUID): boolean {
		if (this.value && this.value.includes(uuid)) {
			return false;
		}

		this.updateValue(this.multiple ? this.value instanceof Array ? this.value.concat(uuid) : [uuid] : uuid);

		return true;
	}

	public removeAsset(asset: APAsset): void {
		if (this.value instanceof Array) {
			const newValue = this.value.filter((uuid: string) => {
				return asset.uuid !== uuid;
			});

			this.updateValue(newValue.length > 0 ? newValue : null);
		} else {
			this.updateValue(null);
		}
	}

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	/**
	 * Update the value, setting the new value and calling the onChange callback.
	 * 
	 * @param value - New Value of the component.
	 */
	public updateValue(value: UUID | UUID[]): void {
		this.value = value;
		this.onChange(value);
		this.loadBaseOptions();
	}

	public writeValue(value: UUID | UUID[]): void {
		this.value = value;
		this.loadBaseOptions();
	}

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}

	public registerOnTouched(fn: any): void {}
}


