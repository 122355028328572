@if (app.device.isDesktop()) {
	<div [ngStyle]="this.ngStyle">
		@for (option of this.options; track option) {
			<div style="display: inline-block; cursor: pointer; width: 45px; height: 45px;" (click)="option.onClick()" uno-tooltip uno-tooltip-direction="top" uno-tooltip-text="{{option.label | translate}}">
				<svg-icon [src]="option.icon" [svgStyle]="{fill: 'var(--light)', 'pointer-events': 'none', display: 'block', margin: '7px', width: '30px', height: '30px'}"></svg-icon>
			</div>
		}
	</div>
}
@if (app.device.isMobile()) {
	<!-- Collapse button -->
	<div [ngStyle]="this.toggleButtonStyle" style="position: absolute; display: block; overflow-y: auto; overflow-x: hidden; text-align: center; width: 30px; height: 40px; top: 30px; margin: 0px; cursor: pointer; background-color: var(--gray-13)" (click)="this.toggleCollapsed();">
		<ion-icon style="left: 5px; top: 10px; position: absolute; font-size: 20px;" [name]="this.collapsed ? 'caret-forward-outline' : 'caret-back-outline'"></ion-icon>
	</div>
	<!-- Tool bar -->
	<div [ngStyle]="this.ngStyle">
		@for (option of this.options; track option) {
			<div style="display: inline-block; cursor: pointer; width: 45px; height: 45px; top: 0px;" (click)="option.onClick()" uno-tooltip uno-tooltip-direction="top" uno-tooltip-text="{{option.label | translate}}">
				<svg-icon [src]="option.icon" [svgStyle]="{fill: 'var(--gray-1)', 'pointer-events': 'none', display: 'block', margin: '10px', width: '30px', height: '30px'}"></svg-icon>
			</div>
		}
	</div>
}
