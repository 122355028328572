<uno-text [ngStyle]="{position: 'absolute', display: 'block', top: '0px', left: '0px', width: '100%'}" ngClass="welcome">{{'welcome' | translate}}</uno-text>

<uno-text [multiline]="true" [ngStyle]="{position: 'absolute', display: 'block', top: '20px', left: '0px', width: '100%', height: '100px'}" ngClass="credentials">{{'twoFactorCode' | translate}}</uno-text>

<div [ngStyle]="{position: 'absolute', display: 'block', top: '100px', width: '100%', height: '300px', left: '0px'}">
	<uno-text [ngStyle]="{position: 'absolute', display: 'block', top: '0px', width: '100%'}" ngClass="input">{{'code' | translate}}</uno-text>
	<uno-input [ngStyle]="{position: 'absolute', display: 'block', top: '15px', width: '100%'}" [ngModelOptions]="{standalone: true}" [(ngModel)]="this.code" placeholder="{{'code' | translate}}" type="text"></uno-input>
</div>

<uno-button [ngStyle]="{position: 'absolute', display: 'flex', top: '120px', width: '100%'}" (click)="this.login();" [expand]="true">{{'login' | translate}}</uno-button>

<!-- Back -->
<div [ngStyle]="{position: 'absolute', display: 'block', top: '300px', width: '100%', height: '40px', cursor: 'pointer'}">
	<uno-text alignment="center" ngClass="recover" (click)="this.returnLogin();">{{'returnLogin' | translate}}</uno-text>
</div>
