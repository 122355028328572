import {Component, Input, ViewEncapsulation} from '@angular/core';
import {UUID} from 'src/app/models/uuid';
import {NgClass} from '@angular/common';
import {DashboardWidget} from 'src/app/models/dashboards/widget';
import {UnoWidgetComponent} from './widgets/widget.component';

/**
 * The Dashboard component to display a number of widgets
 */
@Component({
	selector: 'uno-dashboard',
	templateUrl: 'dashboard.component.html',
	styleUrls: ['dashboard.component.css'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [NgClass, UnoWidgetComponent]
})
export class UnoDashboardComponent {
	/**
	 * Indicates if the dashboard is editable.
	 */
	@Input()
	public editable: boolean = true;

	/**
	 * Uuid of the dashboard.
	 */
	@Input()
	public uuid: UUID;

	/**
	 * The widgets that are a part of this dashboard.
	 */
	@Input()
	public widgets: DashboardWidget[];
}
