<div class="uno-audio-selector">
	@if (this.value !== null) {
		<div class="uno-audio-selector-audio-container">
			<audio controls src="{{this.audioUrl}}"></audio>

			@if (!this.disabled) {
				<uno-button icon="/assets/icons/uno/bin.svg" (click)="this.updateValue(null);"></uno-button>
				<uno-button icon="/assets/icons/uno/upload.svg" (click)="this.selectFile();"></uno-button>
			}
		</div>
	}
	@if (!this.value) {
		@if (this.disabled) {
			<div class="uno-audio-selector-select-container" style="cursor: not-allowed;">
				<div class="uno-audio-selector-select-container-text">
					<uno-icon src="assets/icons/uno/audio.svg" color="primary" width="32" height="32"></uno-icon>
					<div>
						<span>{{'noDataOnServer' | translate }}</span>
					</div>
				</div>
			</div>
		}
		@else {
			<div #upload class="uno-audio-selector-select-container" (click)="this.selectFile()">
				<div class="uno-audio-selector-select-container-text">
					<uno-icon src="assets/icons/uno/audio.svg" color="primary" width="32" height="32"></uno-icon>
					<div>
						<span>{{'dragAndDrop' | translate }}</span>
						<span>{{'or' | translate }}</span>
						<span class="uno-audio-selector-choose-file">{{'chooseFile' | translate }}</span>
						<span>{{'toUpload' | translate }}</span>
					</div>
				</div>
				<span class="uno-audio-selector-container-sublabel">{{'audioFiles' | translate }}</span>
			</div>
		}
	}
</div>
