<div class="uno-tree-list-page-filters">
	<uno-searchbar [value]="selfStatic.filters.search" (valueChange)="onSearch($event)"></uno-searchbar>
	<uno-filter-bar [filters]="selfStatic.filters" [options]="selfStatic.filterOptions" (onChange)="this.onFilterChange($event);"></uno-filter-bar>
</div>

<uno-content [ngStyle]="{height: 'calc(100% - 130px)'}">
	@if (this.items?.length !== 0) {
		<cdk-virtual-scroll-viewport style="height: 100%; width: 100%" itemSize="50">
			<asset-tree-list-item *cdkVirtualFor="let item of this.flatItems;" [item]="item" (toggleExpand)="this.toggleExpansion(item);"></asset-tree-list-item>
		</cdk-virtual-scroll-viewport>
	} @else {
		<uno-no-data></uno-no-data>
	}
</uno-content>
