<div style="height: 110px; margin: 10px;">
	<!-- Search bar -->
	<uno-searchbar style="width: 100%;" [value]="selfStatic.filters.search" (valueChange)="onSearchChange($event)"></uno-searchbar>

	<!-- Filters -->
	<div style="overflow-x: auto; display: flex; align-items: flex-end; justify-content: flex-start; flex-direction: row; gap: 5px; width: 100%; height:80px">
		<uno-filter-bar [filters]="selfStatic.filters" [options]="selfStatic.filterOptions" (onChange)="this.onFilterChange($event);"></uno-filter-bar>

		<div style="height:80px; padding-bottom: 10px; gap: 5px; display: flex; align-items: flex-end;">
			@if (this.selectedGapUuids.length !== 0 && (userPermissions.GA_ACTION_PLAN_CREATE | hasPermissions)) {
				<uno-button icon="assets/icons/uno/add.svg" (click)="app.navigator.navigate('/menu/gap-analysis/action-plans/edit', {createMode: true, gapUuids: this.selectedGapUuids});">
					{{'createActionPlan' | translate}}
				</uno-button>
			}

			@if (this.selectedGapUuids.length !== 0 && (userPermissions.GA_GAP_DELETE | hasPermissions)) {
				<uno-button color="error" icon="assets/icons/uno/bin.svg" (click)="this.deleteSelection()">
					{{'delete' | translate}}
				</uno-button>
			}

			@if (userPermissions.GA_GAP | hasPermissions) {
				<uno-button icon="assets/icons/uno/download.svg" (click)="this.exportXLSX();">
					{{'exportXlsx' | translate}}
				</uno-button>
			}
		</div>
	</div>
</div>

<div style="height: calc(100% - 130px); overflow: hidden;">
	<uno-responsive-table-list [loadMore]="this.loadTableItems" [totalItems]="this.tableTotalItemsCount" [layout]="this.tableLayout" [pageSize]="selfStatic.tablePageSize" [labelShown]="true" [selectable]="true" (rowChecked)="this.checkedRow($event)" (rowClick)="app.navigator.navigate('/menu/gap-analysis/gaps/edit', {uuid: $event.element.uuid});" (sortChange)="this.sortChanged($event.sortBy)">
	</uno-responsive-table-list>
</div>
