import {
	Component,
	ElementRef,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import {Chart, ChartData} from 'chart.js';
import {NgStyle} from '@angular/common';
import {Session} from 'src/app/session';
import {CssNgStyle} from '../../../utils/css-ng-style';

@Component({
	selector: 'dashboard-bar-chart',
	templateUrl: './dashboard-bar-chart.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [NgStyle]
})
export class DashboardBarChartComponent implements OnInit, OnDestroy {
	@ViewChild('canvas', {static: true})
	public canvas: ElementRef = null;

	/**
	 * Data to display in the bar chart.
	 */
	@Input()
	public data: ChartData = null;

	/**
	 * Style of the bar chart.
	 */
	@Input()
	public ngStyle: CssNgStyle = null;

	/**
	 * Context of the bar chart.
	 */
	public context: CanvasRenderingContext2D = null;

	/**
	 * Chart of the bar chart.
	 */
	public chart: Chart = null;

	/**
	 * Dataset of the bar chart.
	 */
	public dataset: ChartData = null;

	public ngOnInit(): void {
		this.dataset = {
			labels: this.data.labels,
			datasets: this.data.datasets
		};

		this.context = this.canvas.nativeElement.getContext('2d');

		this.chart = new Chart(this.context, {
			type: 'bar',
			data: this.dataset,
			options: {
				indexAxis: 'y',
				responsive: false,
				maintainAspectRatio: true,
				plugins: {
					legend: {
						labels: {
							usePointStyle: true,
							boxWidth: 8,
							boxHeight: 8,
							font: {
								family: 'Nunito',
								size: 12,
								lineHeight: 24
							}
						},
						display: true,
						position: 'bottom'
					}
				},
				scales: {
					y: {
						ticks: {
							color: Session.settings.theme === 'dark' ? '#42475C' : '#A8B3C3',
							font: {
								family: 'Nunito',
								size: 10
							}
						},
						grid: {color: Session.settings.theme === 'dark' ? '#222230' : '#F0F3F6'},
						border: {color: Session.settings.theme === 'dark' ? '#222230' : '#F0F3F6'}
					},
					x: {
						position: 'top',
						ticks: {
							color: Session.settings.theme === 'dark' ? '#42475C' : '#A8B3C3',
							font: {
								family: 'Nunito',
								size: 10
							}
						},
						grid: {color: Session.settings.theme === 'dark' ? '#222230' : '#F0F3F6'},
						border: {color: Session.settings.theme === 'dark' ? '#222230' : '#F0F3F6'}
					}
				}
			}
		});
	}

	public ngOnDestroy(): void {
		if (this.chart) {
			this.chart.destroy();
			this.chart = null;
		}
	}
}

