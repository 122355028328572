import {Component, OnInit} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {UnoFormField} from '../../../components/uno-forms/uno-form/uno-form-field';
import {Locale} from '../../../locale/locale';
import {App} from '../../../app';
import {Session} from '../../../session';
import {Environment} from '../../../../environments/environment';
import {Settings} from '../data/settings';
import {ScreenComponent} from '../../../components/screen/screen.component';
import {StyleManager} from '../../../theme/style-manager';
import {UnoContentComponent} from '../../../components/uno/uno-content/uno-content.component';
import {SettingsLayout} from '../settings-layout';
import {UnoFormModule} from '../../../components/uno-forms/uno-form.module';
import {UnoTabComponent} from '../../../components/uno/uno-tab/uno-tab.component';
import {UnoTabSectionComponent} from '../../../components/uno/uno-tab/uno-tab-section/uno-tab-section.component';

@Component({
	selector: 'settings-page',
	templateUrl: './settings.page.html',
	standalone: true,
	imports: [UnoContentComponent, IonicModule, FormsModule, TranslateModule, UnoFormModule, UnoTabComponent, UnoTabSectionComponent]
})
export class SettingsPage extends ScreenComponent implements OnInit {
	public app: any = App;

	public locale: any = Locale;

	public environment: any = Environment;

	public layout: any = SettingsLayout;

	/**
	 * User Settings object, has to be set into the session data after changes.
	 */
	public settings: Settings = null;

	/**
	 * Update settings after form changes
	 * 
	 * @param object - Settings object
	 * @param row - Row that was edited
	 * @param value - New value.
	 */
	public onChange = (object: any, row: UnoFormField, value: any): void => {
		if (row.attribute === 'theme') {
			StyleManager.setTheme(value);
		}

		this.settings[row.attribute] = value;

		Session.updateSettings(this.settings);
	};

	public ngOnInit(): void {
		super.ngOnInit();
		
		App.navigator.setTitle('settings');

		this.settings = Session.settings;
	}
}
