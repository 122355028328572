import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {DemoPage} from './demo/demo.page';
import {DemoDynamicFormPage} from './demo/dynamic-form/demo-dynamic-form.page';
import {DemoExpressionEditorPage} from './demo/expression-editor/demo-expression-editor.page';
import {DemoComponentsPage} from './demo/components/demo-components.page';
import {DemoTypographyPage} from './demo/typography/demo-typography.page';
import {DemoColorPalettePage} from './demo/color-palette/demo-color-palette.page';

const routes: Routes = [
	{
		path: '',
		component: DemoPage
	},
	{
		path: 'form',
		component: DemoDynamicFormPage
	},
	{
		path: 'expression-editor',
		component: DemoExpressionEditorPage
	},
	{
		path: 'components',
		component: DemoComponentsPage
	},
	{
		path: 'typography',
		component: DemoTypographyPage
	},
	{
		path: 'color-palette',
		component: DemoColorPalettePage
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class DemoRouterModule { }
