import {
	Component,
	ElementRef,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import {NgClass, NgStyle} from '@angular/common';
import {Session} from '../../../session';
import {CssNgStyle} from '../../../utils/css-ng-style';
import {UnoTextComponent} from '../uno-text/uno-text.component';
import {UnoIconComponent} from '../uno-icon/uno-icon.component';

/**
 * Whether the button is filled or only has a border
 */
export const ButtonType = {
	FILL: 'fill',
	BORDER: 'border'
};

/**
 * Color style of the button element.
 */
export const ButtonColor = {
	PRIMARY: 'primary',
	ERROR: 'error',
	WARNING: 'warning',
	SUCCESS: 'success'
};

/**
 * Size of the button to be used.
 */
export const ButtonSize = {
	LARGE: 'large',
	MEDIUM: 'medium',
	SMALL: 'small',
	EXTRASMALL: 'extraSmall'
};

/**
 * UNO button is a customizable button, that can contain text and icon.
 *
 * Can be configured in terms of color, size and disabled, if necessary.
 */
@Component({
	selector: 'uno-button',
	templateUrl: './uno-button.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [NgStyle, NgClass, UnoIconComponent, UnoTextComponent]
})
export class UnoButtonComponent implements OnChanges, OnInit {
	@ViewChild('div', {static: true})
	public div: ElementRef<HTMLElement>;

	/**
	 * Style to apply to the element.
	 */
	@Input()
	public ngStyle: CssNgStyle = {};

	/**
	 * Class to apply to the element.
	 */
	@Input()
	public ngClass: NgClass = null;

	/**
	 * Color style of the button element
	 */
	@Input()
	public color: string = ButtonColor.PRIMARY;

	/**
	 * Color style of the button element
	 */
	@Input()
	public type: string = ButtonType.FILL;

	/**
	 * Size of the button element
	 */
	@Input()
	public size: string = ButtonSize.LARGE;

	/**
	 * Adjust the horizontal size automatically to fit content.
	 */
	@Input()
	public fitContent: boolean = true;

	/**
	 * Adjust the horizontal size to occupy the parent div.
	 */
	@Input()
	public expand: boolean = false;

	/**
	 * Indicates if the button is disabled.
	 */
	@Input()
	public disabled: boolean = false;

	/**
	 * Source of the icon on the button.
	 */
	@Input()
	public icon: string = null;

	/**
	 * Method called when the button is pressed.
	 */
	@Input()
	public onClick: ()=> void = null;

	/**
	 * Color of the icon, default is white.
	 */
	@Input()
	public iconColor: string = 'var(--light)';

	/**
	 * Width of the button.
	 *
	 * Only applied if "fitContent" and "expand" are set false.
	 */
	@Input()
	public width: string = '140px';

	/**
	 * Height of the button (optional).
	 */
	@Input()
	public height: string = null;

	/**
	 * Style of the text element.
	 */
	@Input()
	public textStyle: CssNgStyle = {
			'font-family': 'Nunito',
			'font-style': 'normal',
			'font-weight': '500',
			'font-size': '12px',
			'line-height': '12px',
			color: 'var(--light)',
			width: 'fit-content',
			height: '100%'
		};

	/**
	 * Indicates if the cursor is hover the button.
	 */
	public hover: boolean = false;

	/**
	 * Indicates if the button is being held down.
	 */
	public pressed: boolean = false;

	/**
	 * The size of the icon (20px for extra small and 25px for everything else)
	 */

	public iconSize: string = this.size === 'extraSmall' ? '20px' : '25px';

	/**
	 * Style of the button element.
	 */
	public style: CssNgStyle = {
		display: 'flex',
		'flex-direction': 'row',
		'align-items': 'center',
		'justify-content': 'center'
	};

	public ngOnInit(): void {
		this.updateStyle();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		this.updateStyle();
	}

	/**
	 * Update the style of the button.
	 *
	 * Should be called after pointer events that require change in the style.
	 */
	public updateStyle(): void {
		switch (this.size) {
			case ButtonSize.EXTRASMALL:
				Object.assign(this.style, {
					'border-radius': '8px',
					width: this.width,
					height: '24px'
				});
				Object.assign(this.textStyle, {'font-size': '12px', 'line-height': '14px', 'font-weight': '700'});
				break;
			case ButtonSize.SMALL:
				Object.assign(this.style, {
					'border-radius': '8px',
					width: this.width,
					height: '28px'
				});
				Object.assign(this.textStyle, {'font-size': '12px', 'line-height': '14px', 'font-weight': '700'});
				break;
			case ButtonSize.MEDIUM:
				Object.assign(this.style, {
					'border-radius': '10px',
					width: this.width,
					height: '34px'
				});
				Object.assign(this.textStyle, {'font-size': '14px', 'line-height': '14px', 'font-weight': '500'});
				break;
			case ButtonSize.LARGE:
				Object.assign(this.style, {
					'border-radius': '10px',
					width: this.width,
					height: '39px'
				});
				Object.assign(this.textStyle, {'font-size': '16px', 'line-height': '16px', 'font-weight': '500'});
				break;

			default:
				throw new Error('Invalid uno-button size');
		}

		if (this.height) {
			this.style.height = this.height;
		}

		if (this.fitContent) {
			Object.assign(this.style, {
				width: 'fit-content',
				'padding-left': '10px',
				'padding-right': '10px'
			});
		}

		if (this.expand) {
			Object.assign(this.style, {width: '100%'});
		}

		if (this.disabled) {
			this.textStyle.color = 'var(--gray-5)';
			this.iconColor = 'var(--gray-5)';
			Object.assign(this.style, {
				cursor: null,
				'background-color': 'var(--gray-10)',
				border: '1px solid var(--gray-10)'
			});
		} else {
			this.style['cursor'] = 'pointer';
		}

		if (!this.disabled) {
			if (this.type === ButtonType.BORDER) {
				switch (this.color) {

					case ButtonColor.PRIMARY: 
						this.style['border'] = this.hover ? '1px solid var(--brand-primary-hover)' : '1px solid var(--brand-primary)';
						this.textStyle.color = this.hover ? 'var(--brand-primary-hover)' : 'var(--brand-primary)';
						this.iconColor = this.hover ? 'var(--brand-primary-hover)' : 'var(--brand-primary)';
						break;

					case ButtonColor.WARNING:
						this.style['border'] = this.hover ? '1px solid var(--warning-hover)' : '1px solid var(--warning-normal)';
						this.textStyle.color = 'var(--gray-0)';
						this.iconColor = this.hover ? 'var(--warning-hover)' : 'var(--warning-normal)';
						break;

					case ButtonColor.ERROR: 
						this.style['border'] = this.hover ? '1px solid var(--error-hover)' : '1px solid var(--error-normal)';
						this.textStyle.color = 'var(--gray-0)';
						this.iconColor = this.hover ? 'var(--error-hover)' : 'var(--error-normal)';
						break;

					case ButtonColor.SUCCESS: 
						this.style['border'] = this.hover ? '1px solid var(--success-hover)' : '1px solid var(--success-normal)';
						this.textStyle.color = 'var(--gray-0)';
						this.iconColor = this.hover ? 'var(--success-hover)' : 'var(--success-normal)';
						break;

					default:
						throw new Error('Invalid uno-button color');
				}

			} else if (this.type === ButtonType.FILL) {

				switch (this.color) {
					case ButtonColor.PRIMARY:
						this.style['background-color'] = this.hover ? 'var(--brand-primary-hover)' : 'var(--brand-primary)';
						this.textStyle.color = 'var(--light)';
						break;

					case ButtonColor.WARNING:
						this.style['background-color'] = this.hover ? 'var(--warning-hover)' : 'var(--warning-normal)';
						if (Session.settings.theme === 'dark') {
							this.textStyle.color = 'var(--gray-14)';
							this.iconColor = 'var(--gray-14)';
						}
						break;

					case ButtonColor.SUCCESS:
						this.style['background-color'] = this.hover ? 'var(--success-hover)' : 'var(--success-normal)';
						if (Session.settings.theme === 'dark') {
							this.textStyle.color = 'var(--gray-14)';
							this.iconColor = 'var(--gray-14)';
						}
						break;

					case ButtonColor.ERROR:
						this.style['background-color'] = this.hover ? 'var(--error-hover)' : 'var(--error-normal)';
						break;
					default:
						throw new Error('Invalid uno-button color');
				}
			}
		}

		Object.assign(this.style, this.ngStyle);
	}
}
