import {Component, ElementRef, Input, ViewChild, ViewEncapsulation, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {NgClass} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {EventManager} from 'src/app/utils/event-manager';
import {App} from 'src/app/app';
import Viewer from 'viewerjs';
import {PdfJsViewerModule} from 'ng2-pdfjs-viewer';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {FileExtensionUtils} from 'src/app/utils/file-extension-utils';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {FileUtils} from '../../../utils/file-utils';
import {Resource, ResourceType} from '../../../models/resource';
import {ResourceUtils} from '../../../utils/resource-utils';
import {UnoIconComponent} from '../../uno/uno-icon/uno-icon.component';
import {OfficeViewerComponent} from '../../../modules/office/components/office-viewer/office-viewer.component';
import {UnoButtonComponent} from '../../uno/uno-button/uno-button.component';
import {ExtensionIconPipe} from './pipes/uno-file-selector-extension-icon.pipe';


@Component({
	selector: 'uno-file-multiple-selector',
	templateUrl: './uno-file-selector.component.html',
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['uno-file-selector.component.css'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => { return UnoFileSelectorComponent; }),
			multi: true
		}
	],
	standalone: true,
	imports: [IonicModule, TranslateModule, UnoIconComponent, NgClass, PdfJsViewerModule, OfficeViewerComponent, UnoButtonComponent, ExtensionIconPipe]
})
export class UnoFileSelectorComponent implements ControlValueAccessor {
	@ViewChild('upload', {static: false})
	public set upload(element: ElementRef) {
		if (element) {
			if (!this.disabled) {
				['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
					this.events.add(element.nativeElement, eventName, (event) => {
						event.preventDefault();
						event.stopPropagation();
					});
				});
				this.events.add(element.nativeElement, 'drop', (event) => {
					this.dropFile(event);
					this.isDragging = false;
				});

				this.events.add(element.nativeElement, 'dragenter', () => {
					this.isDragging = true;
				});

				this.events.add(element.nativeElement, 'dragleave', () => {
					this.isDragging = false;
				});
	
				this.events.create();
			}
		}
	}

	/**
	 * PDF Viewer element reference
	 */
	@ViewChild('pdfViewer') 
	public pdfViewer;

	public fileUtils: any = FileUtils;

	public app: any = App;

	/**
	 * URL of the sample file if there is any available.
	 */
	@Input()
	public sampleFile: string = null;

	/**
	 * If true allow to select multiple files.
	 */
	@Input()
	public multiple: boolean = true;

	/**
	 * Allow the input to be disabled.
	 */
	@Input()
	public disabled: boolean = false;

	/**
	 * File type filter to allow a specific type of files only.
	 */
	@Input()
	public filter: string = '';

	/**
	 * Local flag control if the file should be stored on resource server or stored in memory.
	 */
	@Input()
	public local: boolean = false;

	/**
	 * Resources representing the documents stored.
	 */
	public value: (Resource[] | File[] | Resource | File) = [];

	/**
	 * Method called when the data is changed.
	 */
	public onChange: (value: any)=> void = function(value) { };

	/**
	 * Index of the currently hovered file
	 */
	public hoverIndex: number | null = null;

	/**
	 * Event manager used for drag and drop
	 */
	public events: EventManager = new EventManager();
	
	/**
	 * Image viewer if there is one active. Should be destroyed when the component is destroyed.
	 */
	public viewer: Viewer = null;
	
	/**
	 * Controls if the pdfjs viewer is open
	 */
	public isPdfOpen: boolean = false;

	/**
	 * URL for the open office document
	 */
	public officeUrl: SafeResourceUrl = '';

	/**
	 * Controls if office viewer is open
	 */
	public isOfficeDocOpen = false;

	/**
	 * Currently open file (that isn't an image)
	 */
	public openResource: File | Resource | null = null;

	/**
	 * Controls if a file is being dragged
	 */
	public isDragging: boolean = false;

	public ngOnDestroy(): void {
		if (this.viewer) {
			this.viewer.destroy();
			this.viewer = null;
		}
		
		this.events.destroy();
	}

	public constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

	/**
	 * Download the document into the device.
	 */
	public openFile(resource: (Resource | File)): void {
		const format = this.local ? FileUtils.getFileExtension(resource as File) : (resource as Resource).format;
		switch (true) {
			case FileExtensionUtils.isImage(format):
				this.viewImage(resource);
				break;
			case FileExtensionUtils.isPDF(format):
				this.viewPDF(resource);
				this.openResource = resource;
				break;
			case FileExtensionUtils.isOffice(format):
				this.openOfficeDocuments(resource);
				this.openResource = resource;
				break;
			default:
				this.downloadFile(resource);
				break;
		}
	}

	/**
	 * Downloads file
	 * 
	 * @param resource - file that will be downloaded
	 */
	public downloadFile(resource: File | Resource): void {
		if (this.local) {
			FileUtils.download(resource as File);
		} else {
			ResourceUtils.download(resource as Resource);
		}
	}

	/**
	 * Ensure it downloads pdf instead of opening it
	 * 
	 * @param resource - pdf that will be downloaded
	 */
	public async downloadFilePDF(resource: File | Resource): Promise<void> {
		let arrayBuffer: ArrayBuffer;
		let name: string;
		let format: string;

		if (this.local) {
			arrayBuffer = await (resource as File).arrayBuffer();
			name = FileUtils.getFileName(resource as File);
			format = FileUtils.getFileExtension(resource as File);
		} else {
			const url = ResourceUtils.getURL(resource as Resource);
			arrayBuffer = await FileUtils.readFileArrayBuffer(url);
			name = (resource as Resource).uuid;
			format = (resource as Resource).format;
		}

		FileUtils.writeFileArrayBuffer(`${name}.${format}`, arrayBuffer);
	}

	/**
	 * Open the image viewer to show the image in detail.
	 *
	 * @param resource - Image resource to focus when the viewer is opened.
	 */
	public viewImage(resource: Resource | File): void {
		if (this.viewer) {
			throw new Error('EQS: There is already one viewer opened.');
		}

		// Show all images in the viewer
		const ul = document.createElement('ul');
		const li = document.createElement('li');
		const image = document.createElement('img');

		if (this.local) {
			image.src = window.URL.createObjectURL(resource as File);
		} else {
			const imageResource = resource as Resource;

			image.src = Service.getURL(ServiceList.resources.file.get, {
				uuid: imageResource.uuid,
				format: imageResource.format
			});
		}

		li.appendChild(image);
		ul.appendChild(li);
		

		this.viewer = new Viewer(ul, {
			hidden: () => {
				this.viewer.destroy();
				this.viewer = null;
			},
			title: false,
			transition: false,
			initialViewIndex: 0,
			zIndex: 1e8,
			toolbar: false,
			navbar: false
		});

		this.viewer.show();
	}

	/**
	 * Gets blob of the pdf
	 * 
	 * @param url - url of the pdf
	 * @returns blob of the pdf
	 */
	public getPDFData(url: string): Observable<Blob> {
		return this.http.get(url, {responseType: 'blob'})
			.pipe(map((result: Blob) => {
				return result;
			}));
	}

	/**
	 * Open pdfjs viewer.
	 *
	 * @param resource - Pdf resource.
	 */
	public viewPDF(resource: Resource | File): void {
		const url = this.local ? window.URL.createObjectURL(resource as File) : ResourceUtils.getURL(resource as Resource);
		
		this.getPDFData(url).subscribe((res) => {
			this.pdfViewer.pdfSrc = res;
			this.pdfViewer.refresh();
		  });

		this.isPdfOpen = true;
	}

	/**
	 * Closes pdf viewer
	 */
	public closePDF(): void {
		this.pdfViewer.pdfSrc = '';
		this.pdfViewer.refresh();
		this.isPdfOpen = false;
		this.openResource = null;
	}

	/**
	 * Opens Office viewer
	 * 
	 * @param resource - Office resource
	 */
	public openOfficeDocuments(resource: Resource | File): void {
		this.isOfficeDocOpen = true;
		this.officeUrl = this.local ? window.URL.createObjectURL(resource as File) : ResourceUtils.getURL(resource as Resource);
	}

	/**
	 * Closes Office viewer
	 */
	public closeOfficeDocument(): void {
		this.isOfficeDocOpen = false;
		this.officeUrl = '';
		this.openResource = null;
	}

	/**
	 * Remove a document from the list.
	 *
	 * @param resource - Resource of the file to be removed.
	 */
	public remove(resource: Resource | File): void {
		if (this.multiple) {
			if (this.value instanceof Array) {
				// @ts-ignore
				const index = this.value.indexOf(resource);
				if (index !== -1) {
					this.value.splice(index, 1);
					this.updateValue(this.value);
				}
			} else {
				throw new Error('Value must be an array in multiple mode');
			}
		} else {
			this.updateValue(null);
		}
	}

	/**
	 * Select document file and add it to the values array.
	 */
	public async selectFiles(): Promise<void> {
		const files: File[] = await FileUtils.chooseFile(this.filter, this.multiple);

		if (this.multiple && this.value instanceof Array) {
			const values: any[] = this.value.slice();
			for (const file of files) {
				if (this.local) {
					values.push(file as any);
				} else {
					values.push(await this.uploadFile(file));
				}
			}
			this.updateValue(values);
		} else if (files.length > 0) {
			const file = files[0];
			if (this.local) {
				this.updateValue(file as any);
			} else {
				this.updateValue(await this.uploadFile(file));
			}
		}
	}


	/**
	 * Upload a document to the resource server.
	 *
	 * @param file - File to be uploaded.
	 */
	public async uploadFile(file: File): Promise<Resource> {
		if (this.local) {
			throw new Error('When local is set true, uploadFile() should not be called');
		}

		const format = FileUtils.getFileExtension(file);

		const form = new FormData();
		form.append('file', file, 'document');
		form.append('format', format);

		const res = new Resource(null, format);
		res.description = file.name;
		res.type = ResourceType.FILE;

		const request = await Service.fetch(ServiceList.resources.file.upload, null, null, form, null);
		res.uuid = request.response.uuid;
		
		return res;
	}

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	/**
	 * Update the value, setting the new value and calling the onChange callback.
	 * 
	 * @param value - New Value of the component.
	 */
	public updateValue(value: (Resource[] | File[] | Resource | File)): void {
		this.writeValue(value);
		this.onChange(this.value);
	}

	public writeValue(value: (Resource[] | File[] | Resource | File)): void {
		if (!value) {
			value = this.multiple ? [] : null;
		}

		this.value = value;
	}

	public registerOnTouched(fn: any): void { }

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}

	public hoverFile(index: number): void {
		this.hoverIndex = index;
	}

	public onHoverFileLeave(): void {
		this.hoverIndex = null;
	}

	/**
	 * Upload file on drop
	 */
	public async dropFile(event: any): Promise<void> {
		const files = event.dataTransfer.files;
		if (this.multiple && this.value instanceof Array) {
			const values: any[] = this.value.slice();
			for (const file of files) {
				if (this.local) {
					values.push(file as any);
				} else {
					values.push(await this.uploadFile(file));
				}
			}
			this.updateValue(values);
		} else if (files.length > 0) {
			const file = files[0];
			if (this.local) {
				this.updateValue(file as any);
			} else {
				this.updateValue(await this.uploadFile(file));
			}
		}
		event.preventDefault();
	}

}

