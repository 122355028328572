<div style="height: 110px; margin: 15px;">
	<!-- Search bar -->
	<uno-searchbar style="width: 100%;" [value]="selfStatic.filters.search" (valueChange)="onSearch($event)"></uno-searchbar>

	<!-- Filters -->
	<div style="overflow-x: auto; display: flex; align-items: flex-end; justify-content: flex-start; flex-direction: row; gap: 5px; width: 100%">
		<uno-filter-bar [filters]="selfStatic.filters" [options]="selfStatic.filterOptions" (onChange)="this.onFilterChange($event);">
			<!-- Create action plan -->
			@if (this.selection.length > 0 && (userPermissions.ACTION_PLAN_CREATE | hasPermissions)) {
				<uno-button style="margin-top: 20px;" icon="assets/icons/uno/add.svg" (click)="this.createActionPlan()">
					{{'createActionPlan' | translate}}
				</uno-button>
			}

			<!-- Recommendations -->
			@if (this.selection.length > 0 && (userPermissions.FFP_EDIT | hasPermissions)) {
				<uno-button style="margin-top: 20px;" icon="assets/icons/uno/checklist.svg" (click)="this.appendRecommendations()">
					{{'recommendations' | translate}}
				</uno-button>
			}

			<!-- Bulk Delete -->
			@if (this.selection.length > 0 && (userPermissions.FFP_DELETE | hasPermissions)) {
				<uno-button style="margin-top: 20px;" icon="assets/icons/uno/bin.svg" (click)="this.deleteSelection()">
					{{'delete' | translate}}
				</uno-button>
			}
		</uno-filter-bar>

	</div>
</div>

<div style="width: 100%; height: calc(100% - 130px); overflow: hidden;">
	<uno-responsive-table-list [loadMore]="this.loadTableItems" [totalItems]="this.tableTotalItemsCount" [layout]="this.tableLayout" [pageSize]="this.tablePageSize" [labelShown]="true" [selectable]="true" (rowClick)="app.navigator.navigate('/menu/atex/ffp/edit', {ffp: $event.element.uuid, asset: $event.element.assetUuid, inspection: $event.element.inspection.uuid});" (sortChange)="this.sortChanged($event.sortBy)" (rowChecked)="this.checkedRow($event)">
	</uno-responsive-table-list>
</div>
