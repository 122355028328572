<uno-tab>
	<uno-tab-section title="Headlines" [active]="true">
		<table style="width: 100%; border-collapse:separate; border-spacing:0 20px;">
			<tr>
				<th>Type</th>
				<th>Semibold</th>
				<th>Bold</th>
			</tr>
			<tr>
				<td>h1</td>
				<td class="uno-h1">The quick brown fox jumps over the lazy dog</td>
				<td class="uno-h1-bold">The quick brown fox jumps over the lazy dog</td>
			</tr>
			<tr>
				<td>h2</td>
				<td class="uno-h2">The quick brown fox jumps over the lazy dog</td>
				<td class="uno-h2-bold">The quick brown fox jumps over the lazy dog</td>
			</tr>
			<tr>
				<td>h3</td>
				<td class="uno-h3">The quick brown fox jumps over the lazy dog</td>
				<td class="uno-h3-bold">The quick brown fox jumps over the lazy dog</td>
			</tr>
			<tr>
				<td>h4</td>
				<td class="uno-h4">The quick brown fox jumps over the lazy dog</td>
				<td class="uno-h4-bold">The quick brown fox jumps over the lazy dog</td>
			</tr>
			<tr>
				<td>h5</td>
				<td class="uno-h5">The quick brown fox jumps over the lazy dog</td>
				<td class="uno-h5-bold">The quick brown fox jumps over the lazy dog</td>
			</tr>
			<tr>
				<td>h6</td>
				<td class="uno-h6">The quick brown fox jumps over the lazy dog</td>
				<td class="uno-h6-bold">The quick brown fox jumps over the lazy dog</td>
			</tr>
			<tr>
				<td>h7</td>
				<td class="uno-h7">The quick brown fox jumps over the lazy dog</td>
				<td class="uno-h7-bold">The quick brown fox jumps over the lazy dog</td>
			</tr>
			<tr>
				<td>h8</td>
				<td class="uno-h8">The quick brown fox jumps over the lazy dog</td>
				<td class="uno-h8-bold">The quick brown fox jumps over the lazy dog</td>
			</tr>
		</table>
	</uno-tab-section>
	<uno-tab-section title="Paragraphs">
		<table style="width: 100%; border-collapse:separate; border-spacing:0 20px;">
			<tr>
				<td>Type</td>
			</tr>
			<tr>
				<td>p1</td>
				<td class="uno-p1">The quick brown fox jumps over the lazy dog</td>
			</tr>
			<tr>
				<td>p2</td>
				<td class="uno-p2">The quick brown fox jumps over the lazy dog</td>
			</tr>
			<tr>
				<td>p3</td>
				<td class="uno-p3">The quick brown fox jumps over the lazy dog</td>
			</tr>
		</table>
	</uno-tab-section>
	<uno-tab-section title="Buttons">
		<table style="width: 100%; border-collapse:separate; border-spacing:0 20px;">
			<tr>
				<td>Type</td>
			</tr>
			<tr>
				<td>Large Button</td>
				<td class="uno-large-button">The quick brown fox jumps over the lazy dog</td>
			</tr>
			<tr>
				<td>Medium Button</td>
				<td class="uno-medium-button">The quick brown fox jumps over the lazy dog</td>
			</tr>
			<tr>
				<td>Small Button</td>
				<td class="uno-small-button">The quick brown fox jumps over the lazy dog</td>
			</tr>
			<tr>
				<td>X Small Button</td>
				<td class="uno-x-small-button">The quick brown fox jumps over the lazy dog</td>
			</tr>
		</table>
	</uno-tab-section>
	<uno-tab-section title="Other">
		<table style="width: 100%; border-collapse:separate; border-spacing:0 20px;">
			<tr>
				<td>Type</td>
			</tr>
			<tr>
				<td>Small Text</td>
				<td class="uno-small">The quick brown fox jumps over the lazy dog</td>
			</tr>
		</table>
	</uno-tab-section>
</uno-tab>
