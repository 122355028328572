<div style="height: 110px; margin: 10px;">
	<div style="display:flex; align-items: center; gap: 5px;">
		<!-- Add button -->
		@if (([userPermissions.DL50_SUPERVISOR, this.userPermissions.DL50_INSPECTIONS_CREATE] | hasPermissions) && (this.selfStatic.selectedFilters.status === this.inspectionStatus.ALL || this.selfStatic.selectedFilters.status === this.inspectionStatus.IN_PROGRESS)) {
			<uno-button icon="assets/icons/uno/add.svg" (click)="app.navigator.navigate('/menu/dl50/inspections/edit', {createMode: true})">
				@if (app.device.isDesktop()) {
					{{'create' | translate}}
				}
			</uno-button>
		}

		<!-- Search bar -->
		<uno-searchbar style="width: 100%;" [value]="this.selfStatic.selectedFilters.search" (valueChange)="onSearch($event)"></uno-searchbar>
	</div>
	<div style="display: flex; align-items: center;">
		<uno-filter-bar [filters]="this.selfStatic.selectedFilters" [options]="(this.status === this.inspectionStatus.ALL) ? this.selfStatic.filterOptions.concat([this.selfStatic.statusFilterOption]) : this.selfStatic.filterOptions" (onChange)="this.onFilterChange($event);">
		</uno-filter-bar>
		@if (([userPermissions.DL50_SUPERVISOR, this.userPermissions.DL50_INSPECTIONS_EDIT] | hasPermissions) && this.checkedInspections.length > 0) {
			<uno-button icon="assets/icons/uno/checklist.svg" (click)="this.validateInspections(this.checkedInspections);" color="success" style="margin-left: 5px; margin-top: 16px;">
				{{'validate' | translate}}
			</uno-button>
		}
	</div>
</div>

<uno-content [ngStyle]="{height: 'calc(100% - 130px)'}">
	<uno-responsive-table-list [layout]="this.layout" [loadMore]="this.loadMore" [totalItems]="this.totalItems" [selectable]="this.status === this.inspectionStatus.VALIDATION" [pageSize]="this.tablePageSize" [rowClickable]="true" [labelShown]="true" (rowClick)="app.navigator.navigate('/menu/dl50/inspections/edit', {uuid: $event.element.uuid});" (sortChange)="this.sortChanged($event.sortBy)" (rowChecked)="this.checkRows($event.items)">
	</uno-responsive-table-list>
</uno-content>
