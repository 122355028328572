import {SortDirection} from 'src/app/utils/sort-direction';
import {EnumUtils} from 'src/app/utils/enum-utils';
import {RBACLayout} from 'src/app/modules/roles/screens/rbac-layout';
import {UnoFormField} from 'src/app/components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from 'src/app/components/uno-forms/uno-form/uno-form-field-types';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {InspectionForm} from '../../../../models/inspections/form/inspection-form';
import {InspectionWorkflowStepStatus, InspectionWorkflowStepStatusLabel} from '../../../../models/inspections/workflow/inspection-workflow-step-status';
import {InspectionFormService} from '../../services/inspection-form.service';

export const InspectionWorkflowStepLayout: UnoFormField[] = [
	{
		label: 'uuid',
		attribute: 'uuid',
		type: UnoFormFieldTypes.UUID
	},
	{
		required: true,
		attribute: 'name',
		label: 'name',
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: false,
		attribute: 'description',
		label: 'description',
		type: UnoFormFieldTypes.TEXT_MULTILINE
	},
	{
		required: false,
		attribute: 'bypassRequiredCheck',
		label: 'bypassRequiredCheck',
		type: UnoFormFieldTypes.CHECKBOX
	},
	{
		required: true,
		attribute: 'defaultStep',
		label: 'defaultStep',
		type: UnoFormFieldTypes.CHECKBOX
	},
	{
		attribute: 'status',
		label: 'status',
		required: true,
		type: UnoFormFieldTypes.OPTIONS,
		sort: false,
		options: EnumUtils.values(InspectionWorkflowStepStatus).map(function(value) {
			return {value: value, label: InspectionWorkflowStepStatusLabel.get(value as InspectionWorkflowStepStatus)};
		})
	},
	{
		required: false,
		label: 'color',
		attribute: 'color',
		type: UnoFormFieldTypes.COLOR
	},
	{
		isActive: false,
		required: true,
		attribute: 'workflowUuid',
		label: 'workflow',
		type: UnoFormFieldTypes.OPTIONS,
		options: [{value: null, label: 'none'}],
		fetchOptions: async function(object: any, row: UnoFormField): Promise<void> {
			const request = await Service.fetch(ServiceList.inspection.workflow.list, null, null, null, Session.session);
			const response = request.response;

			row.options = [{value: null, label: 'none'}];
			for (let i = 0; i < response.workflows.length; i++) {
				row.options.push({
					value: response.workflows[i].uuid,
					label: response.workflows[i].name
				});
			}
		}
	},
	{
		required: false,
		attribute: 'formUuid',
		label: 'form',
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
		multiple: false,
		identifierAttribute: 'uuid',
		fetchOptionsLazy: async function(request: any): Promise<{options: any[], hasMore: boolean, id: number}> {
			const data = {
				from: request.from,
				count: request.count,
				search: request.search,
				sortField: '[inspection_form].[name]',
				sortDirection: SortDirection.ASC
			};

			const req = await InspectionFormService.list(data);
			return {options: req.forms, hasMore: req.hasMore, id: req.id};

		},
		fetchOptionsBatch: async function(request: any): Promise<{options: any[]}> {
			const data = {forms: request.options};

			const req = await Service.fetch(ServiceList.inspection.form.listBatch, null, null, data, Session.session);
			return {options: req.response.forms};
		},
		getOptionText: function(option: InspectionForm): string {
			return option.name;
		}
	},
	{
		required: false,
		attribute: 'analysesGaps',
		label: 'analysesGaps',
		type: UnoFormFieldTypes.CHECKBOX
	},
	...RBACLayout
];
