import {Component, Input, ViewEncapsulation, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule} from '@angular/forms';
import {IonicSelectableComponent} from 'ionic-selectable';
import {TranslateModule} from '@ngx-translate/core';

import {IonicModule} from '@ionic/angular';
import {TeamService} from 'src/app/modules/teams/services/teams.service';
import {Session} from '../../../session';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {App} from '../../../app';
import {UserPermissions} from '../../../models/users/user-permissions';
import {Team} from '../../../models/teams/team';
import {UUID} from '../../../models/uuid';

/**
 * Component used to select team from a list.
 */
@Component({
	selector: 'uno-team-selector',
	templateUrl: './uno-team-selector.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => { return UnoTeamSelectorComponent; }),
		multi: true
	}],
	standalone: true,
	imports: [IonicModule, IonicSelectableComponent, FormsModule, TranslateModule]
})
export class UnoTeamSelectorComponent implements ControlValueAccessor {
	public permissions: any = UserPermissions;

	public app: any = App;
	
	/**
	 * Indicate if the user can select multiple teams.
	 *
	 * When selecting multiple the value stored is an array.
	 */
	@Input()
	public multiple: boolean = true;

	/**
	 * Allow the input to be disabled.
	 */
	@Input()
	public disabled: boolean = false;

	/**
	 * If set, clear button will be shown on component to clear its value.
	 */
	@Input()
	public showClear: boolean = true;

	/**
	 * If set, sorts all the items alphabetically upon fetch.
	 */
	@Input()
	public sort: boolean = false;

	/**
	 * List of the selected team UUID stored in this component.
	 */
	public value: (UUID[] | UUID) = [];

	/**
	 * Teams that are selected on the object and are not yet available from the API.
	 *
	 * These should be retrieved from the API.
	 */
	public baseOptions: Team[] = [];

	/**
	 * Teams available based on the search value inserted.
	 */
	public options: Team[] = [];

	/**
	 * Search value inserted.
	 */
	public search: string = '';

	/**
	 * Number of elements to fetch on each request when using lazy loading.
	 */
	public batchSize: number = 20;

	/**
	 * Number of element already loaded from the API, used to control lazy loading.
	 */
	public count: number = 0;

	/**
	 * Load more data from the API to display. Only loads a chunk of data at a time.
	 *
	 * @param component - Component, used to control the data flow.
	 * @param isSearch - If set true its assumed to be a search or first request, the lazy loader counter is reset and data is cleared.
	 */
	public async loadDataLazy(component?: any, isSearch: boolean = false): Promise<void> {
		// Auxiliary method to add loaded options to the list
		const addOptions = (options): void => {
			for (let i = 0; i < options.length; i++) {
				// Check the uuid already exists in the base options that were pre-loaded
				if (this.baseOptions.find(function(a) { return a.uuid === options[i].uuid; }) === undefined) {
					if (!this.options) {
						this.options = [];
					}
					
					this.options.push(options[i]);
				}
			}
		};

		const data: any = {
			from: isSearch ? 0 : this.count,
			count: this.batchSize,
			search: this.search
		};

		if (this.sort) {
			data.sortField = 'name';
			data.sortDirection = 'ASC';
		}

		const request = await Service.fetch(ServiceList.teams.list, null, null, data, Session.session);

		// If is it a search result clean up old options
		if (isSearch) {
			if (component) {
				component.enableInfiniteScroll();
			}

			this.options = this.baseOptions.concat([]);
			addOptions(request.response.teams);
			this.count = request.response.teams.length;
		} else {
			addOptions(request.response.teams);
			this.count += request.response.teams.length;
		}

		if (component) {
			if (!request.response.hasMore) {
				component.disableInfiniteScroll();
			}
			if (isSearch) {
				component.endSearch();
			}
			component.endInfiniteScroll();
		}

	}

	/**
	 * Load base options from the API server, these options are the ones selected on the object.
	 *
	 * They need to be fetched to show on the list (their name is not known, if their info is not retrieved).
	 */
	public async loadBaseOptions(): Promise<void> {
		if (!this.value) {
			return;
		}

		const teamsUuids: any = this.multiple ? this.value : [this.value];

		this.baseOptions = await TeamService.getBatch(teamsUuids);
		this.options = this.baseOptions.concat([]);
	}

	/**
	 * Callback method called when the user inputs something into the search box.
	 */
	public onSearch(event: {component: IonicSelectableComponent, text: string}): void {
		this.search = event.text;
		this.loadDataLazy(event.component, true);
	}

	/**
	 * Callback method used to load more data from the API.
	 */
	public onInfiniteLoad(event: {component: IonicSelectableComponent, text: string}): void {
		this.loadDataLazy(event.component, false);
	}

	/**
	 * Method called when the data is changed.
	 */
	public onChange: (value: any)=> void = function() {};

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	/**
	 * Update the value, setting the new value and calling the onChange callback.
	 * 
	 * @param value - New Value of the component.
	 */
	public updateValue(value: any): void {
		this.value = value;
		this.onChange(value);
		this.loadBaseOptions();
	}

	public writeValue(value: any): void {
		this.value = value;
		this.loadBaseOptions();
	}

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}

	public registerOnTouched(fn: any): void {}
}
