import {Themes} from '../../../theme/themes';
import {Theme} from '../../../theme/theme';
import {ThemeOS} from '../../../theme/theme-os';
import {MapStyles} from '../../../theme/map-styles';
import {PictureFormat} from './picture-format';
import {DateFormat} from './date-format';
import {TimeFormat} from './time-format';
import {UIScale} from './ui-scale';

/**
 * User specific settings class stores/manages all the user specific configurable elements of the application.
 */
export class Settings {
	/**
	 * Locale language to be used in the application.
	 */
	public locale: string = 'en';

	/**
	 * If true the application will load picture list previews
	 *
	 * Set to false to save on data usage.
	 */
	public showListPictures: boolean = true;

	/**
	 * If true the application will compress pictures.
	 */
	public compressPictures: boolean = true;

	/**
	 * Maximum size in pixel of the photos taken by the application.
	 *
	 * Is applied in booth axis to the photo (keep the aspect ratio of the image).
	 */
	public pictureMaxSize: number = 2000;

	/**
	 * Preferred photo picture format.
	 */
	public pictureFormat: PictureFormat = PictureFormat.JPEG;

	/**
	 * Picture encoding quality level to be used (only for lossy formats).
	 */
	public pictureQuality: number = 0.7;

	/**
	 * ID of the app theme to be used in the GUI.
	 */
	public theme: string = ThemeOS.preferredTheme();

	/**
	 * Format to present dates on interface.
	 */
	public dateFormat: DateFormat = DateFormat.DEFAULT;

	/**
	 * Format to present time.
	 */
	public timeFormat: TimeFormat = TimeFormat.DEFAULT;

	/**
	 * If set true search fields and filters will be kept when returning to pages.
	 */
	public keepFilters: boolean = true;

	/**
	 * If set true the application will not open more than one ta.
	 *
	 * It will reuse the one already to open new pages.
	 */
	public keepExternalWindow: boolean = false;

	/**
	 * MapBox map style to be applied to the map visualizations.
	 */
	public mapStyle: string = MapStyles.VECTOR;

	/**
	 * If true the application sidebar is expanded by default.
	 */
	public sidebarExpanded: boolean = false;

	/**
	 * Scale applied to the user interface.
	 * 
	 * Directly changes the zoom level of the interface.
	 */
	public scale: number = UIScale.DEFAULT;

	/**
	 * Update a user settings object to contain newly added options.
	 *
	 * @param settings - Settings object.
	 */
	public static update(settings: Settings): Settings {
		// Create a new settings object to extract default values
		const base = new Settings();

		// Add missing attributes to loaded data
		for (const i in base) {
			if (settings[i] === undefined) {
				settings[i] = base[i];
			}
		}

		// Update scale
		try {
			// @ts-ignore
			document.body.style.zoom = Number.parseInt(Math.round(settings.scale * 100)) + '%';
		} catch (e) {}

		// If theme is invalid, if so set it to a valid option
		const themeExists: boolean = Themes.filter(function(val: Theme) {
			return val.id === settings.theme;
		}).length > 0;

		if (!themeExists) {
			settings.theme = Themes[0].id;
		}

		return settings;
	}
}
