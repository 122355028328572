<div style="height: 30px; margin: 10px;">
	<div style="display:flex; align-items: center; gap: 5px;">
		@if ([userPermissions.DL50_SUPERVISOR, userPermissions.DL50_MASTER_DATA_REGULATORY_STANDARDS_CREATE] | hasPermissions) {
			<!-- Add button -->
			<uno-button icon="assets/icons/uno/add.svg" (click)="this.save()">
				@if (app.device.isDesktop()) {
					{{'create' | translate}}
				}
			</uno-button>
		}
		<!-- Search bar -->
		<uno-searchbar style="width: 100%;" [value]="selfStatic.filters.search" (valueChange)="onSearch($event)"></uno-searchbar>
	</div>
</div>

<uno-content [ngStyle]="{height: 'calc(100% - 50px)'}">
	<uno-responsive-table-list [layout]="this.tableLayout" [loadMore]="this.loadMore" [totalItems]="this.totalItems" [selectable]="false" [pageSize]="this.tablePageSize" [rowClickable]="true" [labelShown]="true" (rowClick)="this.save($event.element.uuid);">
	</uno-responsive-table-list>
</uno-content>
