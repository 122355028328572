export const LocaleES = {
	// App
	app: 'UNO Asset Management',
	uno: 'UNO',
	eqs: 'EQS',

	// Locales
	portuguese: 'Portugués',
	english: 'Inglés',
	spanish: 'Español',

	// Months
	january: 'Enero',
	february: 'Febrero',
	march: 'Marzo',
	april: 'Abril',
	may: 'Mayo',
	june: 'Junio',
	july: 'Julio',
	august: 'Agosto',
	september: 'Septiembre',
	october: 'Octubre',
	november: 'Noviembre',
	december: 'Diciembre',

	// Week days
	monday: 'Lunes',
	tuesday: 'Martes',
	wednesday: 'Miércoles',
	thursday: 'Jueves',
	friday: 'Viernes',
	saturday: 'Sábado',
	sunday: 'Domingo',

	// Forms
	text: 'Texto',
	checkbox: 'Caja',
	document: 'Documento',

	// Login
	welcome: 'Bienvenido!',
	enterCredentials: 'Ingrese los datos de su cuenta para iniciar sesión.',
	login: 'Acceso',
	logout: 'Cerrar Sesión',
	forgotPassword: 'Recuperar la contraseña de la cuenta.',
	confirmPassword: 'Confirmar Contraseña',
	returnLogin: 'Regrese para iniciar sesión.',
	twoFactorAuth: 'Autenticación de Dos Factores',
	email: 'Email',
	sms: 'SMS',
	password: 'Contraseña',
	reset: 'Reiniciar',
	recover: 'Recuperar',
	code: 'Código',
	recoverEmail: 'Se envió un correo electrónico con instrucciones para recuperar el acceso a la plataforma.',
	enterNewPasswordReset: 'Ingrese su nueva contraseña y haga clic en restablecer para cambiarla.',
	enterEmailToRecover: 'Ingrese su correo electrónico de usuario para recuperar el acceso a su cuenta.',
	passwordsDontMatch: 'Las contraseñas no coinciden.',
	passwordResetSuccess: 'La contraseña se restableció correctamente.',
	passwordResetError: 'Hubo un error al intentar restablecer su contraseña.',
	passwordSizeError: 'La contraseña debe tener, al menos, 8 caracteres.',
	loginSuccess: 'El inicio de sesión fue exitoso.',
	invalidLogin: 'Credenciales de acceso no válidas. Revisa tu correo electrónico y contraseña.',
	invalidPhoneNumber: 'El número de teléfono no es válido.',
	invalidEmail: 'El correo no es válido.',
	twoFactorCode: 'La autenticación de dos factores está habilitada para esta cuenta. Se ha enviado un código a su correo electrónico/teléfono. Proporcione el código para continuar.',

	// Master data
	masterdata: 'Datos Maestros',
	ffpRecommendations: 'Recomendaciones de FFP',
	inspectionChecklists: 'Listas de verificación de inspección',
	inspectionFields: 'Campos de inspección',
	masterdataConfirm: '¿Está seguro de que desea actualizar estos valores?',
	errorRepeatedID: 'ID {id} utilizado varias veces para diferentes entradas.',
	errorFieldInspectionMissing: 'El campo {field} de inspección {inspection} no existe.',
	errorFieldInspectionRemoved: 'Se eliminó el campo {field} utilizado en la inspección {inspection}.',
	errorAttributeShouldHaveKeyValue: 'El atributo {attribute} debe tener el mismo valor que la clave del objeto.',
	errorOnlyBackofficeInspector: 'El campo de inspección {field} solo puede pertenecer al backoffice oa la inspección.',

	// File formats
	docx: 'DOCX',
	pdf: 'PDF',
	zip: 'ZIP',

	// Import/Export
	global: 'Global',
	import: 'Importar',
	export: 'Exportar',
	exportJSON: 'Exportar JSON',
	dataGenerators: 'Generadores de Datos',
	tools: 'Herramientas',
	howMany: 'Cuantos',
	generate: 'Generar',
	restore: 'Restaurar',
	database: 'Base de Datos',
	size: 'Tamaño',
	runGapAnalysis: 'Ejecutar análisis de gaps',
	runGapAnalysisSub: 'Ejecute análisis de gaps para todas las inspecciones. Se recrearon espacios eliminados y se restauraron espacios archivados.',
	generateQRTags: 'Etiquetas de código QR',
	generateQRTagsSub: 'Genere múltiples códigos QR con valor aleatorio para adjuntar a los equipos.',
	checkAtexInspectionsEmptyFields: 'Compruebe los campos vacíos de las inspecciones ATEX',
	checkAtexInspectionsEmptyFieldsSub: 'Compruebe si cada inspección ATEX tiene todos los campos llenos.',
	checkAtexInspectionNoForms: 'Verifique la selección del formulario de inspecciones ATEX',
	checkAtexInspectionNoFormsSub: 'Verifique si todas las inspecciones tienen formularios seleccionados.',
	defaultAtexInspectionForm: 'Formulario de inspección ATEX predeterminado',
	defaultAtexInspectionFormError: 'El formulario de inspección predeterminado no existe, consulte la sección de lista de verificación de datos maestros.',
	updateReports: 'Actualizar Informes',
	updateReportsSub: 'Actualizar informes para todos los proyectos de inspección disponibles.',
	exportXlsx: 'Exportar XLSX',
	exportGapsXlsx: 'Gaps XLSX',
	exportGapsXlsxSub: 'Exporta archivo XLSX con todas las Gaps y recomendaciones de Gaps insertadas en la plataforma.',
	epiJson: 'CSI JSON',
	exportEPIJsonSub: 'Exporte un archivo JSON que contenga todos los datos CSI (circuito de seguridad intrínseca) disponibles en la plataforma.',
	ffpJson: 'JSON de FFP',
	exportFFPJsonSub: 'Exporte un archivo JSON que contenga todos los datos FFP (Fit For Purpose) disponibles en la plataforma.',
	assetsJson: 'Activos JSON',
	exportAssetsJsonSub: 'Exporta un archivo JSON que contiene los datos de los activos insertados en la plataforma.',
	assetsXlsx: 'Activos XLSX',
	exportAssetsXlsxSub: 'Exporte un archivo de XLSX que contenga solamente los datos de los activos insertados en la plataforma.',
	exportAssetsDetailedXlsx: 'Activos XLSX',
	exportAssetsDetailedXlsxSub: 'Exporte un archivo de XLSX que contenga los datos detallados de los activos insertados en la plataforma.',
	keyQuestionCodesXlsx: 'Códigos de preguntas clave XLSX',
	atexInspectionsXlsx: 'Inspecciones ATEX XLSX',
	exportAtexInspectionsXlsxSub: 'Exporta un archivo XLSX que contiene las inspecciones ATEX insertadas en la plataforma.',
	inspectionsXlsx: 'Inspecciones XLSX',
	exportInspectionsXlsxSub: 'Exporta un archivo XLSX que contiene las inspecciones de todos los proyectos de inspección dinámica en la plataforma.',
	inspectionsAverageXlsx: 'Promedio de inspecciones XLSX',
	exportInspectionsAverageXlsxSub: 'Exporta un archivo xlsx que contiene las inspecciones de todos los proyectos de inspección dinámica insertados en la plataforma con valores numéricos para las respuestas de la lista de verificación. Se puede cargar un archivo con códigos de preguntas clave. Cada hoja de proyecto tiene una columna adicional con detalles de error.',
	inspectionsJson: 'Inspecciones JSON',
	exportInspectionsJsonSub: 'Exporta todas las inspecciones dinámicas existentes en la plataforma como JSON. Incluye formularios, flujos de trabajo, proyectos y datos de inspección.',
	importInspectionWorkflowJSON: 'Flujo de trabajo JSON',
	importInspectionWorkflowJSONSub: 'Importe un flujo de trabajo de inspección desde un archivo JSON. Importa formularios y flujos de trabajo al módulo de inspección.',
	atexInspectionsAuditXlsx: 'Auditoría de inspecciones ATEX XLSX',
	exportAtexInspectionsAuditXlsxSub: 'Exporte un archivo XLSX que contenga información completa de las inspecciones ATEX existentes, así como todo el historial de cambios.',
	atexInspectionsXlsxResults: 'Resultados de las inspecciones ATEX XLSX',
	exportAtexInspectionsXlsxResultsSub: 'Exporte un archivo de XLSX que contenga los resultados de todas las inspecciones ATEX completadas.',
	atexInspectionsReports: 'Informes de inspecciones ATEX',
	atexInspectionsReportsSub: 'Exporte informes para todas las inspecciones ATEX disponibles en la plataforma.',
	atexInspectionsJson: 'Inspecciones ATEX JSON',
	exportAtexInspectionsJson: 'Exporte un archivo JSON que contenga todas las inspecciones ATEX insertadas en la plataforma con sus detalles asociados.',
	ffpXlsx: 'FFP XLSX',
	exportFFPXlsxSub: 'Exporte un archivo de XLSX con todas las entradas FFP (Fit For Purpose), junto con sus detalles, inspección asociada y datos de activos.',
	actionPlanXlsx: 'Plan de acción XLSX',
	exportActionPlanXlsxSub: 'Exporte todas las entradas del plan de acción como un archivo de XLSX junto con sus detalles asociados a FFP, inspección y datos de activos.',
	atexInspectionsBulkFields: 'Cambiar campos de inspección ATEX de forma masiva por etiqueta de activo',
	atexInspectionsBulkFieldsSub: 'Cambie la respuesta de campo de una lista de inspecciones ATEX a un valor definido.',
	bulkActionPlansFFP: 'Crear planes de acción a partir de entradas de FFP por etiqueta de activo',
	bulkActionPlansFFPSub: 'Complete los datos de FFP para las etiquetas de los equipos y los campos de una lista (R1, R2, etc.).',
	bulkDeleteFFPUnused: 'Eliminar FFP No usados',
	bulkDeleteFFPUnusedSub: 'Elimine todos los FFP asociados con los campos de inspección que tengan la respuesta "No aplicable" o de inspección ATEX con campos no utilizados.',
	repairsXlsx: 'Reparaciones XLSX',
	exportRepairsXlsxSub: 'Exporta un archivo XLSX, que contiene los datos completos de las reparaciones insertadas en la plataforma.',
	importRepairsXlsxSub: 'Importe una lista de reparaciones del archivo de XLSX.',
	importcalendarXlsxSub: 'Importe una lista de eventos de calendario del archivo de XLSX',
	calendarXlsx: 'Planificación de Activos XLSX',
	epiXlsx: 'CSI XLSX',
	importEpiXlsx: 'Importe una lista de datos CSI a la aplicación desde un archivo XLSX.',
	exportEPIXlsxSub: 'Exporte una lista de las entradas CSI en formato XLSX.',
	importRENXlsxDev: 'Activos REN XLSX (SOLO DEV)',
	importRENXlsxSubDev: 'Herramienta para importar activos REN y crear jerarquía de cartera de activos desde un archivo XLSX.',
	importAssetsXlsx: 'Activos XLSX',
	importAssetsXlsxSub: 'Herramienta para importar activos con información base, de un archivo XLSX',
	exportInspectionsReportsBulk: 'Informes de Inspección',
	exportInspectionsReportsBulkSub: 'Exporta los informes de todas las inspecciones asociadas con los activos contenidos en una lista presentada en una sola columna de un archivo XLSx con "activos" en el encabezado.',
	exportRepairInspectionsReportsBulk: 'Informes de inspección de reparación',
	exportRepairInspectionsReportsBulkSub: 'Exporte informes de todas las inspecciones de reparación.',
	exportFormBlocksXlsx: 'Bloques de formulario (XLSX)',
	exportFormBlocksXlsxSub: 'Exporta los bloques de formulario de la cartera de activos y todos sus campos de formulario en formato XLSX. ',
	importFormBlocksXlsx: 'Bloques de formulario (XLSX)',
	importFormBlocksXlsxSub: 'Importa bloques de formulario y sus campos de formulario',
	importFormBlocksXlsxAdditionalInformation: 'Esta herramienta puede provocar la pérdida permanente de datos.\n\nLos elementos con UUID se actualizarán; de lo contrario, se crearán. Los campos de bloque de formulario deben hacer referencia a un bloque de formulario en la hoja de bloques de formulario, ya sea por UUID o por nombre. Los campos de bloque de formulario sin indexación se indexarán por su orden en el archivo. Si se seleccionan las opciones de "eliminación de elementos no presentes en el documento", estos elementos serán eliminados definitivamente.',
	deleteMissingItemsWarning: 'Seleccionó opciones para eliminar permanentemente elementos que no están presentes en el documento.',
	exportHasEnded: 'La exportación ha finalizado.',
	exportAssetPortfolioStructureXlsx: 'Estructura de la cartera de activos XLSX',
	exportAssetPortfolioStructureXlsxSub: 'Exporta la estructura completa de los tipos, subtipos y bloques de formulario de la cartera de activos en formato XLSX.',
	exportAssetPlanningXlsxSub: 'Exporta los eventos del calendario mostrados actualmente, aplicando los filtros activos.',
	userXlsx: 'Usuario Excel',
	exportInspectionsUserSub: 'Exporta todos los usuarios en formato XLSX.',
	errorMissingFormOnImport: 'Hay referencias a un formulario con el UUID "{formUuid}" pero falta en el archivo importado.',

	// Inspections
	noFormFields: 'No hay campos de formulario disponibles.',
	noWorkflowSteps: 'No hay pasos de flujo de trabajo disponibles.',
	noWorkflowAdjacencies: 'No hay adyacencias de flujo de trabajo disponibles.',
	createAtexInspection: 'Crear inspección ATEX',
	atexInspectionReportIEC60079: 'Las inspecciones realizadas cumplen con IEC 60079-17 - Atmósferas explosivas - Parte 17: Inspección y mantenimiento de instalaciones eléctricas.',
	atexInspectionReportFormLabel: 'OK - Aprobado, el equipo cumple con los requisitos.',
	atexInspectionReportDisclaimer: 'Los resultados solo se refieren a los elementos probados.',
	atexInspectionReport: 'Informe de inspección de equipos ATEX',
	inspectionPhotos: 'Industria cinematográfica Fotos',
	equipmentData: 'Datos del Equipo',
	inspectionDetails: 'Detalles de inspección',
	inspectionDate: 'Fecha de Inspección',
	documentDate: 'Fecha del Documento',
	myTeamOnly: 'Solo mi equipo',
	withDocuments: 'Con Documentos',
	waitingDocuments: 'Esperando Documentos',
	inspectionReport: 'Reporte de inspección',
	details: 'Detalles',
	resultInspection: 'Resultado de Inspeccion',
	resultFinal: 'Resultado Final',
	nok: 'No está bien',
	okRevised: 'Ok EQS',
	todo: 'Para Hacer',
	review: 'Revisar',
	approved: 'Aprobado',
	disapproved: 'Desaprobado',
	pending: 'Pendiente',
	conditionallyApproved: 'Aprobado Condicionalmente',
	completed: 'Terminado',
	implemented: 'Implementado',
	underSupervision: 'Supervisión',
	failed: 'Ha Fallado',
	inspection: 'Inspección',
	inspectionNumber: 'Número de inspección',
	atexInspection: 'Inspección ATEX',
	inspectorsCount: 'Los inspectores cuentan',
	inspectionForms: 'Formularios de inspección',
	inspectionProject: 'Proyecto de inspección',
	inspectionProjects: 'Proyectos de inspección',
	inspectionProjectUuid: 'UUID del Proyecto de inspección',
	inspectorForm: 'Formulario de Inspector',
	backofficeForm: 'Formulario de Backoffice',
	approve: 'Aprobar',
	reject: 'Rechazar',
	clientFeedback: 'Comentarios del Cliente',
	messageSupervisor: 'Mensaje al supervisor.',
	messageRejection: 'Justificación del rechazo',
	inspectionRejected: 'Inspección rechazada',
	exportReport: 'Reporte de Exportacion',
	exportReportPDF: 'Exportar informe (PDF)',
	exportReportDOCX: 'Exportar informe (Word)',
	editAsset: 'Editar Activo',
	confirmReject: '¿Está seguro de que desea rechazar la inspección?',
	confirmRedirect: 'Si continúa con esta acción, todos los datos no guardados se perderán. Confirme para continuar o cancele esta acción y guarde los datos antes de continuar.',
	sendToSupervision: 'Enviar al supervisor',
	returnToTodo: 'Volver a Hacer',
	returnToRevision: 'Devolución para revisión',
	returnToBackoffice: 'Regresar a Backoffice',
	successInspectionSave: 'Los datos de inspección se guardaron correctamente.',
	successInspection: 'Los datos de inspección se enviaron correctamente.',
	errorInspectionResult: 'Debe seleccionar aprobado o reprobado para el resultado de la inspección.',
	errorSelectForm: 'Es necesario seleccionar al menos un formulario de inspección.',
	errorInspectionState: 'Se encontró un estado de inspección no válido.',
	errorInspectionNotFound: 'La inspección no se encontró en la base de datos.',
	warningSelectForm: 'No ha seleccionado ningún formulario de inspección.',
	warningApproveNok: 'El resultado de la inspección se estableció como aprobado, pero uno o más campos se marcaron como incorrectos.',
	exportAllXlsx: 'Exportar todo (XLSX)',
	projectName: 'Nombre del Proyecto',
	projectCode: 'Código de Proyecto',
	createInspection: 'Crear Inspección',
	optionsDisplayMode: 'Modo de visualización de opciones',
	checklistAnswersAvg: 'Promedio de respuestas de la lista de verificación',
	checklistWightedAnswersAvg: 'Promedio ponderado de respuestas de la lista de verificación',
	projectChecklistWeight: 'Peso de la lista de verificación',
	bypassRequiredCheck: 'Omitir verificación requerida',
	confirmRequiredCheckBypass: 'Algunos campos del formulario son obligatorios y están vacíos. ¿Piensas quedártelo de todos modos?',
	lockStepData: 'Bloquear datos de pasos',
	true: 'Verdadero',
	false: 'Falso',
	keyQuestionCodesFile: 'Archivo de preguntas clave',
	overallAssessment: 'Evaluación global',
	assetStatus: 'Estado del Activo',
	inspectionQR: 'Inspección QR',
	createNewInspectionConfirmation: 'No existen inspecciones para este evento. Desea crear una nueva?',
	article: 'Artículo',
	navigateNextStep: '¿Desea navegar al siguiente paso de esta inspección?',
	allowExportReportFromList: 'Permitir la exportación de informes desde la lista de inspecciones',

	// Gap Analysis
	gapAnalysis: 'Gap Analysis',
	gap: 'Gap',
	gaps: 'Gaps',
	gapUuid: 'UUID de Gap',
	analysesGaps: 'Analiza Gaps',
	generatesGaps: 'Genera Gaps',
	generatesGapsOptions: 'Opción que genera gaps',
	generatesGapsMultipleOptions: 'Opciones que generam las gaps',
	gapName: 'Nombre de Gap',
	gapDescription: 'Descripción de gap',
	gapDocuments: 'Documentos de Gap',
	inspectionDataUuid: 'UUID datos de inspección',
	inspectionFieldUuid: 'UUID campo de inspección',
	financialImpact: 'Impacto Financiero',
	gapRecommendations: 'Recomendaciones de Gaps',
	recommendationUuid: 'UUID de recomendación',
	recommendationDescription: 'Descripción de la recomendación',
	recommendationDocuments: 'Documentos de recomendación',

	// Expression Editor
	expressionEditor: 'Editor de Expresiones',

	// Observation
	createObservation: 'Crear Observación',
	observationCreated: 'La observación se creó con éxito',
	observationUpdated: 'La observación se actualizó correctamente',

	// Atex
	unclassified: 'Desclasificado',
	unclassifiedZone: 'Zona sin clasificar',
	zone0: 'Zona 0',
	zone1: 'Zona 1',
	zone2: 'Zona 2',
	zone20: 'Zona 20',
	zone21: 'Zona 21',
	zone22: 'Zona 22',
	mine: 'Mío',
	errorAtexTags: 'La propiedad ATEX debe tener al menos una etiqueta.',
	resultAtexInspection: 'Resultado de la inspección ATEX',
	resultAtexFinal: 'Resultado final de la inspección ATEX',
	liftingEquipmentRequired: 'Equipo de elevación requerido',
	confirmAtexCreate: '¿Está seguro de que desea crear una inspección ATEX?',
	hasFfp: 'Tiene FFPs',

	// Project
	inspectionTypeRequired: 'Tipo de inspección requerido',

	// Assets
	locations: 'Ubicaciones',
	location: 'Localización',
	plants: 'Plantas',
	plant: 'Planta',
	operationalUnits: 'Unidades Operativas',
	operationalUnit: 'Unidad Operativa',
	operationalUnitTag: 'Etiqueta de unidad operativa',
	equipments: 'Equipos',
	equipment: 'Equipo',
	equipmentTag: 'Etiqueta de Equipo',
	equipmentSubtype: 'Subtipo de Equipo',
	equipmentOwner: 'Propietario del Equipo',
	components: 'Componentes',
	singlePhase16: 'Monofásico 16A',
	triplePhase16: 'Trifásico 16A',
	triplePhase63: 'Trifásico 63A',
	hsx: 'HS X',
	codeLightStruct: 'Código con estructura de diseño de iluminación',
	modelList: 'Lista de Modelos',
	assetAlreadySelected: 'Activo ya seleccionado.',

	// QR
	qr: 'QR',
	qrReader: 'Lector de QR',
	qrCode: 'Código QR',
	generateQR: 'Generar QR',
	attachQR: 'Adjuntar QR',
	errorCameraNotFound: 'Cámara no encontrada / disponible en el dispositivo.',
	qrUnknown: 'El código QR no está asociado con ninguna entrada.',
	qrSuccess: 'Código QR leído correctamente.',

	// NFC
	nfc: 'NFC',
	nfcReader: 'Lector NFC',
	readNFC: 'Etiqueta NFC aproximada',
	attachNFC: 'Adjuntar NFC',
	noNFCReader: 'Dispositivo incompatible con la funcionalidad de lectura NFC.',
	nfcUnknown: 'La etiqueta NFC no está asociada a ningún activo.',
	nfcErrorStarting: 'Error al iniciar el detector de etiquetas NFC, compruebe si NFC está habilitado.',
	nfcSuccessStarting: 'Oyente de etiquetas NFC iniciado correctamente.',
	nfcSuccess: 'Etiqueta NFC leída correctamente.',

	// FFP
	ffpShort: 'FFP',
	ffp: 'Adecuado para el propósito',
	ffpSaved: 'Los datos de FFP se guardaron correctamente.',
	low: 'Bajo',
	medium: 'Medio',
	high: 'Elevado',
	cost: 'Costo',
	priority: 'Prioridad',
	fieldName: 'Nombre del Campo',
	lastFFPSByAsset: 'Últimos FFPs por activo',

	// EPI
	epiShort: 'CSI',
	epi: 'Circuito de seguridad intrínseco',
	zener: 'Zener',
	galvanic: 'Galvánico',
	barrier: 'Barrera',
	barrierId: 'ID de Barrera',
	barrierType: 'Tipo de Barrera',
	cable: 'Cable',
	cables: 'Cables',
	resultingCable: 'Cable Resultante',
	comments: 'Comentarios',
	resistance: 'Resistencia',
	resistanceSection: 'Resistencia de la sección',
	maxOutputVoltage: 'Max.',
	maxOutputCurrent: 'Max.',
	maxOutputPower: 'Max.',
	maxExternalCapacity: 'Max.',
	maxExternalInductance: 'Max.',
	groundResistance: 'Tierra GroundResistance (Rt)',
	maxInputVoltage: 'Max.',
	maxInputCurrent: 'Max.',
	maxInputPower: 'Max.',
	maxInternalCapacity: 'Max.',
	maxInternalInductance: 'Max.',
	length: 'Largo',
	inductance: 'Inductancia',
	cableCapacity: 'Capacidad (Cc)',
	capacitySection: 'Capacidad de la sección',
	cableInductance: 'Inductancia (Lc)',
	inductanceSection: 'Inductancia de la sección',
	intrinsicSecurityTest: 'Prueba de seguridad intrínseca',
	acceptedResult: 'Aceptado / No aceptado',
	epiSaved: 'El circuito de seguridad intrínseca se guardó correctamente.',
	epiSubmitted: 'Circuito de seguridad intrínseca sometido a revisión.',
	epiApproved: 'Circuito de seguridad intrínseca aprobado con éxito.',
	loopEquipments: 'Equipos de Bucle',

	// Digital Twin
	locked: 'Bloqueado',
	fullscreen: 'Pantalla Completa',
	visible: 'Visible',
	top: 'Cima',
	bottom: 'Abajo',
	left: 'Izquierda',
	right: 'Derecha',
	front: 'Delantera',
	pointcloud: 'Nubes de Puntos',
	move: 'Mover',
	rotate: 'Girar',
	scale: 'Redimensionar',
	screenshot: 'Captura de Pantalla',
	navigate: 'Navegar',
	transform: 'Transformar',
	focus: 'Focar',
	selectObjectFirst: 'Para realizar esta operación seleccione un objeto.',
	lockedEditError: 'Los objetos bloqueados no se pueden editar. Desbloquee el objeto antes de aplicar los cambios.',
	
	// Misc
	typography: 'Tipografía',
	selectMultiple: 'Seleccionar Vários',
	displayMode: 'Modo de Visualización',
	sections: 'Secciones',
	tabs: 'Tabs',
	inspectionResult: 'Resultado de la Inspección',
	flowMode: 'Modo de Flujo',
	sendNotification: 'Enviar Notificación',
	executed: 'Ejecutado',
	preview: 'Avance',
	manufacturingYear: 'Año de Manufactura',
	installationDate: 'Fecha de Instalación',
	level: 'Nivel',
	itemsOf: '{n} de {total} elementos',
	pagesOf: '{n} de {total} páginas',
	alarmConfiguration: 'Configuración de Alarma',
	configuration: 'Ajustes',
	inProgress: 'En Curso',
	noAction: 'Ninguna Acción',
	filters: 'Filtros',
	customized: 'Personalizado',
	activities: 'Actividades',
	copy: 'Copiar',
	clone: 'Clon',
	deepClone: 'Clon Completo',
	cloneSubForms: 'Clonar Subformularios',
	cloneSuccessfully: 'Clonado con éxito',
	spacing: 'Espaciado',
	chemical: 'Químico',
	audit: 'Auditoría',
	stopMaintenance: 'Parada de Mantenimiento',
	actionType: 'Tipo de Acción',
	actionSubtype: 'Subtipo de acción',
	duration: 'Duración',
	alarmType: 'Tipo de Alarma',
	alarmOffset: 'Notificación Previa',
	fileName: 'Nombre del Archivo',
	upload: 'Cargar',
	uploadXlsx: 'Cargar XLSX',
	row: 'Línea',
	accept: 'Aceptar',
	deleteNotApplicable: 'Eliminar no Aplicables',
	deleteNonExistent: 'Eliminar Inexistente',
	deleteEvent: 'Eliminar Evento',
	nonExistentField: 'Campo Inexistente',
	notApplicableField: 'Campo no Aplicable',
	additionalInformations: 'Información adicional',
	user: 'Usuario',
	formats: 'Formatos',
	signature: 'Firma',
	composedField: 'Campo Compuesto',
	multipleOptions: 'Varias Opciones',
	multipleOptionsLegacy: 'Varias Opciones Legacy',
	requireAsset: 'Requerir Activo',
	subForm: 'Subformulario',
	multipleForm: 'Múltiples Formas',
	required: 'Requerido',
	sampleFile: 'Archivo de Muestra',
	anomalies: 'Anomalías',
	forecast: 'Pronóstico',
	fillGaps: 'Llenar los vacíos',
	basic: 'Básico',
	confidenceInterval: 'Intervalo de Confianza',
	average: 'Promedio',
	derivative: 'Derivado',
	derivativeAbsolute: 'Derivado (absoluto)',
	timeseries: 'Series de Tiempo',
	last: 'Último',
	first: 'Primero',
	areaZoom: 'Seleccione el área para hacer zoom',
	restoreZoom: 'Restaurar el nivel de zoom.',
	saveAsImage: 'Guardar como imagen',
	forAll: 'Para Todos',
	onlyForNew: 'Solo para nuevos',
	format: 'Formato',
	separator: 'Separador',
	showInMenu: 'Mostrar en el menú',
	reports: 'Informes',
	manual: 'Manual',
	template: 'Plantilla',
	templates: 'Plantillas',
	reportTemplate: 'Reportar Plantilla',
	proposalTemplate: 'Plantilla de Propuesta',
	origin: 'Origen',
	destination: 'Destino',
	label: 'Etiqueta',
	forms: 'Formularios',
	steps: 'Pasos',
	step: 'Paso',
	defaultStep: 'Paso Predeterminado',
	adjacency: 'Proximidad',
	adjacencies: 'Adyacencias',
	workflow: 'Flujo de Trabajo',
	workflows: 'Flujos de Trabajo',
	projects: 'Proyectos',
	open: 'Abierto',
	view: 'Ver',
	nextMonth: 'Próximo mes',
	previousMonth: 'Mes Anterior',
	startDate: 'Fecha de Inicio',
	endDate: 'Fecha Final',
	repetitions: 'Repeticiones',
	mode: 'Modo',
	effectiveDate: 'Fecha Efectiva',
	change: 'Cambio',
	register: 'Registrarse',
	clear: 'Claro',
	allowClear: 'Permitir Borrar',
	number: 'Número',
	silent: 'Silencio',
	expression: 'Expresión',
	color: 'Color',
	virtual: 'Virtual',
	condition: 'Condición',
	inside: 'Adentro',
	outside: 'Fuera De',
	greater: 'Mayor Que',
	less: 'Menos',
	inBetween: 'Entre',
	response: 'Respuesta',
	noDocuments: 'Sin Documentos',
	overrideResult: 'Resultado de anulación',
	overrideFinalResult: 'Anular el resultado final',
	includeArchived: 'Incluir Archivado',
	includeBackoffice: 'Incluir campos de backoffice',
	includeInspector: 'Incluir campos de inspector',
	includeCompleted: 'Incluir Completado',
	includeWithActionPlan: 'Incluir con plan de acción',
	includeWithRecommendations: 'Incluir con recomendaciones',
	skipHasRecommendations: 'No actualice FFP si tiene recomendaciones',
	dontChange: 'No Cambies',
	equipmentProtection: 'Protección de equipos',
	recenter: 'Volver a Centrar',
	permissions: 'Permisos',
	copyDocuments: 'Copiar Documentos',
	copiedSuccessfully: 'Copiado con éxito',
	archived: 'Archivado',
	archive: 'Archivo',
	unarchived: 'No Archivado',
	unarchive: 'Desarchivar',
	waiting: 'En Espera',
	resolved: 'Resuelto',
	direction: 'Dirección',
	sortField: 'Ordenar Campos',
	sortBy: 'Ordenar Por',
	date: 'Fecha',
	time: 'Hora',
	asset: 'Activo',
	noAsset: 'Sin Activo',
	graph: 'Grafico',
	events: 'Eventos',
	event: 'Evento',
	alarms: 'Alarmas',
	alarm: 'Alarma',
	sensor: 'Sensor',
	sensors: 'Sensores',
	showAll: 'Mostrar Todo',
	treeView: 'Vista de árbol',
	tableFields: 'Campos de Tabla',
	searchFields: 'Campos de búsqueda',
	noRecommendation: 'Sin Recomendación',
	hasRecommendation: 'Tiene Recomendaciones',
	withActionPlan: 'Con plan de acción',
	withoutActionPlan: 'Sin plan de acción',
	list: 'Lista',
	listMode: 'Modo de listado',
	calendar: 'Calendario',
	file: 'Archivo',
	download: 'Descargar',
	documents: 'Documentos',
	images: 'Imagenes',
	minute: 'Minuto',
	hour: 'Hora',
	day: 'Día',
	week: 'Semana',
	month: 'Mes',
	year: 'Año',
	minutes: 'Minutos',
	hours: 'Horas',
	days: 'Dias',
	weeks: 'Semanas',
	months: 'Meses',
	years: 'Años',
	hourly: 'Cada Hora',
	daily: 'Diario',
	weekly: 'Semanalmente',
	monthly: 'Mensual',
	yearly: 'Anual',
	selectionMode: 'Modo de selección',
	accepted: 'Aceptado',
	run: 'Correr',
	theme: 'Tema',
	development: 'Desarrollo',
	related: 'Relacionados',
	latitude: 'Latitud',
	longitude: 'Longitud',
	altitude: 'Elevación',
	asc: 'Ascendente',
	desc: 'Descendiendo',
	updatedAt: 'Fecha de actualización',
	update: 'Actualizar',
	updateAll: 'Actualizar Todo',
	createdAt: 'Fecha de creación',
	created: 'Creado',
	updated: 'Actualizado',
	yes: 'sí',
	no: 'No',
	edited: 'Editado',
	nd: 'N/a',
	compliant: 'Obediente',
	nonCompliant: 'No Cumple',
	nonConformities: 'No Conformidades',
	done: 'Hecho',
	message: 'Mensaje',
	unknown: 'Desconocido',
	error: 'Error',
	errors: 'Errors',
	errorLogs: 'Registros de Errores',
	errorLogsTasks: 'Registros de errores de tareas',
	errorLogsClient: 'Registros de errores del cliente',
	success: 'Éxito',
	ok: 'OK',
	cancel: 'Cancelar',
	confirm: 'Confirmar',
	all: 'Todas',
	create: 'Crear',
	delete: 'Borrar',
	remove: 'Eliminar',
	edit: 'Editar',
	add: 'Anãdir',
	back: 'Atrás',
	dismiss: 'Despedir',
	close: 'Cerca',
	options: 'Opciones',
	optionsLegacy: 'Opciones Legacy',
	form: 'Formulario',
	select: 'Seleccione',
	map: 'Mapa',
	demo: 'Demostración',
	next: 'Próximo',
	previous: 'Anterior',
	none: 'Ninguno',
	save: 'Ahorrar',
	field: 'Campo',
	fields: 'Campos',
	question: 'Pregunta',
	questions: 'Preguntas',
	requiredField: 'Campo requerido.',
	notApplicable: 'No Aplica',
	submit: 'Enviar',
	warning: 'Advertencia',
	selectFile: 'Seleccione Archivo',
	preserveUuids: 'Conservar UUIDs',
	notFinished: 'Sin Terminar',
	finished: 'Terminado',
	finish: 'Terminar',
	selectTime: 'Seleccionar Hora',
	selectDate: 'Seleccione Fecha',
	version: 'Versión',
	timestamp: 'Marca de Tiempo',
	commit: 'Commit',
	branch: 'Branch',
	production: 'Producción',
	dateFormat: 'Formato de Fecha',
	timeFormat: 'Formato de Hora',
	history: 'Historia',
	actions: 'Comportamiento',
	action: 'Acción',
	unlock: 'desbloquear',
	triggerEvent: 'Desencadenado por otro evento',
	triggerEventOffset: 'Compensación entre eventos',
	triggerEventUuid: 'UUID del Evento desencadenado',
	triggerEventDirection: 'Dirección de desencadenamento',
	offsetDirection: 'Dirección de Desplazamiento',
	offset: 'Desplazamiento',
	angularOffset: 'Desplazamiento Angular',
	numberTimesRepeat: 'Número de veces a repetir',
	simultaneous: 'Simultáneo',
	before: 'Antes',
	after: 'Después',
	accessLogs: 'Registros de Acceso',
	createNewAsset: 'Creación de nuevos activos',
	today: 'Hoy',
	percentage: 'Porcentaje',
	tab: 'Pestaña',
	addTab: 'Agregar pestaña',
	card: 'Tarjeta',
	addCard: 'Agregar Tarjeta',
	modal: 'Modale',
	popover: 'Popover',
	search: 'Buscar',
	data: 'Datos',
	index: 'Índice',
	indexes: 'Indexación',
	help: 'Ayuda',
	empty: 'Vacio',
	default: 'Predeterminada',
	defaultOption: 'Opción Predeterminada',
	createEvent: 'Crear Evento',
	eventCreated: 'Evento creado con éxito.',
	eventEdited: 'Evento editado con éxito.',
	eventError: 'Error al crear/editar el evento.',
	planning: 'Planificación',
	scheduledEvents: 'Eventos Programados',
	assetEvents: 'Eventos de Activos',
	repairEvents: 'Eventos de Reparación',
	confirmEditSelection: '¿Quieres editar este evento o todos los eventos?',
	confirmDeleteSelection: '¿Quieres eliminar este evento o todos los eventos?',
	allEvents: 'Todos los eventos',
	thisEvent: 'Este Evento',
	myEvents: 'Mis Eventos',
	sorting: 'Ordenación',
	wizard: 'Asistente',
	singleStep: 'Solo Paso',
	replace: 'Reemplazar',
	read: 'Leer',
	chooseFile: 'Elija el archivo',
	toUpload: 'para subir',
	files: '(DOCX, PDF, XLSX y otros)',
	imageFiles: '(PNG, JPG)',
	videoFiles: '(MP4, MKV, MPEG, AVI y otros)',
	audioFiles: '(MP3, WAV, OGG y otros)',
	dragAndDrop: 'Arrastrar y Soltar',
	or: 'o',
	openCamera: 'abrir camara',
	requiredAssetFields: 'Campos activos obligatorios',
	newAsset: 'Nuevo Activo',
	assignments: 'Asignaciones',
	dropFile: 'Suelte su archivo para cargarlo',
	compressPictures: 'Comprimir Imágenes',
	
	// Components
	repetitiveForm: 'Forma Repetitiva',
	assetSelector: 'Selector de Activos',
	companySelector: 'Selector de Empresa',
	teamSelector: 'Selector de Equipo',
	userSelector: 'Selector de Usuario',
	textMultiline: 'Texto de varias líneas',
	toggle: 'Toggle',
	image: 'Imagen',
	radio: 'Radio',
	numberSlider: 'Números (control deslizante)',
	numberRange: 'Números (rango)',
	atexField: 'Campo ATEX',
	stringArray: 'Matriz de Valores',
	dateFrequency: 'Fecha de Frecuencia',
	checkboxGroup: 'Grupo de casillas de verificación',
	noActionAvailable: 'No hay acción disponible',

	// Roles
	userRoles: 'Roles',
	userRole: 'Rol',
	userRoleCreated: 'Se creó correctamente un nuevo rol de usuario.',
	userRoleDeleted: 'La función de usuario se eliminó correctamente.',
	userRoleDeleteError: 'Se produjo un error al eliminar una función de usuario.',
	administrator: 'Administrador',
	inspector: 'Inspector',
	backoffice: 'Back Office',
	supervisor: 'Supervisor',
	client: 'Cliente',

	// Attributes
	onlyApplicable: 'Solo Aplicable',
	activationTime: 'Tiempo de activación',
	min: 'Mínimo',
	max: 'Máximo',
	kpi: 'KPI',
	units: 'Unidades',
	ffpCount: 'Recuento de FFP',
	projectUuid: 'UUID del Proyecto',
	ffpUuid: 'UUID de FFP',
	inspectionUuid: 'UUID de inspección',
	assetUuid: 'UUID de Activo',
	parentUuid: 'UUID del activo superior',
	parentName: 'Nombre del activo superior',
	parentTag: 'Tag del activo superior',
	operationalUnitUuid: 'UUID de la unidad operativa',
	actionPlanUuid: 'UUID del plan de acción',
	actionPlanDescription: 'Descripción del plan de acción',
	teamUuid: 'UUID del Equipo',
	teamUuids: 'UUID del Equipos',
	companyUuid: 'UUID de la empresa',
	userUuid: 'UUID de Usuario',
	userUuids: 'UUIDs de Usuario',
	fieldUuid: 'UUID del Campo',
	fieldText: 'Texto del Campo',
	fieldLabel: 'Etiqueta del Campo',
	recommendation: 'Recomendacion',
	recommendations: 'Recomendaciones',
	hasRecommendations: 'Tiene Recomendaciones',
	alertEmail: 'Alerta de Email',
	actionPlans: 'Plan de acción',
	actionPlansProgress: 'Progreso de los planes de acción',
	resolution: 'Resolución',
	workOrder: 'Orden de Trabajo',
	inspectionFrequency: 'Frecuencia de inspección',
	company: 'Empresa',
	state: 'Expresar',
	limitDate: 'Fecha Límite',
	address: 'Habla a',
	vat: 'Número de valor agregado',
	observations: 'Observaciones',
	observation: 'Observación',
	uuid: 'Identificador único',
	id: 'Identificador',
	attribute: 'Atributo',
	value: 'Valor',
	name: 'Nombre',
	phoneNumber: 'Número de teléfono',
	notes: 'Notas',
	active: 'Activo',
	inactive: 'Inactivo',
	role: 'Papel',
	picture: 'Imagen',
	pictures: 'Fotos',
	description: 'Descripción',
	quantity: 'Cantidad',
	tag: 'Etiqueta',
	position: 'Posición',
	manufacturer: 'Fabricante',
	model: 'Modelo',
	modelIdentification: 'Identificación del modelo',
	serialNumber: 'Número de serie',
	atex: 'ATEX',
	atexTags: 'ATEX Tags',
	atexInfo: 'ATEX Información',
	atexCompliant: 'Cumple con ATEX',
	zoneClassification: 'Clasificación de Zona',
	temperatureClass: 'Clase de Temperatura',
	explosionGroup: 'Grupo de Explosión',
	category: 'Categoría',
	type: 'Tipo',
	typeUuid: 'Tipo UUID',
	subtype: 'Subtipo',
	subtypeUuid: 'Subtipo UUID',
	current: 'Corriente',
	voltage: 'Voltaje',
	nominalVoltage: 'Tensión Nominal',
	power: 'Energía',
	rpm: 'RPM',
	weight: 'Peso',
	insulationClass: 'Clase de Aislamiento',
	energeticClass: 'Clase energética',
	output: 'Producción',
	output1: 'Salida 1',
	output2: 'Salida 2',
	output3: 'Salida 3',
	signalRange: 'Rango de señal',
	workRange: 'Rango de operación',
	flangeSize: 'Dimensión de la brida',
	photos: 'Fotos',
	photo: 'Foto',
	video: 'Video',
	audio: 'Audio',
	result: 'Resultado',
	status: 'Estado',
	overall: 'General',
	tagPicture: 'Etiqueta de Imagen',
	nameplate: 'Placa de características',
	justifications: 'Justificaciones',
	loopDiagram: 'Diagrama de Bucle',
	diagram: 'Diagrama',
	bearingShaft: 'Cojinete (lado del eje)',
	bearingOppositeShaft: 'Cojinete (lado opuesto del eje)',
	supplyVoltage: 'Tensión de alimentación',
	designTemperature: 'Temperatura de diseño',
	workTemperature: 'Temperatura de Trabajo',
	designPressure: 'Presión de diseño',
	workPressure: 'Presión de trabajo',
	corrosionThickness: 'Espesor de corrosión',
	thickness: 'Grosor',
	diameter: 'Diámetro',
	material: 'Material',
	summary: 'Resumen',
	scheduled: 'Programados',
	selectValidAsset: 'Seleccione un activo válido',
	selectValidRepair: 'Seleccione una reparación válida',
	inspectionQrCode: 'Código QR de la Inspección',

	// Equipment subtypes
	base: 'Base',
	motor: 'Motores',
	motorHandSwitch: 'Interruptor de mano de motores',
	valve: 'Actuadores de válvulas',
	jb: 'JB (cajas de conexiones)',
	transmittersPressure: 'Transmisores de presión',
	transmittersLevel: 'Transmisor de Nivel',
	transmittersFlow: 'Transmisor de Flujo',
	transmittersTemperature: 'Transmisor de Temperatura',
	illumination: 'Cajas de iluminación y derivación',
	emergency: 'Emergencia',
	socketsDerivationBoxes: 'Cajas de enchufes y derivaciones',
	security: 'Detector, pulsadores, botón de parada de emergencia.',
	analysers: 'Analizadores',
	limitSwitch: 'Posicionadores y final de carrera',
	transducer: 'Transductor (I / P)',
	pipeline: 'Tubería',
	safetyEquipment: 'Equipo de Seguridad',

	// Dashboard
	fromDate: 'De',
	toDate: 'Hasta',
	dailyHours: 'Horas Diarias',
	dashActionPlanRejected: 'Planes de acción rechazados',
	dashActionPlanRejectedSub: 'Indica la cantidad de entradas de planes de acción que fueron rechazadas por el cliente.',
	dashActionPlanBlocked: 'Planes de acción bloqueados',
	dashActionPlanBlockedSub: 'Indica la cantidad de entradas del plan de acción que están bloqueadas.',
	dashActionExecuted: 'Planes de acción ejecutados',
	dashActionExecutedSub: 'Indica la cantidad de entradas del plan de acción que se ejecutaron.',
	dashActionPlanSub: 'Indica la cantidad de entradas del plan de acción en cada estado de ejecución.',
	dashProgress: 'Progreso de las inspecciones',
	dashProgressSub: 'Muestra cuántas inspecciones se completaron y cuántas están en progreso en cada estado.',
	dashResult: 'Resultado de las inspecciones',
	dashResultSub: 'Inspecciones por su resultado, indica la cantidad de equipos que necesitan reparación.',
	dashInspectionUpdates: 'Inspecciones Actualizadas',
	dashInspectionUpdatesSub: 'Indica cuántas inspecciones han estado actualizando los equipos a diario, incluidas las inspecciones realizadas, aprobadas y correcciones.',
	dashInspectionPerformed: 'Inspecciones Realizadas',
	dashInspectionPerformedSub: 'Indica cuántas inspecciones fueron realizadas por los inspectores de campo a diario.',
	dashInspectionReviewed: 'Inspecciones Revisadas',
	dashInspectionReviewedSub: 'Indica cuántas inspecciones revisó el equipo de backoffice diariamente.',
	dashTimeInspection: 'Tiempo por inspección',
	dashTimeInspectionSub: 'Indica el tiempo medio que tarda cada inspección.',

	// Action plan
	createActionPlan: 'Crear plan de acción',
	underWork: 'Trabajando',
	waitingClient: 'Esperando Cliente',
	rejected: 'Rechazado',
	notRejected: 'No Rechazado',
	blocked: 'Obstruido',
	waitingReinspection: 'Esperando Reinspección',
	actionPlanActions: 'Acciones del plan de acción',
	reinspection: 'Reinspección',
	reinspections: 'Reinspecciones',
	viewAsset: 'Ver Activo',
	viewInspection: 'Ver Inspección',
	implementation: 'Implementación',

	// Asset Portfolio
	structure: 'Estructura',
	editable: 'Editable',
	formTab: 'Pestaña de Formulario',
	formTabUuid: 'UUID de Pestaña de Formulario',
	formTabs: 'Pestañas de Formulario',
	formTabCards: 'Cartas de Formulario',
	formBlock: 'Bloque de Formulario',
	formBlockUuid: 'UUID de Bloque de Formulario',
	formBlocks: 'Bloques de Formulario',
	formBlockFields: 'Campos de bloque de formulario',
	noFieldsSet: 'Sin Campos',
	addField: 'Agregue Campo',
	types: 'Tipos',
	subTypes: 'Subtipos',
	modelsList: 'Lista de Modelos',
	noTabsSet: 'Sin Pestañas',
	errorCreatingFormBlock: 'Error en la creación del bloque de formulario.',
	fieldType: 'Tipo de Campo',
	assetType: 'Tipo de Activo',
	assetTypeFilter: 'Filtro de Tipo de Activo',
	assetSubTypeFilter: 'Filtro de Sub-tipo de Activo',
	assetTypeUuid: 'UUID del tipo de activo',
	assetTypes: 'Tipos de Activos',
	assetSubType: 'Sub-tipo de activo',
	assetSubTypeUuid: 'UUID del sub-tipo de activo',
	assetSubTypes: 'Sub-tipos de activo',
	allowedOnRoot: 'Permitido en la raíz',
	allowedParentTypes: 'Tipos de padres de activos permitidos',
	allowTagRepeatedOnSameType: 'Permitir la repetición de etiquetas en el mismo tipo',
	allowTagRepeatedOnChild: 'Permitir la repetición de etiquetas en tipos niños',
	parentSubType: 'Sub-tipo padre',
	parentSubTypeUuid: 'UUID sub-tipo padre',
	itself: 'Él mismo',
	errorSelectAssetTypeFirst: 'Primero seleccione un tipo de activo.',
	errorCreatingAssetType: 'Error al crear el tipo de activo.',
	errorUpdatingAssetType: 'Error al actualizar el tipo de activo.',
	errorCreatingAsset: 'Error al crear activo.',
	errorUpdatingAsset: 'Error al actualizar activo.',
	errorCreatingSubAssetType: 'Error al crear el sub-tipo de activo.',
	errorUpdatingSubAssetType: 'Error al actualizar el sub-tipo de activo.',
	updateWillCauseDataLoss: 'Los cambios realizados en este atributo provocarán la pérdida de los cambios no guardados. Para evitar su pérdida, cierre el selector y guarde los cambios realizados antes de continuar.',
	useFormBlock: 'Usar Form Bloque',
	privateBlock: 'Bloque Privado',
	parentAsset: 'Activo Superior',
	assetInfo: 'Información del Activo',
	tagRules: 'Reglas de Etiqueta',
	defaultAssetType: 'Tipo de activo predeterminado',
	componentCode: 'Código do Componente',
	componentName: 'Nombre del Componente',
	deleteMissingFormBlocks: 'Eliminar bloques de formulario que no están en el archivo.',
	deleteMissingFormFields: 'Eliminar campos de bloque de formulario que no están en el archivo.',
	errorMissingDocumentSheets: 'Faltan hojas de documentos. Revisa la plantilla del documento.',
	errorUpdatingFormBlockFieldsIndexesMixed: 'Error al actualizar los índices de los campos de formulario. Verifique la indexación de campos de formulario vacíos o duplicados.',
	errorNameRequired: 'El nombre es obligatorio.',
	errorFormBlockNotPresentOnDocument: 'Bloque de formulario no presente en el documento.',
	errorCannotUpdateFieldOfNewFormBlock: 'No se pueden importar campos de bloque de formulario con UUID para un bloque de formulario nuevo.',
	errorInvalidFieldComponent: 'Componente del campo de bloque de formulario no válido.',

	// Repairs
	repairReport: 'Informe de reparación',
	management: 'Gestión',
	exportProposal: 'Exportar propuesta (Word)',
	exportProposalPDF: 'Propuesta de exportación (PDF)',
	createRepair: 'Crear Reparación',
	newRepair: 'Nueva Reparación',
	repair: 'Reparar',
	author: 'Autor',
	assetList: 'Lista de Activos',
	criticality: 'Criticidad',
	temporaryRepair: 'Reparación temporal',
	definitiveRepair: 'Reparación definitiva',
	repairCreated: 'Se ha creado la reparación.',
	repairSubmitted: 'La reparación se ha enviado para su aprobación.',
	repairUpdated: 'Se actualizaron los datos de reparación.',
	technicalDocuments: 'Documentos técnicos',
	waitingTechnicalInfo: 'Esperando información técnica',
	proposal: 'Propuesta',
	proposals: 'Propuestas',
	waitingValidation: 'Esperando Validación',
	qualityAssurance: 'Seguro de Calidad',
	validated: 'Validado',
	validation: 'Validación',
	finishedRepairs: 'Reparaciones Terminadas',
	repairProposal: 'Propuesta de reparación',
	proposalDocuments: 'Documentos de Propuesta',
	longevity: 'Longevidad',
	onGoing: 'En Marcha',
	repairJob: 'Trabajo de reparación',
	repairOrder: 'Orden de reparación',
	proposalApproval: 'Aprobación de propuesta',
	waitingProposal: 'Esperando Propuesta',
	waitingProposalApproval: 'Esperando la aprobación de la propuesta',
	waitingRiskAssessment: 'Esperando la evaluación de riesgos',
	veryLow: 'Muy Bajo',
	normal: 'Normal',
	veryHigh: 'Muy Alto',
	documentCode: 'Código de documento',
	welder: 'Soldador',
	welding: 'Soldadura',
	weldingJob: 'Trabajo de Soldadura',
	weldings: 'Soldaduras',
	needsWelding: 'Necesita Soldadura',
	weldingCount: 'Recuento de Soldadura',
	weldingDiameter: 'Diámetro de soldadura',
	weldingSchedule: 'Programa de Soldadura',
	weldingDocuments: 'Documentos de Soldadura',
	weldingNotes: 'Notas de Soldadura',
	procedure: 'Procedimiento',
	weldingProcedures: 'Procedimientos de Soldadura',
	weldingTypes: 'Tipos de Soldadura',
	weldingStandards: 'Estándares de soldadura',
	standards: 'Estándares',
	procedures: 'Procedimientos',
	licensed: 'Con Licencia',
	consumables: 'Consumibles',
	weldNumber: 'Número de soldadura',
	handleFluids: 'Manejar fluidos (clase 1, 2 o 3)',
	riskAssessmentDocuments: 'Documentos de evaluación de riesgos',
	riskAssessmentNotes: 'Notas de evaluación de riesgos',
	billOfMaterials: 'Lista de Materiales',
	repairDeleted: 'La reparación se ha eliminado correctamente.',
	repairDeleteError: 'Se produjo un error al intentar eliminar la reparación de la base de datos. ({error})',
	repairsProgressDashTitle: 'Progreso de las reparaciones',
	repairsProgressDashSubTitle: 'Presenta cuántas reparaciones existen para cada estado de ejecución.',
	criticalityGraphTitle: 'Repara la criticidad',
	criticalityGraphSubTitle: 'Presenta cuántas reparaciones existen para cada nivel de criticidad.',
	waitingApproval: 'esperando aprovación',
	repairExecution: 'Ejecución',
	block: 'Cuadra',
	blockedJustification: 'Justificación de bloque',
	periodicInspections: 'Inspecciones Periódicas',
	recurrentInspections: 'Inspecciones Periódicas',
	periodic: 'Periódico',
	periodicity: 'Periodicidad',
	periodicityRepetition: 'Repetición de Periodicidad',
	alertTime: 'Tiempo de Alerta',
	requiresRecurrentInspections: 'Requiere inspecciones periódicas',
	temporary: 'Temporal',
	definitive: 'Definitivo',
	nextInspection: 'Próxima inspección',
	assetName: 'Nombre del Activo',
	assetTag: 'Etiqueta de Propiedad',
	assetQR: 'QR del Activo',
	assetNFC: 'NFC del Activo',
	assetDescription: 'Descripción del activo',
	repairRejected: 'Reparación rechazada',
	jobPhotos: 'Fotografía - Imágenes Fotos',
	proposalCompany: 'Empresa Propuesta',
	repairUuid: 'Reparar UUID',
	proposalNotes: 'Notas de Propuesta',
	proposalApprovalNotes: 'Notas de aprobación de propuestas',
	jobPictures: 'Fotos de Trabajo',
	jobDocuments: 'Documentos de Trabajo',
	jobNotes: 'Notas de Trabajo',
	blockedDocuments: 'Documentos Bloqueados',
	blockedNotes: 'Notas Bloqueadas',
	rejectionMessage: 'Mensaje de Rechazo',
	errorImportRepairs: 'Se produjo un error al importar los datos de las reparaciones.',
	importedRepairsXlsxSuccessfully: 'El archivo se importó correctamente a la aplicación. Se crearon {imported} nuevas reparaciones, se actualizaron los datos de {updated} reparaciones y {failed} reparaciones fallaron.',
	exportedReportsBulk: 'El proceso de exportación finalizó, los informes de {success} se exportaron correctamente. No fue posible exportar {failed} informes.',
	removed: 'Remoto',
	firstPeriodicInspectionError: 'La primera inspección periódica se realizará después de la fecha de vencimiento de la reparación.',
	nonDestructiveTest: 'Prueba no destructiva',
	nonDestructiveTests: 'Ensayos no destructivos',
	repairType: 'Tipo de reparación',
	fluids: 'Fluidos',
	fluidFamily: 'Familia de Fluidos',
	fluidType: 'Tipo de Fluido',
	fluidTypes: 'Tipos de Fluidos',

	// Repair Inspections
	nonCompliantRepair: 'Reparación no conforme',
	repairInspectorAppreciation: 'Apreciación del inspector',
	finalAppreciation: 'Apreciación final',
	repairInspection: 'Inspección de reparación',
	repairInspectionPhotos: 'Fotos de inspección de reparación',
	repairInspectionReport: 'Informe de inspección de reparación',
	repairInspectionCreated: 'Inspección de reparación creada',
	repairInspectionSubmitted: 'La inspección de reparación se ha enviado para su aprobación.',
	repairInspectionUpdated: 'Se actualizaron los datos de inspección de reparación.',
	repairInspectionDeleted: 'La inspección de reparación se ha eliminado correctamente.',
	repairInspectionDeleteError: 'Se produjo un error al intentar eliminar la inspección de reparación de la base de datos. ({error})',
	noInspectionsToShow: 'No hay inspecciones disponibles para mostrar.',
	createNewInspection: 'Crear Inspección',
	dueDate: 'Fecha de Vencimiento',

	// User
	editUser: 'Editar Usuario',
	userName: 'Nombre de Usuario',
	changePassword: 'Cambiar la contraseña',
	createUser: 'Crear Usuario',
	userCreated: 'Se creó una nueva cuenta de usuario con éxito.',
	profile: 'Perfil',

	// Teams
	team: 'Equipo',
	teamName: 'Nombre del Equipo',
	teamMembers: 'Miembros del Equipo',
	teamCreated: 'Equipo creado con éxito',
	teamUpdate: 'Los datos del equipo se actualizaron correctamente',
	parentTeam: 'Equipo de Padres',

	// Companies
	parentCompany: 'Compañía matriz',
	companyCreated: 'Empresa creada con éxito',
	companyUpdate: 'Empresa actualizada correctamente',
	defaultCompany: 'Empresa Predeterminada',

	// Sensors
	undefined: 'Indefinido',
	flow: 'Flujo',
	velocity: 'Velocidad',
	distance: 'Distancia',
	impedance: 'Impedancia',
	force: 'Fuerza',
	mass: 'Masa',
	acceleration: 'Aceleración',
	energy: 'Energía',
	capacity: 'Capacidad',
	efficiency: 'Eficiencia',
	temperature: 'Temperatura',
	pressure: 'Presión',

	// Settings
	locale: 'Idioma',
	language: 'Idioma',
	showListPictures: 'Mostrar listas de imágenes',
	lazyLoading: 'Lista de carga diferida',
	pictureMaxSize: 'Tamaño de la foto (px)',
	pictureFormat: 'Formato de Foto',
	mapStyle: 'Estilo de Mapa',
	vector: 'Vector',
	satellite: 'Satélite',
	mixed: 'Mezclado',
	dark: 'Oscuro',
	light: 'Luz',
	keepExternalWindow: 'Reutilizar pestañas',
	keepFilters: 'Mantener filtros de búsqueda',
	appearance: 'Apariencia',
	preferences: 'Preferencias',
	title: 'Título',
	icon: 'Icono',
	loginBackgroundImage: 'Imagen de fondo de inicio de sesión',
	logo: 'Logo',
	smallLogo: 'Logotipo pequeño',
	topLogo: 'Logotipo Superior',
	bottomLogo: 'Logotipo Bajo',
	accentColor: 'Acentuar el color',
	settingsUpdated: 'Ajustes Actualizan',

	// Menu
	assetPlanning: 'Planificación de Activos',
	privacyPolicy: 'Política de Privacidad',
	dashboard: 'Dashboard',
	functionalAnalysis: 'Análisis Funcional',
	project: 'Proyecto',
	companies: 'Compañías',
	users: 'Usuarios',
	usersEmail: 'Usuarios (Email)',
	usersSms: 'Usuarios (SMS)',
	tasks: 'Tareas',
	teams: 'Equipos',
	assets: 'Activos',
	assetPortfolio: 'Portfólio de activos',
	actionPlan: 'Plan de Accion',
	atexActionPlan: 'Plan de Accion ATEX',
	repairs: 'Refacción',
	repairInspections: 'Inspecciones de Reparación',
	inspections: 'Inspecciones',
	atexInspections: 'Inspecciones ATEX',
	settings: 'Ajustes',
	masterSettings: 'Configuración Maestra',
	importExport: 'Importación y Exportación',
	logs: 'Registros',
	intelligentDocuments: 'Documentos Inteligentes',
	digitalTwin: 'Digital Twin',

	// Success messages
	createdActionPlanTag: 'Plan de acción creado para la etiqueta {tag} con éxito.',
	addedSuccessfully: 'Datos agregados con éxito.',
	createdSuccessfully: 'La nueva entrada se creó con éxito.',
	updatedSuccessfully: 'Los datos se actualizaron correctamente.',
	updatedSuccessfullyName: 'Los datos de {name} se actualizaron correctamente.',
	importSuccessful: 'La importación se terminó con éxito.',
	importedSuccessfullyCounters: 'El archivo se importó correctamente a la aplicación. Se crearon {imported} nuevos activos, se actualizaron los datos de {updated} activos y {failed} los activos fallaron.',
	deleteSuccessfully: 'Los datos se eliminaron correctamente del servidor.',
	deleteSuccessfullyName: '{name} se eliminó de la base de datos con éxito.',
	deleteSuccessfullyCount: 'Se eliminaron {count} entradas de la base de datos con éxito.',
	reportGeneratedSuccessfully: 'Informe generado con éxito.',
	cameraSelected: 'Se seleccionó la cámara "{name}".',
	restoreSuccessful: 'Los datos se restauraron con éxito.',

	// Confirm Messages
	confirmDelete: '¿Estás seguro de eliminar estos datos?',
	updatedVersionFound: 'Hay disponible una nueva versión de la aplicación.',
	confirmDeletePipeline: 'Si dibuja una tubería nueva, la existente se borrará. ¿Desea continuar?',
	confirmDeleteEvent: '¿Estás seguro de eliminar este evento? Los eventos eliminados no se pueden recuperar.',
	confirmInspectionValidation: '¿Está seguro de que desea validar todas las inspecciones seleccionadas?',

	// Warning messages
	deviceOffline: 'Se perdió la conexión a Internet. El dispositivo está offline.',
	deviceOnline: 'Conexión a Internet restaurada. El dispositivo está en online.',
	loadingAssetsData: 'Cargando datos de activos',
	uploadingData: 'Subiendo Datos',
	processingData: 'Procesando Datos',
	importingData: 'Importado {imported} de {total}. {failed} han fallado.',
	importingDataLines: 'Datos importados de {importedLines} filas, de un total de {totalLines}.',
	deletingData: 'Borrando Datos',
	loadingData: 'Cargando datos.',
	loadingMoreData: 'Cargando más datos ...',
	nothingWasDeleted: 'No se eliminó nada.',

	// Error messages
	nothingToExport: 'Nada que exportar.',
	nothingUpdatedFFPNotFound: 'No se cambió nada, no es posible encontrar FFP que coincida con la configuración.',
	requiredFieldsError: 'Deben completarse los campos obligatorios.',
	assetRequiredFieldsError: 'Deben completarse los campos de activo obligatorios.',
	warningNoSensorSelected: 'Ningún sensor seleccionado, debe seleccionar al menos un sensor para continuar.',
	errorAssetNotFound: 'El activo no se encontró en la base de datos.',
	errorFileColumn: 'El archivo no contiene ninguna columna con el nombre "{attribute}".',
	errorNoFile: 'No se seleccionó ningún archivo.',
	errorDeletingSomeFailed: 'No fue posible eliminar entradas {failed} de la base de datos.',
	errorProcessingSomeFailed: 'No fue posible procesar todas las entradas. Verifique el archivo exportado en busca de errores.',
	errorExportingQR: 'Error al exportar el código QR al archivo.',
	errorImport: 'Se produjo un error al importar el archivo a la aplicación.',
	errorProcessingData: 'Se produjo un error al procesar los datos.',
	errorImportDetails: 'Se produjo un error al importar el archivo a la aplicación. ({details})',
	errorImportAsset: 'Se produjo un error al importar los datos de los activos.',
	errorExport: 'Error al exportar datos al archivo.',
	errorExportPDFConfirmTryDOCX: 'Error al exportar datos a un archivo PDF. ¿Desea probar en DOCX?',
	errorGeneratingQR: 'Error al intentar generar un código QR.',
	errorGeneratingReportDetails: 'Ha fallado la generación del informe. Se encontró un problema con los datos al generar el informe. ({details})',
	errorConvertingReport: 'Error en la conversión del informe. ({details})',
	errorEncodingData: 'Error al intentar codificar datos.',
	dataMalformed: 'Los datos recibidos del servidor están mal formados.',
	dataUpdateError: 'Los datos no se actualizaron correctamente.',
	noPermission: 'El usuario no tiene permisos para ejecutar esta acción.',
	noPermissionDetails: 'El usuario no tiene permisos para ejecutar esta acción. ({details})',
	internalServerError: 'Error de servidor interno. ({details})',
	badRequest: 'Error en los datos enviados al servidor. ({details})',
	cannotReachServer: 'No se puede acceder al servidor.',
	userSessionExpired: 'La sesión de inicio de sesión del usuario ha expirado.',
	noDataOnServer: '¡No se encontró contenido en el servidor!',
	errorGettingProjectFormHeaders: 'Error al pedir encabezados de formulario for proyecto {projectName} ({projectUuid}) al paso {stepName} ({stepUuid})})',
	errorGettingInspectionsForProject: 'Error al obtener inspecciones para el proyecto {projectName} ({projectUuid})',
	errorExportingInspectionForProject: 'Error al exportar la inspección {inspectionName} ({inspectionUuid}) del proyecto {projectName} ({projectUuid})',
	errorsEncounteredDuringExport: 'Se encontraron los siguientes errores durante la exportación: \n{errors}',
	errorDefaultWorkflowStepMissing: 'Se debe seleccionar un paso inicial para el flujo de trabajo.',
	errorMultipleDefaultWorkflowSteps: 'Múltiples pasos iniciales seleccionados. Seleccione sólo uno.',
	errorMissingPipeline: 'No hay tubería. Se deben agregar CMPS y features a una canalización!',
	errorSnapTolerance: 'El elemento dibujado no está cerca de ninguna tubería!',
	errorImportAssetsInvalidType: 'El tipo de activo proporcionado no es válido.',
	errorImportAssetsMissingDefaultType: 'Se debe proporcionar un tipo de activo predeterminado o un nombre/UUID de tipo de activo válido.',
	errorImportAssetsInvalidSubType: 'El subtipo de activo proporcionado no es válido.',
	errorImportAssetsInvalidSubtypeType: 'El subtipo de activo proporcionado no pertenece al tipo de activo seleccionado.',
	errorImportAssetsCreationUpdateFailed: 'La importación de este activo falló en el servidor.',
	errorImportAssetsAtexCreationFailed: 'Error al crear la inspección Atex.',
	errorComputingInspectionAverageDetails: 'Error al calcular el promedio de inspección. ({details})',
	errorImportAssetCreateFailed: 'No se pudo crear un nuevo activo.',
	errorImportAssetUpdateFailed: 'No se pudieron actualizar los datos del activo.',
	errorMultipleDefaultReports: 'Se seleccionaron varias plantillas de informes predeterminadas. Seleccione solo una.',
	
	// MOT
	mot: 'MOT',
	sensorManagement: 'Gestión de sensores',
	pipePlant: 'Planta de tubería',
	frequency: 'Frecuencia',
	repetition: 'Repetición',
	waveChannels: 'Canales de Onda',
	channels: 'Canales',
	propagationSpeed: 'Velocidad de Propagacion',
	locationPoint: 'Localización',
	segment: 'Segmento',
	cmp: 'CMP',
	lds: 'LDS',
	leakDetection: 'Detección de Fugas',
	pipelines: 'Tubería',
	acquisition: 'Adquisición',
	experiment: 'Experimento',
	experiments: 'Experimentos',
	nanoFileImportedSuccessfully: 'El archivo .nano se importó correctamente.',
	feature: 'Feature',
	chooseElementModal: 'Elija el elemento que desea agregar.',
	weld: 'Solda',
	support: 'Soporte',
	flange: 'Brida',
	teeBranch: 'Bifurcación',
	elbow: 'Codo',
	brace: 'Abrazadera',
	defect: 'Defecto',
	indication: 'Recomendación',
	valves: 'Válvulas',
	featureGeneric: 'Feature genérica',
	compare: 'Comparar',
	acquisitions: 'Adquisicións',
	pickTwoAcquisitions: 'Seleccione dos adquisiciones para comparar',
	pipelineIntegrity: 'Integridad de la Tubería',
	alarmDeleted: 'Esta alarma fue eliminada',

	// DL50
	dl50: 'DL50',
	dl50Inspection: 'Inspección DL50',
	validate: 'Validar',
	report: 'Informe',
	representative: 'Representante',
	operator: 'Operador',
	authorizedOperator: 'Operador Autorizado',
	equipmentOperator: 'Operador de Equipo',
	owner: 'Propietario',
	serviceHours: 'Horas de Servicio',
	equipmentServiceHours: 'Horas de servicio del equipo',
	certification: 'Certificación',
	certificationLocation: 'Ubicación de la certificación',
	tests: 'Pruebas',
	hasElectricalTests: 'Tiene pruebas eléctricas',
	electricalTests: 'Pruebas eléctricas',
	hasLoadTests: 'Tiene pruebas de carga',
	loadTests: 'Cargar Pruebas',
	finalNotes: 'Notas Finales',
	conclusion: 'Conclusión',
	safetyRequirements: 'Requisitos de Seguridad',
	evaluation: 'Evaluación',
	maximumProvisions: 'Provisiones máximas',
	load: 'Carga',
	range: 'Alcance',
	spearLength: 'Longitud de la lanza',
	height: 'Altura',
	testData: 'Datos de Prueba',
	dynamicTest: 'Prueba dinámica',
	loadTestOverload: 'Sobrecarga de:',
	loadTestStaticTest: 'Prueba estática',
	loadTestFunctional: 'Prueba Funcional',
	loadTestNoLoad: '0% de sobrecarga/sin carga',
	loadTestMomentLimiter: 'Prueba del limitador de momento',
	loadTestTenPercentOverloadAssessment: 'Sobrecarga máxima10%',
	loadTestMaxLoadLimiter: 'Prueba del limitador de carga máxima',
	groundCircuit: 'Circuito de Tierra',
	groundCircuitContinuity: 'Continuidad del circuito de tierra de protección',
	electricCurrent: 'Intensidad eléctrica de prueba de corriente',
	differentialSwitches: 'Interruptores Diferenciales',
	differentialSwitchesOpenCircuitTimeHalf: 'Tiempo de activación para ½ x IΔn',
	differentialSwitchesOpenCircuitTimeOne: 'Tiempo de activación para 1 x IΔn',
	differentialSwitchesOpenCircuitTimeTwo: 'Tiempo de activación para 2 x IΔn',
	differentialSwitchesOpenCircuitTimeFive: 'Tiempo de activación para 5 x IΔn',
	insulationResistance: 'Resistencia de Aislamiento',
	insulationResistanceR: 'R - PE',
	insulationResistanceS: 'S - PE',
	insulationResistanceT: 'T - PE',
	insulationResistanceN: 'N - PE',
	emission: 'Emisión',
	revision: 'Revisión',
	reviewer: 'Crítico',
	clientReview: 'Revisión del cliente',
	supervisionRejected: 'Rechazado por supervisión',
	errorCreatingInspection: 'Error al crear la inspección',
	errorUpdatingInspection: 'Error al actualizar la inspección',
	errorUpdatingInspectionPermission: 'El usuario no tiene permisos para crear o editar inspecciones DL50.',
	gapsOk: 'Si no hay no conformidades',
	gapsLight: 'Si hay no conformidades - luz',
	gapsSevere: 'Si hay no conformidades - graves',
	gapsNok: 'No Encaja',
	possibleGaps: 'Posibles Gaps',
	legislationAndStandards: 'Legislación y referencias normativas',
	dl50InspectionXlsx: 'DL50 Inspection XLSX',
	dl50InspectionXlsxSub: 'Exporta archivo XLSX con todas las inspeccións DL50 insertadas en la plataforma.',
	correctionDeadline: 'Fecha límite de corrección',
	reportNumber: 'Número de informes',
	regulatoryStandard: 'Estándar regulatorio',
	emissionDate: 'Fecha de emisión',
	emissionLocation: 'Ubicación de emisión',
	counter: 'Contador',
	dl50InspectionStatusDashTitle: 'Progreso de las inspecciones DL50',
	dl50InspectionStatusDashSubTitle: 'Inspecciones DL50 por estado.',
	dl50InspectionConclusionDashTitle: 'Resultados de las inspecciones DL50',
	dl50InspectionConclusionDashSubTitle: 'Inspecciones DL50 mediante conclusión.',
	overload: 'Sobrecarga',
	confirmInspectionEvaluationInconsistency: 'Las evaluaciones de esta inspección no se ajustan al valor de la conclusión. ¿Desea continuar?',
	confirmManyInspectionsInconsistentData: 'Las evaluaciones de las siguientes UUID de inspección no se ajustan al valor de la conclusión. ¿Desea continuar? {uuids}',
	
	// RBAC
	rbac: 'RBAC',
	allowedRoles: 'Roles con Acceso',
	effectiveRoles: 'Roles Globales'
};
