<div class="uno-asset-selector-tree-navigation-flex-container">
	<div class="uno-h3">
		{{'assetList' | translate }}
	</div>
	<div class="uno-asset-selector-tree-navigation-header-close" (click)="this.dismiss()">
		<uno-icon src="assets/icons/uno/close.svg" color="primary" [width]="32" [height]="32"></uno-icon>
	</div>
</div>

<div style="background-color: var(--gray-11); height: 4px;"></div>
<div class="uno-asset-selector-tree-navigation-flex-container">
	<div style="width: 100%;">
		<uno-searchbar [value]="selfStatic.filters.search" (valueChange)="onSearch($event)"></uno-searchbar>
	</div>
	<div class="uno-asset-selector-tree-navigation-filter-input" style="width: 250px;" [ngClass]="{'uno-asset-selector-tree-navigation-filter-input-selected': isSortOpen}" (click)="isSortOpen = !isSortOpen" cdkOverlayOrigin #triggerSort="cdkOverlayOrigin">
		<uno-icon style="margin-right: 4px;" src="assets/icons/uno/sortby.svg" color="primary" [width]="24" [height]="24"></uno-icon>
		<span class="uno-p2" style="min-width: 47px;">{{"sortBy" | translate}}</span>
		<uno-icon [ngClass]="{'uno-asset-selector-tree-navigation-filter-input-icon-rotate': !isSortOpen, 'disabled': false}" color="{{!isSortOpen ? 'var(--gray-5)' : 'var(--brand-primary)' }}" [width]="32" [height]="32" src="./assets/components/options/up-specialarrow-icon.svg"></uno-icon>
	</div>
	<div class="uno-asset-selector-tree-navigation-filter-input" style="width: 385px;" [ngClass]="{'uno-asset-selector-tree-navigation-filter-input-selected': isFiltersOpen}" (click)="isFiltersOpen = !isFiltersOpen" cdkOverlayOrigin #triggerFilters="cdkOverlayOrigin">
		<div style="display: flex; align-items: center;">
			<uno-icon style="margin-right: 4px;" src="assets/components/global-calendar/filter-icon.svg" color="primary" [width]="24" [height]="24"></uno-icon>
			<span class="uno-p2">{{"filters" | translate}}</span>
		</div>
		<uno-icon [ngClass]="{'uno-asset-selector-tree-navigation-filter-input-icon-rotate': !isFiltersOpen, 'disabled': false}" color="{{!isFiltersOpen ? 'var(--gray-5)' : 'var(--brand-primary)' }}" [width]="32" [height]="32" src="./assets/components/options/up-specialarrow-icon.svg"></uno-icon>
	</div>

	<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="triggerSort" [cdkConnectedOverlayOpen]="isSortOpen">
		<div class="uno-asset-selector-tree-navigation-filter-input-options-list" style="width: 130px;">
			@for (option of this.selfStatic.filterOptions[1].options; track option) {
				<span class="uno-asset-selector-tree-navigation-filter-input-option" (click)="this.changeSort(option.value)">{{option.label | translate}}</span>
			}
		</div>
	</ng-template>
	<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="triggerFilters" [cdkConnectedOverlayOpen]="isFiltersOpen">
		<div class="uno-asset-selector-tree-navigation-filter-input-options-list" style="width: 193px;">
			@for(option of this.selfStatic.filterOptions[2].options; track option) {
				<div style="display: flex; align-items: center; padding: 8px;">
					<ion-checkbox (ionChange)="this.changeFilter(option.value)" [checked]="this.selectedFilters.includes(option.value)" style="margin: 4px; vertical-align: middle;"></ion-checkbox>
					<span>{{option.label | translate}}</span>
				</div>
			}
		</div>
	</ng-template>

</div>
<div style="background-color: var(--gray-11); height: 1px; margin-bottom: 12px;"></div>
<div>
	<cdk-virtual-scroll-viewport style="height: 318px; width: 100%;" itemSize="50">
		<div style="display: flex;" *cdkVirtualFor="let item of this.flatItems; let i = index;">
			<!-- Level padding -->
			@for (item of (item.level | range); track item) {
				<div style="width: 22px; height: 38px; margin-left: 15px; margin-right: -10px;">
					<div class="uno-asset-selector-tree-navigation-guideline"></div>
				</div>
			}
			<div class="uno-asset-selector-tree-navigation-asset" (click)="this.select(item)" [ngClass]="{'uno-asset-selector-tree-navigation-asset-odd': !(i % 2) && !item.level, 'uno-asset-selector-tree-navigation-asset-odd-child': !(i % 2) && item.level}">
				<input type="checkbox" [value]="item" [ariaLabel]="'select' + item.uuid" [checked]="isAssetSelected(item)">
				@if (item.hasChild && hasSelectedChildren(item)) {
					<span class="uno-asset-selector-tree-navigation-partial-checkmark">
						<span class="uno-asset-selector-tree-navigation-partial-checkmark-dash"></span>
					</span>
				} @else {
					<span class="uno-asset-selector-tree-navigation-checkmark"></span>
				}
				<span class="uno-asset-selector-tree-navigation-asset-title uno-p2">{{ item.name }}</span>
				@if (item.hasChild) {
					@if (this.item.expanded) {
						<uno-icon (click)="this.toggleExpansion(item)" src="/assets/icons/uno/down-fullarrow.svg"></uno-icon>
					} @else {
						<uno-icon (click)="this.toggleExpansion(item)" src="/assets/icons/uno/right-fullarrow.svg"></uno-icon>
					}
				}
			</div>
		</div>
	</cdk-virtual-scroll-viewport>
</div>

<div class="uno-asset-selector-tree-navigation-flex-container">
	<uno-button type="border" [fitContent]="false" [expand]="false" width="174px" height="35px" (click)="this.dismiss()"> {{'cancel' | translate }}</uno-button>
	<uno-button color="primary" type="fill" [fitContent]="false" [expand]="false" [disabled]="!canAdd" width="174px" height="35px" (click)="this.add()"> {{'add' | translate }}</uno-button>
</div>
