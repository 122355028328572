import {InspectionFormFieldType} from './inspection-form-field-type';

/**
 * Map of the form field types to a method to get the default value for the field type.
 */
export const InspectionFormFieldTypeDefaultValue = new Map<number, Function>([
	[InspectionFormFieldType.NONE, function() {return null;}],
	[InspectionFormFieldType.CHECKBOX, function() { return false; }],
	[InspectionFormFieldType.TEXT, function() { return ''; }],
	[InspectionFormFieldType.DOCUMENTS, function() { return []; }],
	[InspectionFormFieldType.IMAGES, function() { return []; }],
	[InspectionFormFieldType.GEO_POSITION, function() { return null; }],
	[InspectionFormFieldType.DATE, function() { return null; }],
	[InspectionFormFieldType.TEAM, function() { return null; }],
	[InspectionFormFieldType.COMPANY, function() { return null; }],
	[InspectionFormFieldType.TITLE, function() { return null; }],
	[InspectionFormFieldType.USER, function() { return null; }],
	[InspectionFormFieldType.OPTIONS, function() { return null; }],
	[InspectionFormFieldType.OPTIONS_MULTIPLE, function() { return []; }],
	[InspectionFormFieldType.SUB_FORM, function() { return {}; }],
	[InspectionFormFieldType.COMPOSED_FIELD, function() { return {}; }],
	[InspectionFormFieldType.MULTIPLE_FORMS, function() { return []; }],
	[InspectionFormFieldType.ASSET, function() { return null; }],
	[InspectionFormFieldType.ASSET_MULTIPLE, function() { return []; }]
]);
