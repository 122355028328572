import {DashboardBarChartComponent} from 'src/app/components/dashboard/bar-chart/dashboard-bar-chart';
import {WidgetTypes} from 'src/app/models/dashboards/widget-types';
import {UserPermissions} from 'src/app/models/users/user-permissions';
import {ChartData} from '../../../screens/dashboards/widgets/widget.component';

export class WidgetAssetDistribution {
	public displayType = WidgetTypes.DASHBOARD_WIDGET_TYPE_ASSET_DISTRIBUTION_BY_TYPE_SUBTYPE;

	public visualization = DashboardBarChartComponent;

	public permissions = [UserPermissions.ASSET_PORTFOLIO];

	public static getData(): ChartData {
		return {labels: [], datasets: []};
	};
}
