<uno-content [borders]="true">
	@for (family of this.fluidFamilies; track family) {
		<div>
			<uno-form [layout]="this.layout" [object]="family" #form></uno-form>
			<div class="asset-fluid-family-delete-button-container">
				<uno-button (click)="this.delete(family)" color="error">{{'delete' | translate}}</uno-button>
			</div>
		</div>
	}
	<uno-button (click)="this.add()">{{'add' | translate}}</uno-button>

	<div class="asset-fluid-family-save-buttons-container">
		<uno-button (click)="this.save(true)">{{'update' | translate}}</uno-button>
		<uno-button (click)="this.save()" color="success">{{'save' | translate}}</uno-button>
	</div>
</uno-content>
