import {cloneDeep} from 'lodash-es';
import {ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation, WritableSignal, signal} from '@angular/core';
import {CalendarEvent} from 'angular-calendar';
import {App} from 'src/app/app';
import {Modal} from 'src/app/modal';
import {Locale} from 'src/app/locale/locale';
import {Session} from 'src/app/session';
import {addDays, addMonths, addYears, subDays, subSeconds} from 'date-fns';
import {LocaleDateMonth} from 'src/app/locale/locale-date';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ObjectUtils} from 'src/app/utils/object-utils';
import {SortDirection} from 'src/app/utils/sort-direction';
import {TranslateModule} from '@ngx-translate/core';
import {CdkOverlayOrigin, CdkConnectedOverlay} from '@angular/cdk/overlay';
import {NgClass, NgStyle, KeyValuePipe} from '@angular/common';
import {LocalStorage} from 'src/app/utils/local-storage';
import {EventManager} from 'src/app/utils/event-manager';
import {Environment} from 'src/environments/environment';
import {DL50Inspection} from 'src/app/models/dl50/dl50-inspection';
import {UnoFormUtils} from '../../../../components/uno-forms/uno-form/uno-form-utils';
import {UnoFormField} from '../../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormModalButton} from '../../../../components/uno-forms/uno-form-modal/uno-form-modal.component';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {ServiceResponse} from '../../../../http/service-response';
import {DateRange} from '../../../../models/date-range';
import {UserPermissions} from '../../../../models/users/user-permissions';
import {InspectionWorkflowStep} from '../../../../models/inspections/workflow/inspection-workflow-step';
import {UUID} from '../../../../models/uuid';
import {RepairInspection} from '../../../../models/repairs/inspections/repair-inspection';
import {Repair} from '../../../../models/repairs/repairs/repair';
import {AtexInspection} from '../../../../models/atex-inspections/inspections/atex-inspection';
import {Inspection} from '../../../../models/inspections/inspection/inspection';
import {UnoCalendarDisplayMode, UnoCalendarComponent} from '../../components/uno-calendar/uno-calendar.component';
import {CalendarEvent as PlanningCalendarEvent} from '../../../../models/asset-planning/calendar-event';
import {CalendarEventLayout} from '../calendar-event-layout';
import {
	CalendarEventLabelSubtypes,
	CalendareventSubtypeColors,
	CalendarEventSubtypes,
	CalendarEventSubtypesLabel,
	CalendarEventTypes
} from '../../../../models/asset-planning/calendar-event-actions';
import {CalendarEventExport} from '../../data/calendar-event-export';
import {CalendarEventOccurrence} from '../../../../models/asset-planning/calendar-event-occurrence';
import {CalendarOccurrenceLayout} from '../calendar-event-ocurrence-layout';
import {UnoDateTimeRangeComponent} from '../../../../components/uno-input/uno-date-time-range/uno-date-time-range.component';
import {UnoDateTimeComponent} from '../../../../components/uno-input/uno-date-time/uno-date-time.component';
import {UnoOptionsList} from '../../../../components/uno/uno-options-list/uno-options-list.component';
import {UnoButtonComponent} from '../../../../components/uno/uno-button/uno-button.component';
import {UnoIconComponent} from '../../../../components/uno/uno-icon/uno-icon.component';
import {UnoSearchbarComponent} from '../../../../components/uno/uno-searchbar/uno-searchbar.component';
import {CalendarEventService} from '../../services/calendar-event.service';
import {DateFrequencyUtils} from '../../../../utils/date-frequency-utils';
import {PermissionsPipe} from '../../../../pipes/permissions.pipe';

class CalendarEventOccurrenceWithEvent extends CalendarEventOccurrence {
	/**
	 * The event this occurence refers to.
	 */
	public event: CalendarEvent = null;
}

@Component({
	selector: 'asset-planning-calendar',
	templateUrl: './asset-planning-calendar-screen.component.html',
	styleUrls: ['./asset-planning-calendar-screen.component.css'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [NgClass, NgStyle, UnoSearchbarComponent, UnoIconComponent, CdkOverlayOrigin, CdkConnectedOverlay, FormsModule, ReactiveFormsModule, UnoButtonComponent, UnoOptionsList, UnoDateTimeComponent, UnoDateTimeRangeComponent, UnoCalendarComponent, KeyValuePipe, TranslateModule, PermissionsPipe]
})
export class AssetPlanningCalendarScreen implements OnInit, OnDestroy {

	@ViewChild('unoCalendar', {static: false})
	public unoCalendar: UnoCalendarComponent;

	@ViewChild('container', {static: false})
	public container: ElementRef;

	public get calendarEventSubtypeColors(): Map<number, string> {return CalendareventSubtypeColors;};

	public get calendarEventSubtypesLabel(): Map<number, string> {return CalendarEventSubtypesLabel;};

	public get unoCalendarDisplayMode(): typeof UnoCalendarDisplayMode {return UnoCalendarDisplayMode;};

	public get addDays(): (date: number | Date, amount: number)=> Date {return addDays;};

	public get subDays(): (date: number | Date, amount: number)=> Date {return subDays;};

	public app = App;

	public userPermissions = UserPermissions;

	/**
	 * The current display of the calendar (month, day or list).
	 */
	public display: UnoCalendarDisplayMode = UnoCalendarDisplayMode.MONTH;

	/**
	 * Date currently highlighted in the interface.
	 */
	public viewDate: Date = new Date();

	/**
	 * Whether an event is being edited.
	 */
	public editingEvent: boolean = false;

	/**
	 * The event list containing all the events.
	 */
	public events: CalendarEvent[] = [];

	/**
	 * The planning calendar event occurences
	 */
	public occurrences: CalendarEventOccurrence[] = [];

	/**
	 * Value in the searchbar.
	 */
	public search: string = '';

	/**
	 * The current date.
	 */
	public currentDate: Date = new Date();

	/**
	 * List containing all the months.
	 */
	public monthList: {value: number, label: string}[] = [];

	/**
	 * List containing all the years from a range of [(current date - 100), (current date + 100)].
	 */
	public yearList: {value: number, label: string}[] = [];

	/**
	 * If the filter is expanded.
	 */
	public filterExpanded: boolean = false;

	/**
	 * The form containing the filter's values.
	 */
	public filterForm: FormGroup;

	/**
	 * The list to be used when loading the events containing the subAction values.
	 */
	public filterList: number[] = [];

	/**
	 * The direction in which events are sorted (asc or desc).
	 */
	public sortDirection: string = SortDirection.ASC;

	/**
	 * Date range for data presented.
	 */
	public range: DateRange = new DateRange();

	/*
	 * If there is data being loaded.
	 */
	public loading: boolean = false;

	/**
	 * When to start fetching the events.
	 */
	public from: number = 0;

	/**
	 * How many events to fetch
	 */
	public count: number = 50;

	public eventManager: EventManager;

	/**
	 * If the header is to be shown on 2 lines.
	 */
	public multiLine: WritableSignal<boolean> = signal(false);

	/**
	 * If the multiline variable has been updated.
	 */
	public multiLineSet: WritableSignal<boolean> = signal(false);

	/**
	 * Event manager used to resize the canvas area on window resize.
	 */
	public manager: EventManager = new EventManager();

	/**
	 * If there are more events to load
	 */
	public hasMore: boolean = true;

	public ngOnInit(): void {
		App.navigator.setTitle(Locale.get('assetPlanning'));

		this.createFormGroup();

		this.range.from = new Date();
		this.range.from.setHours(0);
		this.range.from.setMinutes(0);
		this.range.to = addMonths(this.range.from, 2);

		if (LocalStorage.exists('assetPlanningFilters') ) {
			this.loadFilters();
		}

		// Get a list with all the months.
		let count = -1;
		LocaleDateMonth.forEach((month: string) => { count += 1; return this.monthList.push({value: count, label: month}); });

		// Get a list with all the years in the range of 100 years in the past to 100 years into the future.
		for (let i = this.currentDate.getFullYear() - 100; i < this.currentDate.getFullYear() + 100; i++) {
			this.yearList.push({value: i - (this.currentDate.getFullYear() - 100), label: String(i)});
		}

		this.loadEvents();

		this.eventManager = new EventManager();
		this.eventManager.add(window, 'beforeunload', () => {
			this.storeFilters();
		});

		this.eventManager.create();
		if (App.device.isDesktop()) {
			this.manager.add(window, 'resize', () => {
				this.multiLine.set(this.container.nativeElement.offsetWidth < 1100);
			});

			this.manager.create();
		} else if (App.device.isMobile()) {
			this.multiLine.set(true);
		}
	}

	public ngAfterViewChecked(): void {
		if (this.container.nativeElement.offsetWidth !== 0 && !this.multiLineSet()) {
			this.multiLine.set(this.container.nativeElement.offsetWidth < 1100);
			this.multiLineSet.set(true);
		}
	}

	public constructor(private cdref: ChangeDetectorRef) {}

	/**
	 * Modifies the calendar event layout to clean data from object automatically when changes happen on other fields.
	 *
	 * @param layout - The calendar event layout to be edited
	 * @returns The updated layout.
	 */
	public static setCalendarEventLayoutOnChanges(layout: UnoFormField[]): UnoFormField[] {
		// When calendar event type is changed, other properties must be reset/reloaded and some object data cleaned.
		const eventTypeField: UnoFormField = UnoFormUtils.getFormFieldByAttribute(layout, 'actionType');
		eventTypeField.onChange = (object: any, row: UnoFormField, value: any): void => {
			// Clean calendar event subtype
			object.actionSubtype = null;

			// Clear data that depend on calendar sub-type from calendar event
			object.repairUuid = null;
			object.inspectionProjectUuid = null;
			object.assetUuid = null;
		};

		// When calendar event subtype is changed, other properties must be reset/reloaded and object data cleaned.
		const eventSubtypeField: UnoFormField = UnoFormUtils.getFormFieldByAttribute(layout, 'actionSubtype');
		eventSubtypeField.onChange = (object: any, row: UnoFormField, value: any): void => {
			// Clear data not needed for this sub-type of calendar event
			switch (value) {
				case CalendarEventSubtypes.ASSET_ATEX_INSPECTION:
					object.repairUuid = null;
					object.inspectionProjectUuid = null;
					break;
				case CalendarEventSubtypes.ASSET_DL50_INSPECTION:
					object.repairUuid = null;
					object.inspectionProjectUuid = null;
					break;
				case CalendarEventSubtypes.ASSET_DYNAMIC_INSPECTION:
					object.repairUuid = null;
					break;
				case CalendarEventSubtypes.REPAIR_DEFINITIVE_REPAIR:
					object.repairUuid = null;
					object.inspectionProjectUuid = null;
					break;
				case CalendarEventSubtypes.REPAIR_TEMPORARY_INSPECTION:
					object.assetUuid = null;
					object.inspectionProjectUuid = null;
					break;
				case CalendarEventSubtypes.SCHEDULED_AUDIT:
				case CalendarEventSubtypes.SCHEDULED_PLANNED_STOP_MAINTENANCE:
					object.repairUuid = null;
					object.inspectionProjectUuid = null;
					object.assetUuid = null;
					break;
			}
		};

		return layout;
	}

	/**
	 * The event form, used when creating or editing an event.
	 *
	 * Receives GUI event used to fill the form with data we already have, start date in the case of creating a new event and start,end and title in the case of editing one.
	 *
	 * @param event - Event that will be edited.
	 */
	public async eventForm(event: CalendarEvent<PlanningCalendarEvent> | Date = this.unoCalendar.daySelected ? this.viewDate : this.currentDate): Promise<void> {

		// Modify layout to react to changes on other fields
		const layout: UnoFormField[] = AssetPlanningCalendarScreen.setCalendarEventLayoutOnChanges(cloneDeep(CalendarEventLayout));

		// Buttons to be used on the modal.
		const buttons: UnoFormModalButton[] = [{
			success: false,
			label: 'cancel',
			color: 'primary',
			callback: null
		},
		{
			success: true,
			label: 'ok',
			color: 'primary',
			callback: null
		}];


		let createdEvent: PlanningCalendarEvent = new PlanningCalendarEvent();

		if (this.editingEvent) {
			// Edit calendar event
			this.editingEvent = false;

			createdEvent = (event as CalendarEvent).meta;

			if (!Session.hasPermissions(UserPermissions.CALENDAR_EVENT_EDIT)) {
				buttons.pop();
			}

			try {
				await Modal.form(Locale.get('edit'), (event as CalendarEvent).meta, layout, buttons, Session.hasPermissions([UserPermissions.CALENDAR_EVENT_EDIT]));
			} catch {
				// Rejected on cancel
				return;
			}

			await CalendarEventService.update(createdEvent);

			Modal.toast(Locale.get('eventEdited'), 3000, 'success');

		} else {
			if (!Session.hasPermissions(UserPermissions.CALENDAR_EVENT_CREATE)) {
				throw new Error('User does not have permissions to create a new event');
			}

			// Create new calendar event
			createdEvent = new PlanningCalendarEvent();
			createdEvent.date = new Date(event as Date);

			try {
				await Modal.form(Locale.get('create'), createdEvent, layout, buttons, Session.hasPermissions([UserPermissions.CALENDAR_EVENT_CREATE]));
			} catch {
				// Rejected on cancel
				return;
			}

			await CalendarEventService.create(createdEvent);
			Modal.toast(Locale.get('eventCreated'), 3000, 'success');
		}

		this.viewDate = createdEvent.date;
		
		await this.loadEvents();
	}

	/**
	 * Get possible actions an event can have.
	 */
	public getActions(evt: PlanningCalendarEvent, occ: CalendarEventOccurrence): any[] {
		const actions = [];

		const canCreateAction = (e: PlanningCalendarEvent, o: CalendarEventOccurrence): boolean => {
			return e.actionSubtype === CalendarEventSubtypes.ASSET_ATEX_INSPECTION && Session.hasPermissions(UserPermissions.ATEX_INSPECTION_CREATE) && o.atexInspectionUuid === null ||
			e.actionSubtype === CalendarEventSubtypes.ASSET_DL50_INSPECTION && Session.hasPermissions(UserPermissions.DL50_INSPECTIONS_CREATE) && o.dl50InspectionUuid === null || 
			e.actionSubtype === CalendarEventSubtypes.ASSET_DYNAMIC_INSPECTION && Session.hasPermissions(UserPermissions.INSPECTION_CREATE) && o.inspectionUuid === null || 
			e.actionSubtype === CalendarEventSubtypes.REPAIR_DEFINITIVE_REPAIR && Session.hasPermissions(UserPermissions.REPAIR_CREATE) && o.repairUuid === null || 
			e.actionSubtype === CalendarEventSubtypes.REPAIR_TEMPORARY_INSPECTION && Session.hasPermissions(UserPermissions.REPAIR_INSPECTIONS_CREATE) && o.repairInspectionUuid === null;
		};

		actions.push({
			label: canCreateAction(evt, occ) ? '<p class="uno-calendar-event-label"><img class="uno-calendar-event-icon" src="assets/components/global-calendar/add-icon.svg"/></p>' : '',
			onClick: ({event}: {event: CalendarEvent}): void => {
				this.navigateToAction({event: event});
			}
		});

		actions.push({
			label: '<p class="uno-calendar-event-label" ><img class="uno-calendar-event-icon" src="assets/icons/material/create/outline.svg"/></p>',
			a11yLabel: Locale.get('edit'),
			onClick: ({event}: { event: CalendarEvent }): void => {
				this.editForm(event);
			}
		});

		if (Session.hasPermissions(UserPermissions.CALENDAR_EVENT_DELETE)) {
			actions.push({
				label: '<p class="uno-calendar-event-label"><img class="uno-calendar-event-icon" src="assets/icons/material/delete/outline.svg"/></p>',
				a11yLabel: Locale.get('delete'),
				onClick: ({event}: {event: CalendarEvent}): void => {
					this.deleteForm(event);
				}
			});
		}

		return actions;
	}

	/**
	 * Shows a confirmation message to delete a specific event, if confirmed calls the event deletion method
	 *
	 * @param uuid - The uuid of the event to delete
	 */
	public async deleteForm(event: CalendarEvent): Promise<void> {
		// Modal for the user to choose to delete this or all events.
		const allEvents: boolean = await Modal.confirm(Locale.get('delete'), Locale.get('confirmDeleteSelection'), ['allEvents', 'thisEvent']);

		// If the "All events" option is selected.
		if (allEvents) {
			await this.deleteEvent(event.meta.uuid);
		} else {
			// Find the occurrence to delete.
			const occurrence: CalendarEventOccurrence = event.meta.occurrence;

			// Delete the occurrence
			await this.deleteOccurrence(occurrence.uuid);
		}
	}

	/**
	 * Shows a confirmation message to edit a specific occurrence or the entire event.
	 *
	 * @param event - The event to edit.
	 */
	public async editForm(event: CalendarEvent): Promise<void> {
		this.editingEvent = true;

		const confirm = await Modal.confirm(Locale.get('edit'), Locale.get('confirmEditSelection'), ['allEvents', 'thisEvent']);

		if (confirm) {
			this.eventForm(event);
		} else {
			this.occurrenceForm(event);
		}
	}

	/**
	 * Date range in the current view mode.
	 *
	 * Can be used to get data for the view mode.
	 */
	public getViewDateRange(): {startDate: Date, endDate: Date} {
		let startDate: Date = structuredClone(this.viewDate);
		let endDate: Date = structuredClone(this.viewDate);

		if (this.display === UnoCalendarDisplayMode.MONTH) {
			// Set start Date to the first day
			startDate.setDate(1);
			startDate.setHours(0, 0, 0);
			startDate = subDays(startDate, 7);

			// Set end Date to the end of the last day of the month
			endDate.setMonth(endDate.getMonth() + 1);
			endDate.setDate(1);

			endDate = addDays(endDate, 7);
			endDate.setHours(23, 59, 59);

		} else if (this.display === UnoCalendarDisplayMode.DAY) {
			// Set starting hour to 0
			startDate.setHours(0, 0, 0);
			startDate = subSeconds(startDate, 1);

			// Set ending hour 1 second before midnight
			endDate.setHours(23, 59, 59);
		} else {
			// Set start date to 1970
			startDate = new Date(0);
			startDate.setHours(0, 0, 0);

			// Set end date 200 years in the future
			endDate = addYears(endDate, 200);
			endDate.setHours(23, 59, 59);
		}

		return {startDate: startDate, endDate: endDate};
	}

	/**
	 * Shows the occurrence form.
	 */
	public async occurrenceForm(event: CalendarEvent): Promise<void> {
		this.editingEvent = false;
		let createdUuid: UUID;

		// Find the occurrence that was opened.
		const occurrence: CalendarEventOccurrenceWithEvent = event.meta.occurrence;
		occurrence.event = event.meta;

		const updatedOccurrence = structuredClone(occurrence);
		delete updatedOccurrence.event['occurrence'];

		// Function to update the opened occurrence. Check which subtype was selected and set the uuid in the corresponding action.
		const setActionUuid = function(uuid: UUID): void {
			updatedOccurrence.inspectionUuid = null;
			updatedOccurrence.atexInspectionUuid = null;
			updatedOccurrence.dl50InspectionUuid = null;
			updatedOccurrence.repairUuid = null;
			updatedOccurrence.repairInspectionUuid = null;
			switch (event.meta.actionSubtype) {
				case CalendarEventSubtypes.ASSET_DYNAMIC_INSPECTION:
					updatedOccurrence.inspectionUuid = uuid;
					break;
				case CalendarEventSubtypes.ASSET_ATEX_INSPECTION:
					updatedOccurrence.atexInspectionUuid = uuid;
					break;
				case CalendarEventSubtypes.ASSET_DL50_INSPECTION:
					updatedOccurrence.dl50InspectionUuid = uuid;
					break;
				case CalendarEventSubtypes.REPAIR_DEFINITIVE_REPAIR:
					updatedOccurrence.repairUuid = uuid;
					break;
				case CalendarEventSubtypes.REPAIR_TEMPORARY_INSPECTION:
					updatedOccurrence.repairInspectionUuid = uuid;
					break;
			}
		};

		// Buttons to be used on the modal.
		let buttons: UnoFormModalButton[] = [{
			success: false,
			label: 'cancel',
			color: 'primary',
			callback: null
		}];
		if (Session.hasPermissions(UserPermissions.CALENDAR_EVENT_EDIT)) {
			buttons.push({
				success: true,
				label: 'ok',
				color: 'primary',
				callback: null
			});
		}

		// If the actionSubype is of a certain subType and the user has that permission.
		if ((event as CalendarEvent).meta.actionSubtype === CalendarEventSubtypes.ASSET_ATEX_INSPECTION && Session.hasPermissions(UserPermissions.ATEX_INSPECTION_CREATE) && (event as CalendarEvent).meta.occurrence.atexInspectionUuid === null ||
		(event as CalendarEvent).meta.actionSubtype === CalendarEventSubtypes.ASSET_DL50_INSPECTION && Session.hasPermissions(UserPermissions.DL50_INSPECTIONS_CREATE) && (event as CalendarEvent).meta.occurrence.dl50InspectionUuid === null ||
    	(event as CalendarEvent).meta.actionSubtype === CalendarEventSubtypes.ASSET_DYNAMIC_INSPECTION && Session.hasPermissions(UserPermissions.INSPECTION_CREATE) && (event as CalendarEvent).meta.occurrence.inspectionUuid === null ||
		(event as CalendarEvent).meta.actionSubtype === CalendarEventSubtypes.REPAIR_DEFINITIVE_REPAIR && Session.hasPermissions(UserPermissions.REPAIR_CREATE) && (event as CalendarEvent).meta.occurrence.repairUuid === null ||
		(event as CalendarEvent).meta.actionSubtype === CalendarEventSubtypes.REPAIR_TEMPORARY_INSPECTION && Session.hasPermissions(UserPermissions.REPAIR_INSPECTIONS_CREATE) && (event as CalendarEvent).meta.occurrence.repairInspectionUuid === null &&
		Session.hasPermissions(UserPermissions.CALENDAR_EVENT_EDIT)) {
			const buttonLabel: string = event.meta.actionSubtype === CalendarEventSubtypes.REPAIR_TEMPORARY_INSPECTION ? 'createRepair' : 'createNewInspection';

			buttons = buttons.concat({
				success: true,
				label: buttonLabel,
				color: 'primary',
				callback: async(): Promise<void> => {
					createdUuid = await this.createAction(event.meta, updatedOccurrence);
					setActionUuid(createdUuid);
				}
			});
		}

		await Modal.form(Locale.get('edit'), updatedOccurrence, CalendarOccurrenceLayout, buttons, Session.hasPermissions([UserPermissions.CALENDAR_EVENT_EDIT]));

		if (Session.hasPermissions(UserPermissions.CALENDAR_EVENT_EDIT)) {
			// Update the occurrence.
			await Service.fetch(ServiceList.assetPlanning.calendarEvent.updateOccurrence, null, null, updatedOccurrence, Session.session);
			// If the update button was clicked navigate to the newly created inspection/repair.
			if (createdUuid) {
				event.meta.occurrence = updatedOccurrence;
				this.navigateToAction({event: event}, false);
			}

			await this.loadEvents();
		}
	}

	/**
	 * Fetches all the events and formats them to be shown in the calendar event.
	 */
	public async loadEvents(reset: boolean = true): Promise<void> {
		if (this.loading) {
			return;
		}

		this.loading = true;

		if (reset) {
			this.unoCalendar?.reset();
			this.events = [];
			this.from = 0;
		}

		const range = this.display === UnoCalendarDisplayMode.LIST ? {startDate: this.range.from, endDate: this.range.to} : this.getViewDateRange();

		const data = {
			search: this.search,
			searchFields: ['[calendar_event].[name]', '[calendar_event].[description]', '[calendar_event].[id]', '[ap_asset].[name]', '[ap_asset].[tag]'],
			sortDirection: this.sortDirection,
			actionSubtype: this.filterList,
			startDate: range.startDate,
			endDate: range.endDate
		};

		data['teamUuids'] = this.filterForm.get('user').value ? Session.user.teamUuids : null;
		data['userUuids'] = this.filterForm.get('user').value ? [Session.user.uuid] : null;

		if (this.display === UnoCalendarDisplayMode.LIST) {
			data['from'] = this.from;
			data['count'] = this.count;
		}

		const request: ServiceResponse = await Service.fetch(ServiceList.assetPlanning.calendarEvent.list, null, null, data, Session.session);
		const calendarEvents: PlanningCalendarEvent[] = request.response.calendarEvents.map((d: any) => {
			return PlanningCalendarEvent.parse(d);
		});

		// Parse the request response into a correct object
		this.occurrences = request.response.occurrences.map((d: any) => {
			return CalendarEventOccurrence.parse(d);
		});

		// Filter the calendar occurrences
		this.occurrences = this.occurrences.filter((event: any) => {
			const parentEvent: PlanningCalendarEvent = calendarEvents.find((evt: PlanningCalendarEvent) => {
				return evt.uuid === event.calendarEventUuid;
			});

			return (parentEvent.name.includes(data.search) || parentEvent.description.includes(data.search)) && data.actionSubtype.length > 0 ? data.actionSubtype.indexOf(parentEvent.actionSubtype) !== -1 : true;
		});

		const findTriggerEventDate = (event: PlanningCalendarEvent): Date => {
			let triggerEvent: PlanningCalendarEvent = event;
			while (triggerEvent.triggerEventUuid) {
				triggerEvent = calendarEvents.find((evt: PlanningCalendarEvent) => {
					return evt.uuid === triggerEvent.triggerEventUuid;
				});
			}

			return triggerEvent.date;
		};

		// Clean list of calendar component occurrences
		const events = [];

		// Iterate through the calendar event occurrences
		for (const occurrence of this.occurrences) {
			// Find calendar event associated with this occurrence
			const event: PlanningCalendarEvent = calendarEvents.find((evt: PlanningCalendarEvent) => {
				return evt.uuid === occurrence.calendarEventUuid;
			});

			if (event) {
				const startDate: Date = new Date(occurrence.date !== null ? occurrence.date : findTriggerEventDate(event));

				let endDate: Date = new Date(startDate);
				if (event.duration !== null) {
					endDate = DateFrequencyUtils.addDate(endDate, event.duration);
				}

				events.push({
					title: event.name,
					color: {primary: CalendareventSubtypeColors.get(event.actionSubtype), secondary: 'var(--brand-primary)'},
					start: startDate,
					end: endDate,
					actions: this.getActions(event, occurrence),
					// Meta is used to store any custom data in the GUI calendar event
					meta: {...event, occurrence: occurrence}
				});
			}
		}
		this.events = this.events.concat(events);
		this.events = [this.events][0];

		this.hasMore = request.response.hasMore;
		this.loading = false;

		this.cdref.detectChanges();
	}

	/**
	 * Upon clicking the button to change the display mode between month, list and day verify which was clicked and update the calendar display.
	 *
	 * @param display - The desired display to view.
	 */
	public async changeDisplayMode(display: UnoCalendarDisplayMode): Promise<void> {
		this.display = display;

		await this.loadEvents(true);
	}

	/**
	 * Updates the view date when given a date value.
	 *
	 * @param value - The Date used to update the view.
	 */
	public changeViewDate(value: Date): void {
		const viewDate = new Date(value);
		const rangeChanged = this.viewDate.getMonth() !== viewDate.getMonth() || this.viewDate.getFullYear() !== viewDate.getFullYear() ;

		this.viewDate = viewDate;

		if (rangeChanged && this.display === UnoCalendarDisplayMode.MONTH || this.display === UnoCalendarDisplayMode.DAY) {
			this.loadEvents();
		}
	}

	/**
	 * Sets the month to a given value and updates the change-view.
	 *
	 * @param value - The month to be changed into.
	 */
	public monthChange(value: number): void {
		const newDate = structuredClone(this.viewDate);
		newDate.setMonth(value);
		this.changeViewDate(new Date(newDate));
	}

	/**
	 * Sets the year to a given value and updates the changeview.
	 *
	 * @param value - The year to be changed into.
	 */
	public yearChange(value: number): void {
		const newDate = structuredClone(this.viewDate);
		newDate.setFullYear(this.currentDate.getFullYear() - 100 + value);
		this.changeViewDate(new Date(newDate));
	}

	/**
	 * Calls the Calendar event delete api using the uuid of an event.
	 *
	 * @param uuid - The uuid of the event to delete.
	 */
	public async deleteEvent(uuid: string): Promise<void> {
		await Service.fetch(ServiceList.assetPlanning.calendarEvent.delete, null, null, {uuid: uuid}, Session.session);

		await this.loadEvents();
	}

	/**
	 * Calls the Calendar event deleteOccurrence api using the uuid of an occurrence.
	 *
	 * @param uuid - The uuid of the occurrence to delete.
	 */
	public async deleteOccurrence(uuid: string): Promise<void> {
		await Service.fetch(ServiceList.assetPlanning.calendarEvent.deleteOccurrence, null, null, {uuid: uuid}, Session.session);

		// Reload the events.
		await this.loadEvents();
	}

	/**
	 * Receives a string and expands the section corresponding to it.
	 *
	 * @param section - The current section to expand.
	 */
	public expand(section: string): void {
		if (section === 'assetEvents') {
			this.filterForm.get('assetEventsGroup').patchValue({assetEvents: !this.filterForm.get('assetEventsGroup').value.assetEvents});
		} else if (section === 'repairEvents') {
			this.filterForm.get('repairEventsGroup').patchValue({repairEvents: !this.filterForm.get('repairEventsGroup').value.repairEvents});
		} else if (section === 'scheduledEvents') {
			this.filterForm.get('scheduledEventsGroup').patchValue({scheduledEvents: !this.filterForm.get('scheduledEventsGroup').value.scheduledEvents});
		} else if (section === 'priority') {
			this.filterForm.get('priorityGroup').patchValue({priority: !this.filterForm.get('priorityGroup').value.priority});
		} else if (section === 'doneness') {
			this.filterForm.get('completion').patchValue({doneness: !this.filterForm.get('completion').value.doneness});
		}
	}

	/**
	 * When a filter is changed updates the calendar and reloads it to show the changes.
	 *
	 * @param filter - Array containing the subtypes and their current value.
	 */
	public async toggleFilters(filter: {key: string, value: boolean}[]): Promise<void> {
		// Clones the previous filter list.
		const filters = structuredClone(this.filterList);

		for (const subtype of filter) {
			const keyValue = CalendarEventLabelSubtypes.get(subtype.key);

			// If the current form value is true and is not already in the filter list add it.
			if (subtype.value === true && !this.filterList.includes(keyValue)) {
				this.filterList.push(keyValue);
			} else if (subtype.value === false && this.filterList.includes(keyValue)) {
				// Remove the item if it is false and in the filter list.
				const index = this.filterList.indexOf(keyValue);
				this.filterList.splice(index, 1);
			}
		}

		// Sort both list to compare the contents.
		filters.sort();
		this.filterList.sort();

		// If one of the filter lists has items, and they are both different update the events.
		if (this.filterList.length + filters.length !== 0 && !ObjectUtils.equal(filters, this.filterList)) {
			await this.loadEvents();
		}
	}

	/**
	 * Changes all the filters to be the same as the given filter value, used when the "select all" option in the filter is selected.
	 *
	 * @param allFilters - Object containing the current "selected" value in the form.
	 */
	public async toggleAllFilters(allFilters: boolean): Promise<void> {
		this.loading = true;
		this.filterForm.get('assetEventsGroup').patchValue({
			assetEvents: allFilters,
			inspections: allFilters,
			atexInspections: allFilters,
			dl50Inspection: allFilters
		});

		this.filterForm.get('repairEventsGroup').patchValue({
			repairEvents: allFilters,
			repairInspection: allFilters,
			definitiveRepair: allFilters
		});
		this.filterForm.get('scheduledEventsGroup').patchValue({
			scheduledEvents: allFilters,
			stopMaintenance: allFilters,
			audit: allFilters
		});
		this.filterForm.get('priorityGroup').patchValue({
			priority: allFilters,
			highPriority: allFilters,
			mediumPriority: allFilters,
			lowPriority: allFilters
		});
		this.filterForm.get('completion').patchValue({
			doneness: allFilters,
			done: allFilters,
			overdue: allFilters
		});

		// If the "select all" option is checked, add all the filters to the filter list, if it is unchecked remove all the filters.
		this.filterList = allFilters ? Object.values(CalendarEventSubtypes) : [];

		// Load the events with the new filter.
		this.loading = false;

		await this.loadEvents();
	}

	/**
	 * Changes the sort direction of the list to the one given
	 *
	 * @param direction - The sort direction to change into
	 */
	public async changeSortDirection(direction: string): Promise<void> {
		this.sortDirection = direction;

		await this.loadEvents();
	}

	/**
	 * When a calendar event is clicked open the corresponding form.
	 *
	 * @param data - The data of the action that was clicked
	 */
	public async eventClickAction(data: any): Promise<void> {
		if (data.action === 'edit') {
			this.editForm(data.event);
		} else {
			this.deleteForm(data.event);
		}

		await this.loadEvents();
	}

	/**
	 * Export calendar data as XLSX.
	 *
	 * Opens a modal to select which subtypes to be exported and then exports the Excel.
	 */
	public async exportCalendarXLSX(): Promise<void> {
		let range: {startDate: Date, endDate: Date};
		if (this.display === UnoCalendarDisplayMode.LIST) {
			range = {startDate: this.range.from, endDate: this.range.to};
		} else {
			range = this.getViewDateRange();
		}

		await CalendarEventExport.exportCalendarXLSX(this.filterList, range.startDate, range.endDate, this.search);
	}

	/**
	 * Navigates to the action associated to the calendar occurrence.
	 *
	 * Action might be of different type based on calendar event.
	 *
	 * @param ev - Event object containing the calendar occurrence.
	 */
	public async navigateToAction(ev: {event: CalendarEvent}, showModal: boolean = true): Promise<void> {

		const event: PlanningCalendarEvent = ev.event.meta;
		const occurrence: CalendarEventOccurrence = ev.event.meta.occurrence;
		let createMode: boolean = false;

		if (showModal && !occurrence.atexInspectionUuid && !occurrence.inspectionUuid && !occurrence.repairUuid && !occurrence.repairInspectionUuid && !occurrence.dl50InspectionUuid) {
			if (!await Modal.confirm(Locale.get('create'), Locale.get('createNewInspectionConfirmation'))) {
				return;
			}
			createMode = true;
		}

		if (event.actionType === CalendarEventTypes.ASSET) {
			switch (event.actionSubtype) {
				case CalendarEventSubtypes.ASSET_ATEX_INSPECTION:
					if (createMode) {
						occurrence.atexInspectionUuid = await this.createAction(event, occurrence);

						// Update the occurrence.
						await Service.fetch(ServiceList.assetPlanning.calendarEvent.updateOccurrence, null, null, occurrence, Session.session);
					}
					App.navigator.navigate('/menu/atex/inspections/edit', {uuid: occurrence.atexInspectionUuid});
					break;
				case CalendarEventSubtypes.ASSET_DL50_INSPECTION:
					if (createMode) {
						occurrence.dl50InspectionUuid = await this.createAction(event, occurrence);

						// Update the occurrence.
						await Service.fetch(ServiceList.assetPlanning.calendarEvent.updateOccurrence, null, null, occurrence, Session.session);
					}
					App.navigator.navigate('/menu/dl50/inspections/edit', {uuid: occurrence.dl50InspectionUuid});
					break;
				case CalendarEventSubtypes.ASSET_DYNAMIC_INSPECTION:
					if (createMode) {
						occurrence.inspectionUuid = await this.createAction(event, occurrence);

						// Update the occurrence.
						await Service.fetch(ServiceList.assetPlanning.calendarEvent.updateOccurrence, null, null, occurrence, Session.session);
					}
					App.navigator.navigate('/menu/inspection/edit', {project: event.inspectionProjectUuid, uuid: occurrence.inspectionUuid});
					break;
			}
		} else if (event.actionType === CalendarEventTypes.REPAIR) {
			switch (event.actionSubtype) {
				case CalendarEventSubtypes.REPAIR_DEFINITIVE_REPAIR:
					if (createMode) {
						occurrence.repairUuid = await this.createAction(event, occurrence);

						// Update the occurrence.
						await Service.fetch(ServiceList.assetPlanning.calendarEvent.updateOccurrence, null, null, occurrence, Session.session);
					}
					App.navigator.navigate('/menu/repairs/works/edit', {uuid: occurrence.repairUuid});
					break;
				case CalendarEventSubtypes.REPAIR_TEMPORARY_INSPECTION:
					if (createMode) {
						occurrence.repairInspectionUuid = await this.createAction(event, occurrence);

						// Update the occurrence.
						await Service.fetch(ServiceList.assetPlanning.calendarEvent.updateOccurrence, null, null, occurrence, Session.session);
					}
					App.navigator.navigate('/menu/repairs/inspections/edit', {uuid: occurrence.repairInspectionUuid});
					break;
			}
		}
	}

	/**
	 * Create the action associated with the calendar event.
	 *
	 * Might be of different type (e.g. repair, inspection) depending on calendar event type.
	 *
	 * @param event - Calendar event to create action for.
	 */
	public async createAction(event: PlanningCalendarEvent, occurrence: CalendarEventOccurrence): Promise<UUID> {

		let uuid: UUID;
		switch (event.actionSubtype) {
			case CalendarEventSubtypes.ASSET_DYNAMIC_INSPECTION:
				if (event.assetUuid) {
					const inspection = new Inspection();

					inspection.assetUuid = event.assetUuid;
					inspection.projectUuid = event.inspectionProjectUuid;

					const stepRequest: ServiceResponse = await Service.fetch(ServiceList.inspection.project.defaultStep, null, null, {uuid: inspection.projectUuid}, Session.session);
					const step: InspectionWorkflowStep = InspectionWorkflowStep.parse(stepRequest.response.step);

					inspection.stepUuid = step.uuid;

					uuid = (await Service.fetch(ServiceList.inspection.create, null, null, inspection, Session.session)).response.uuid;
				}
				break;
			case CalendarEventSubtypes.ASSET_ATEX_INSPECTION:
				if (event.assetUuid) {
					const atexInspection = new AtexInspection();
					atexInspection.assetUuid = event.assetUuid;
					uuid = (await Service.fetch(ServiceList.atex.inspection.createForAsset, null, null, atexInspection, Session.session)).response.uuid;
				}
				break;
			case CalendarEventSubtypes.ASSET_DL50_INSPECTION:
				if (event.assetUuid) {
					const dl50Inspection = new DL50Inspection();
					dl50Inspection.assetUuid = event.assetUuid;
					uuid = (await Service.fetch(ServiceList.dl50.inspections.create, null, null, dl50Inspection, Session.session)).response.uuid;
				}
				break;
			case CalendarEventSubtypes.REPAIR_DEFINITIVE_REPAIR:
				if (event.assetUuid) {
					const definitiveRepair = new Repair();
					definitiveRepair.asset = event.assetUuid;
					uuid = (await Service.fetch(ServiceList.repairs.create, null, null, definitiveRepair, Session.session)).response.uuid;
				}
				break;
			case CalendarEventSubtypes.REPAIR_TEMPORARY_INSPECTION:
				if (event.repairUuid) {
					const repair = new RepairInspection();
					repair.repairUuid = event.repairUuid;
					repair.date = occurrence.date;
					repair.dueDate = occurrence.date;
					uuid = (await Service.fetch(ServiceList.repairInspections.create, null, null, repair, Session.session)).response.uuid;
				}
				break;
		}
		return uuid;
	}

	/**
	 * Load more events
	 *
	 * @param index - The current table index.
	 */
	public async loadMore(index: number): Promise<void> {
		if ((this.events.length - 30 < index || this.events.length === 0) && !this.loading) {
			this.from = this.events.length;

			await this.loadEvents(false);
		}
	}

	/**
	 * Stores the current calendar filters in Local Storage.
	 */
	public storeFilters(): void {
		const value = {
			display: this.display,
			range: this.range,
			viewDate: this.viewDate,
			search: this.search,
			filterForm: this.filterForm.value,
			filterList: this.filterList
		};

		LocalStorage.set('assetPlanningFilters', value);
	}

	/**
	 * Loads the current calendar filters present in Local Storage.
	 */
	public loadFilters(): void {
		this.loading = true;
		const filters = LocalStorage.get('assetPlanningFilters');

		this.range = new DateRange(this.range.from, this.range.to);
		this.search = filters.search;
		this.viewDate = new Date(filters.viewDate);
		this.display = filters.display ? filters.display : UnoCalendarDisplayMode.MONTH;

		this.filterList = filters.filterList;

		// Try to set the filterForm value from the LocalStorage values, on failure use the default filter.
		try {
			this.filterForm.setValue(filters.filterForm);
		} catch {
			if (!Environment.PRODUCTION) {
				console.warn('EQS: Failed to set the filter form. Using the default filter');
			}
		}

		this.loading = false;
	}

	/**
	 * Create a new form group for the filters.
	 */
	public createFormGroup(): void {
		// Create a form-group to hold the filter values, all initialized as false (not selected).
		this.filterForm = new FormGroup({
			assetEventsGroup: new FormGroup({
				assetEvents: new FormControl(false),
				inspections: new FormControl(false),
				atexInspections: new FormControl(false),
				dl50Inspection: new FormControl(false)
			}),
			repairEventsGroup: new FormGroup({
				repairEvents: new FormControl(false),
				repairInspection: new FormControl(false),
				definitiveRepair: new FormControl(false)
			}),
			scheduledEventsGroup: new FormGroup({
				scheduledEvents: new FormControl(false),
				stopMaintenance: new FormControl(false),
				audit: new FormControl(false)
			}),
			priorityGroup: new FormGroup({
				priority: new FormControl(false),
				highPriority: new FormControl(false),
				mediumPriority: new FormControl(false),
				lowPriority: new FormControl(false)
			}),
			completion: new FormGroup({
				doneness: new FormControl(false),
				done: new FormControl(false),
				overdue: new FormControl(false)
			}),
			select: new FormControl(false),
			user: new FormControl(false)
		});

		// If the changed value is in the assetEventsGroup and the "select all" option wasn't selected call the function to toggle the corresponding filters.
		this.filterForm.get('assetEventsGroup').valueChanges.subscribe(async(selectedValue) => {
			// If the select all option was not selected.
			if (selectedValue && !this.loading) {
				await this.toggleFilters(ObjectUtils.toArray(selectedValue));
			}
		});

		// If the changed value is in the repairEventsGroup and the "select all" option wasn't selected call the function to toggle the corresponding filters.
		this.filterForm.get('repairEventsGroup').valueChanges.subscribe(async(selectedValue) => {
			// If the select all option was not selected.
			if (selectedValue && !this.loading) {
				await this.toggleFilters(ObjectUtils.toArray(selectedValue));
			}
		});

		// If the changed value is in the scheduledEventsGroup and the "select all" option wasn't selected call the function to toggle the corresponding filters.
		this.filterForm.get('scheduledEventsGroup').valueChanges.subscribe(async(selectedValue) => {
			// If the select all option was not selected.
			if (selectedValue && !this.loading) {
				await this.toggleFilters(ObjectUtils.toArray(selectedValue));
			}
		});

		// If the select all option is selected calls the function to togggle all filters.
		this.filterForm.get('select').valueChanges.subscribe(async(selectedValue) => {
			if (!this.loading) {
				await this.toggleAllFilters(selectedValue);
			}
		});

		// If the "my events" option is selected reload the events.
		this.filterForm.get('user').valueChanges.subscribe(async() => {
			if (!this.loading) {
				await this.loadEvents();
			}
		});
	}

	public ngOnDestroy(): void {
		this.storeFilters();
		this.manager.destroy();
	}
}
