<uno-text [ngStyle]="{position: 'absolute', display: 'block', top: '0px', left: '0px', width: '100%'}" ngClass="welcome">{{'recover' | translate}}</uno-text>
<uno-text [multiline]="true" [ngStyle]="{position: 'absolute', display: 'block', top: '20px', left: '0px', width: '100%'}" ngClass="credentials">{{'enterNewPasswordReset' | translate}}</uno-text>

<!-- Password Input -->
<div [ngStyle]="{position: 'absolute', display: 'block', top: '100px', width: '100%', height: '300px', left: '0px'}">
	<uno-text [ngStyle]="{position: 'absolute', display: 'block', top: '0px', width: '100%'}" ngClass="input">{{'password' | translate}}</uno-text>
	<uno-input [ngStyle]="{position: 'absolute', display: 'block', top: '15px', width: '100%'}" [(ngModel)]="password" [ngModelOptions]="{standalone: true}" placeholder="{{'password' | translate}}" type="password"></uno-input>

	<uno-text [ngStyle]="{position: 'absolute', display: 'block', top: '50px', width: '100%'}" ngClass="input">{{'confirmPassword' | translate}}</uno-text>
	<uno-input [ngStyle]="{position: 'absolute', display: 'block', top: '65px', width: '100%'}" [(ngModel)]="confirmPassword" [ngModelOptions]="{standalone: true}" placeholder="{{'confirmPassword' | translate}}" type="password"></uno-input>
</div>

<!-- Submit -->
<uno-button [ngStyle]="{position: 'absolute', display: 'flex', top: '170px', width: '100%'}" (click)="this.resetPassword();" [expand]="true">{{'reset' | translate}}</uno-button>

<!-- Back -->
<div [ngStyle]="{position: 'absolute', display: 'block', top: '400px', width: '100%', height: '40px', cursor: 'pointer'}">
	<uno-text alignment="center" ngClass="recover" (click)="this.returnLogin();">{{'returnLogin' | translate}}</uno-text>
</div>
